import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserData } from '@mojoapps1/mojoapps1common';
import {} from 'google.maps';
import { BackendProService } from 'src/app/angular-services/backend.pro.service';
import { PageBaseProComponent } from 'src/app/pagebasepro.component';

/**
 * show an image
 */
@Component({
  selector: 'modal-photo-pro',
  templateUrl: './modal-photo-pro.component.html',
  styleUrls: ['./modal-photo-pro.component.scss'],
})
export class ModalPhotoPro
  extends PageBaseProComponent
  implements OnInit, OnDestroy {
  user: UserData;
  loading: boolean;

  /**
   * url to image
   */
  @Input() url: string;

  constructor(
    private modalCtrl: ModalController,
    backendService: BackendProService
  ) {
    super(backendService, 'modal-photo');
    this.loading = false;
  }

  protected async onUserReady(u: UserData): Promise<void> {
    this.log('userready');

    this.user = u;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
