import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import { BackendProService } from 'src/app/angular-services/backend.pro.service';
import { IonFab, IonToggle, ModalController } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import {
  BizService,
  Service,
  BizServiceInfo,
  BizServices,
  BizSamedaySlot,
  DateUtil,
  CollectionNames,
  Togl,
  ToglDataItem,
} from '@mojoapps1/mojoapps1common';

import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';

import {
  trigger,
  state,
  transition,
  animate,
  style,
} from '@angular/animations';

import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  ModalTimeslot,
  ModalTimeslotResult,
} from '../modal-timeslot/modal-timeslot.component';

import { startOfToday } from 'date-fns';

import { UIStringPro } from 'src/app/lang/UIStringPro';
import { AngularFirestore } from '@angular/fire/firestore';
import { LoadingScreenProService } from 'src/app/angular-services/loadingscreen.pro.service';
import {
  triggerFadeInOut,
  triggerScaleInOutFromTop,
} from 'src/app/animations/animations';
import { ToglDataProService } from 'src/app/angular-services/togldata.pro.service';
import {
  IconInfo,
  IconsProService,
} from 'src/app/angular-services/icons.pro.service';
import { DateUtil2 } from 'src/app/util/DateUtil2';
import { SubscriptionProService } from 'src/app/angular-services/subscription.pro.service';
import { AlertProService } from 'src/app/angular-services/alert.pro.service';

// export function sortByStartTime(
//   a: { start: any; [key: string]: any },
//   b: { start: any; [key: string]: any }
// ) {
//   return a.start.valueOf() < b.start.valueOf() ? -1 : 1;
// }

// const ServiceToggleAnimations = [
//   trigger('scaleInOutFromTop', [
//     transition(':enter', [
//       style({ transform: 'scaleY(0)' }),
//       animate('300ms ease-out'),
//     ]),
//     transition(
//       ':leave',
//       animate('300ms ease-out', style({ transform: 'scaleY(0)' }))
//     ),
//   ]),
// ];

/**
 * used to manage business's togled on state
 */
@Component({
  selector: 'app-servicesetup-toggle',
  templateUrl: './servicesetup-toggle.component.html',
  styleUrls: ['./servicesetup-toggle.component.scss'],
  animations: [triggerScaleInOutFromTop(), triggerFadeInOut()],
})
export class ServicesetupToggleComponent
  implements OnInit, OnDestroy, AfterViewInit {
  /**
   * the service object we're rendering. if the component is reused by angular/ionic, this will be called
   * instead of ngoninit. so we need to make sure to set up everything for the new serviceInfo correctly,
   * including subscriptions etc.
   */
  @Input() set serviceInfo(val: BizServiceInfo) {
    const firstTime = this._serviceInfo ? false : true;
    const oldServiceId = this._serviceInfo
      ? this._serviceInfo.serviceId
      : 'none';
    console.log(
      `servicesetup-toggle: set serviceInfo, firsttime=${firstTime}, old=${oldServiceId}, new=${val.serviceId}: `,
      val
    );

    // set new service info
    this._serviceInfo = val;

    this.onServiceInfoUpdated(firstTime).then(() => {
      // done
    });
  }
  get serviceInfo(): BizServiceInfo {
    return this._serviceInfo;
  }
  private _serviceInfo: BizServiceInfo;

  /**
   * the sameday slots for this service. must handle undefined values
   */
  @Input() set slots(val: BizSamedaySlot[]) {
    console.log(
      `servicesetup-toggle: ${this._serviceInfo.serviceId}, slots received: `,
      val
    );
    this.samedaySlots = val ? val : [];

    for (const slot of this.samedaySlots) {
      if (slot.enabled == null) slot.enabled = false;
    }
  }
  get slots(): BizSamedaySlot[] {
    return this.samedaySlots;
  }

  /**
   * the color of the icon, an ionic color (i.e. "primary", "secondary", etc). defaults to primary
   */
  @Input() iconColor: string;

  /**
   * whether user is enabled for same day
   */
  @Input() entitledSameDay: boolean;

  /**
   * UPDATE: deprecated, this component only used on active togls page.
   *
   * whether we're in setup mode (setup service page) or not (active togl page).
   *
   * setup mode means you can edit the service parameters, price, duration, etc.
   *
   * non setup mode means you can't edit the service itself, but you can togl on or off for it, and manage your sameday slots (if applicable).
   */
  @Input() isSetupMode: boolean;

  /**
   * business id of current business
   */
  @Input() set businessId(val: string) {
    console.log(`servicesetup-toggle: set businessId: ${val}`);

    this._businessId = val;
  }
  get businessId() {
    return this._businessId;
  }
  private _businessId: string;

  @Input() isAutoConfirm: boolean;

  /**
   * the now toggle
   */
  @ViewChild('toggle') private toggle: IonToggle;
  @ViewChild('samedayToggle') private samedayToggle: IonToggle;

  /**
   * template variable, whether sameday section is expanded
   */
  // showSameDay: boolean;

  /**
   * template variable, the list of sameday slots
   */
  samedaySlots: BizSamedaySlot[];

  icon: IconInfo;

  toglDataDisplay: ToglDataItem[];

  private service: Service;

  constructor(
    private formBuilder: FormBuilder,
    private backendService: BackendProService,
    private modalCtrl: ModalController,
    private firestore: AngularFirestore,
    private loadingScreen: LoadingScreenProService,
    private toglData: ToglDataProService,
    private icons: IconsProService,
    private subscription: SubscriptionProService,
    private alerts: AlertProService
  ) {}

  /**
   * component init, called once
   */
  ngOnInit() {
    if (!this.businessId) throw new Error('businessId required');

    // default to primary color
    if (!this.iconColor) this.iconColor = 'primary';

    console.log('servicesetup-toggle: onInit: ' + this._serviceInfo.serviceId);

    // subscription to timeslot changes handled by serviceInfo setter
  }

  ngAfterViewInit() {}

  /**
   * component destroy
   */
  ngOnDestroy() {
    console.log(
      'servicesetup-toggle: ondestroy: ' + this._serviceInfo.serviceId
    );
  }

  /**
   * update component when service info is changed
   * @param firstTime whether this is the first time the info has been updated
   */
  private async onServiceInfoUpdated(firstTime: boolean) {
    // this.icons
    //   .getIconInfo(this._serviceInfo.icon)
    //   .then((icon) => (this.icon = icon));

    this.toglDataDisplay = this.toglData.filterToglData(
      this._serviceInfo.toglData
    );

    // load service data for enabled/disabled info. should be moved to biszerviceinfo probably
    this.service = await this.backendService.getServiceFromCache(
      this._serviceInfo.serviceId,
      true
    );
  }

  /**
   * add a sameday time slot when button clicked
   */
  async clickAddTimeslot() {
    // need premier plus
    if (!this.subscription.isPremierPlus()) {
      return this.subscription.userPromptPremierPlusLevel();
    }

    // make sure this service is enabled or they can't togl on
    if (!this.service.enabled) {
      // sorry!
      await this.alerts.alertOk(UIStringPro.format('NOTIF_SERVICE_DISABLED'));
      this._serviceInfo.activeNow = false;
      return;
    }

    await this.showTimeslotModal();
  }

  /**
   * edit a sameday timeslot when button clicked
   * @param slot
   */
  async clickSlot(slot: BizSamedaySlot) {
    // need premier plus
    if (!this.subscription.isPremierPlus()) {
      return this.subscription.userPromptPremierPlusLevel();
    }

    console.log('clickslot: ' + slot.id);

    await this.showTimeslotModal(slot);
  }

  /**
   * template function to determine if timeslot start time is valid
   * @param slot
   * @returns
   */
  isValidTimeslot(slot: BizSamedaySlot): boolean {
    return DateUtil2.isValidStarttime(slot.start.toDate());
  }

  /**
   * show the modal for editing a timeslot
   * @param editMode whether we're adding or editing a sameday timeslot
   * @param slot existing slot data to edit if any
   */
  async showTimeslotModal(slot?: BizSamedaySlot) {
    if (slot) {
      await this.loadingScreen.showLoading();
      // check to see if the slot has any togls, if not, we can't edit it!
      const snap = await this.backendService
        .collectionRef<Togl>(CollectionNames.TOGLS, (ref) =>
          ref
            .where('businessId', '==', this.businessId)
            .where('samedaySlotId', '==', slot.id)
        )
        .get()
        .toPromise();
      await this.loadingScreen.hideLoading();

      if (!snap.empty) {
        // cannot delete
        return this.alerts.alertOk(
          UIStringPro.format('NOTIF_EDIT_TIMESLOT_HAS_TOGL')
        );
      }
    }

    const modal = await this.modalCtrl.create({
      component: ModalTimeslot,
      cssClass: 'timeslot-modal auto-height',
      componentProps: {
        serviceInfo: this._serviceInfo,
        granularity: DateUtil2.DEFAULT_GRANULARITY,
        editMode: !!slot,
        slot: slot,
      },
    });
    await modal.present();

    // wait for modal to dismiss, and then process results
    const { data, role } = await modal.onDidDismiss();

    console.log(
      `servicesetup-toggle: timeslot modal returned, role=${role}`,
      data
    );
    const result: ModalTimeslotResult = data as ModalTimeslotResult;

    if (role === 'delete') {
      // delete existing timeslot
      if (!slot) throw new Error('no slot to delete');

      await this.loadingScreen.showLoading();
      await this.backendService
        .docRef(CollectionNames.BIZ_SAMEDAYSLOTS, slot.id)
        .delete();
      console.log(`servicesetup-toggle: deleted slot ${slot.id}`);

      await this.loadingScreen.hideLoading();
      await this.alerts.alertOk('Timeslot deleted');
    } else if (role === 'add') {
      if (!result) throw new Error('no data returned from ModalTimeslot');

      await this.loadingScreen.showLoading();

      // add a new document
      let newSlot: BizSamedaySlot = {
        start: result.start,
        end: result.end,
        businessId: this.businessId,
        serviceId: this.serviceInfo.serviceId,
        id: this.firestore.createId(),
        enabled: true,
      };
      await this.backendService
        .docRef(CollectionNames.BIZ_SAMEDAYSLOTS, newSlot.id)
        .set(newSlot);
      console.log(`servicesetup-toggle: saved new timeslot ${newSlot.id}`);

      await this.loadingScreen.hideLoading();
      await this.alerts.alertOk('New timeslot created');
    } else if (role === 'update') {
      if (!result) throw new Error('no data returned from ModalTimeslot');
      if (!slot) throw new Error('no slot to update');

      // update existing data
      let updateData: any = {
        start: result.start,
        end: result.end,
      };

      await this.loadingScreen.showLoading();

      // update the backend
      await this.backendService
        .docRef(CollectionNames.BIZ_SAMEDAYSLOTS, slot.id)
        .update(updateData);
      console.log(`servicesetup-toggle: updated timeslot ${slot.id}`);

      await this.loadingScreen.hideLoading();
      await this.alerts.alertOk('Timeslot updated');
    }
  }

  /**
   * when a timeslot toggle switch is toggled
   * @param e
   * @param slot
   */
  async onTimeslotToggle(e: CustomEvent, slot: BizSamedaySlot) {
    let checked = e.detail.checked;
    if (checked && !this.isValidTimeslot(slot)) {
      // TODO: need to cancel the update and tell the user they can't enable an expired timeslot

      checked = false;
      slot.enabled = true;
    }
    console.log(
      `servicesetup-toggle: slot ${slot.id}, enabled=${slot.enabled}, toggleChecked=${checked}`
    );

    if (slot.enabled != checked) {
      // make sure this service is enabled or they can't togl on
      if (!this.service.enabled && slot.enabled == false && checked == true) {
        // sorry!
        await this.alerts.alertOk(UIStringPro.format('NOTIF_SERVICE_DISABLED'));
        slot.enabled = false;
        return;
      }

      console.log('servicesetup-toggle: saving to backend');
      // update the timeslot
      let updateData: any = {
        enabled: checked,
      };
      // update the backend
      await this.backendService
        .docRef(CollectionNames.BIZ_SAMEDAYSLOTS, slot.id)
        .update(updateData);

      console.log(`servicesetup-toggle: updated timeslot ${slot.id}`);

      slot.enabled = checked;
    }

    // this.tmpSlotEnabled[slot.id] = checked;
  }

  /**
   * user toggled green toggle on or off
   */
  async onToggleGreen(e) {
    if (!this.businessId) throw new Error('missing business id');

    let key = 'activeNow';
    let oldValue = this._serviceInfo[key];
    let newValue: boolean = this.toggle.checked ? true : false;

    // filter extraneous toggle events
    if (oldValue != newValue) {
      console.log(
        'servicesetup-toggle: toggle, ' +
          key +
          ', ' +
          oldValue +
          ' => ' +
          newValue
      );

      // make sure they have a subscription or they can't togl on
      if (newValue == true && !this.subscription.isActive()) {
        await this.subscription.userPromptPremierLevel();
        this.toggle.checked = false;
        return;
      }

      // make sure this service is enabled or they can't togl on
      if (newValue == true && !this.service.enabled) {
        // sorry!
        await this.alerts.alertOk(UIStringPro.format('NOTIF_SERVICE_DISABLED'));
        this.toggle.checked = false;
        // this is insuffient somehow
        this._serviceInfo.activeNow = false;
        return;
      }

      // save to backend
      let updateData: any = {
        [this._serviceInfo.serviceId + '.' + key]: newValue,
      };
      // if they togled on, record a timestamp
      if (!oldValue && newValue) {
        updateData[
          this._serviceInfo.serviceId + '.lastTogledOn'
        ] = firebase.firestore.FieldValue.serverTimestamp();
      }
      // if they togled off, remove auto-confirm if any
      // if (oldValue && !newValue) {
      //   updateData[
      //     this._serviceInfo.serviceId + '.autorespondEnabled'
      //   ] = firebase.firestore.FieldValue.delete();
      //   updateData[
      //     this._serviceInfo.serviceId + '.autorespondEndTime'
      //   ] = firebase.firestore.FieldValue.delete();
      // }
      this.backendService
        .docRef<BizServices>(CollectionNames.BIZ_SERVICES, this.businessId)
        .update(updateData)
        .then(() => {
          console.log(
            'servicesetup-toggle: ' +
              this._serviceInfo.serviceId +
              '.activeNow=' +
              newValue
          );
          this._serviceInfo[key] = newValue;
        });
    }
  }

  /**
   * template function to create timeslot label
   * @param slot
   * @returns
   */
  generateTimeslotLabel(slot: BizSamedaySlot): string {
    return (
      DateUtil2.formatTime(slot.start) + ' - ' + DateUtil2.formatTime(slot.end)
    );
  }

  /**
   * helper function for accessing language file
   * @param key
   * @param replaceValues
   * @returns
   */
  uistring(key: string, replaceValues?: any): string {
    return UIStringPro.format(key, replaceValues);
  }
}
