import uistringspro from './uistrings-pro.json';

export class UIStringPro {
  public static isValid(uistringkey: string) {
    let str = uistringspro[uistringkey];
    if (!str) return false;
    return true;
  }

  public static format(uistringkey: string, replaceValues?: any) {
    let str = uistringspro[uistringkey];
    if (!str) throw new Error('unknown uistringkey ' + uistringkey);

    for (const key in replaceValues)
      str = str.replace(
        new RegExp('\\{' + key + '\\}', 'gm'),
        replaceValues[key]
      );
    return str;
  }

  public static getAll() {
    return { ...uistringspro };
  }
}
