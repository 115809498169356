import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IonSearchbar, ModalController } from '@ionic/angular';
import {
  BizSamedaySlot,
  BizServiceInfo,
  Business,
  Category,
  Service,
} from '@mojoapps1/mojoapps1common';
import { BackendProService } from 'src/app/angular-services/backend.pro.service';
import { PageBaseProComponent } from 'src/app/pagebasepro.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { UIStringPro } from 'src/app/lang/UIStringPro';
import { LoadingScreenProService } from 'src/app/angular-services/loadingscreen.pro.service';
import { AlertProService } from 'src/app/angular-services/alert.pro.service';

@Component({
  selector: 'modal-choose-service',
  templateUrl: './modal-choose-service.component.html',
  styleUrls: ['./modal-choose-service.component.scss'],
})
export class ModalChooseService extends PageBaseProComponent implements OnInit {
  @Input() serviceInfo: BizServiceInfo;
  @Input() granularity: number;
  @Input() editMode: boolean;
  @Input() slot: BizSamedaySlot;

  title: string;
  subtitle: string;
  formGroup: FormGroup;

  startTime: Date;
  endTime: Date;

  minuteValues: string;

  business: Business;

  categories: Category[];
  categoryId: string;
  categoryTitle: string;
  services: Service[];
  searchResults: any[];
  @ViewChild('searchbar') private searchBar: IonSearchbar;

  mode: 'search' | 'categories' | 'services';

  constructor(
    private modalCtrl: ModalController,
    private loadingScreen: LoadingScreenProService,
    backendService: BackendProService,
    private alerts: AlertProService
  ) {
    super(backendService, 'modal-choose-service');
  }

  /**
   * when biz object is loaded
   */
  protected async onBizReady(biz: Business) {
    this.log('bizready');

    this.business = this.backendService.currentBusiness;

    // title of form
    this.title = UIStringPro.format('HEADER_ADD_SERVICE');

    // load categories, excluding disabled
    await this.loadingScreen.showLoading();
    const categories = await this.backendService.getAllCategoriesCached();
    this.categories = [...categories];

    // set initial mode
    this.mode = 'categories';

    await this.loadingScreen.hideLoading();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async setCategory(id: string) {
    this.categoryId = id;
    const cat = this.categories.find((v) => v.id === id);
    if (cat) {
      this.categoryTitle = cat.title;
    } else {
      this.categoryTitle = UIStringPro.format('TITLE_SERVICES');
    }
    this.mode = 'services';
    // exclude disabled
    const services = await this.backendService.getServicesForCategoryCached(id);

    this.services = [...services];
    // this.services.sort((a, b) => {
    //   return parseInt(a.id) < parseInt(b.id) ? -1 : 1;
    // });
  }

  backToCategories() {
    this.mode = 'categories';
    this.services = null;
    this.categoryId = null;
  }

  async onClickCategory(c: Category) {
    await this.setCategory(c.id);
  }

  async onClickService(s: Service) {
    const confirmation = await this.alerts.genericConfirm2(
      UIStringPro.format('NOTIF_CONFIRM_ADD_SERVICE', { service: s.title })
    );

    if (confirmation) {
      this.modalCtrl.dismiss(s, 'add');
    }
  }

  async onSearchChange() {
    let searchValue = this.searchBar.value.trim().toLowerCase();
    if (searchValue) {
      this.mode = 'search';
    } else {
      //TODO: remember if we were in service mode?
      this.backToCategories();
    }

    if (this.mode === 'search') {
      // do the search

      const results = await this.backendService.searchServices(searchValue);
      this.searchResults = [...results];

      this.log(
        'search results: ' +
          this.searchResults
            .map((s) => {
              return s.title;
            })
            .join(', ')
      );
    } else {
      // not search mode, go back to normal
      this.searchResults = null;
    }
  }
}
