import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';

import { BackendProService } from 'src/app/angular-services/backend.pro.service';
import { IonToggle } from '@ionic/angular';
import { Subject } from 'rxjs';
import {
  BizService,
  BizServiceInfo,
  Service,
} from '@mojoapps1/mojoapps1common';
import {
  IconInfo,
  IconsProService,
} from 'src/app/angular-services/icons.pro.service';

/**
 * used on biz listing page to request a TOGL
 */
@Component({
  selector: 'app-service-toggle',
  templateUrl: './service-toggle.component.html',
  styleUrls: ['./service-toggle.component.scss'],
})
export class ServiceToggleComponent implements OnInit, OnDestroy {
  /**
   * the service object we're rendering
   */
  @Input() serviceInfo: BizServiceInfo;
  /**
   * the color of the icon, an ionic color (i.e. "primary", "secondary", etc). defaults to primary
   */
  @Input() iconColor: string;
  /**
   * path to an svg icon
   */
  // iconSvgPath: string;
  /**
   * fallback ion-icon name if no svg given
   */
  // iconFallback: string;

  @Input() initialCheckedValue: boolean;

  @ViewChild('toggle') private toggle: IonToggle;

  private _onChange: Subject<ServiceToggleComponent>;

  // icon: IconInfo;

  constructor(private icons: IconsProService) {}

  async ngOnInit() {
    this._onChange = new Subject<ServiceToggleComponent>();

    console.log(
      'service-toggle: init: value=' +
        this.serviceInfo.serviceId +
        ', initialCheckedValue=' +
        this.initialCheckedValue
    );

    // default to primary color
    if (!this.iconColor) this.iconColor = 'primary';

    // this.icon = await this.icons.getIconInfo(this.serviceInfo.icon);
  }

  isToggleDisabled() {
    return !(
      this.serviceInfo.cost != null &&
      this.serviceInfo.cost > 0 &&
      this.serviceInfo.duration != null &&
      this.serviceInfo.duration > 0
    );
  }

  ngOnDestroy() {
    console.log('service-toggle: destroy, value=' + this.serviceInfo.serviceId);
    this._onChange.complete();
  }

  get checked(): boolean {
    return this.toggle.checked ? true : false;
  }

  public get value(): string {
    return this.toggle.value;
  }

  public get onChange() {
    return this._onChange.asObservable();
  }

  onToggle() {
    console.log(
      'service-toggle: on-toggle, value=' +
        this.toggle.value +
        ', toggle.checked=' +
        (this.toggle.checked ? true : false)
    );
    this._onChange.next(this);
  }
}
