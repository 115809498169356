import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';

/**
 * exposes the inapp browser capacitor plugin as an angular service
 */
@Injectable({
  providedIn: 'root',
})
export class BrowserProService {
  static privacyPolicy() {
    return Browser.open({ url: 'https://toglonpro.com/privacy/' });
  }

  static terms() {
    return Browser.open({ url: 'https://toglonpro.com/terms/' });
  }

  static resetPassword() {
    return Browser.open({ url: 'https://toglonpro.com/lost-password/' });
  }

  async openPrivacyPolicy() {
    await BrowserProService.privacyPolicy();
  }

  async openTerms() {
    await BrowserProService.terms();
  }

  async openResetPassword() {
    await BrowserProService.resetPassword();
  }
}
