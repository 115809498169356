import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendProService } from '../../angular-services/backend.pro.service';
import { PageBaseProComponent } from 'src/app/pagebasepro.component';

import { Observable, Subject } from 'rxjs';
import {
  BackendServiceBase,
  Business,
  UserData,
} from '@mojoapps1/mojoapps1common';
import { IonInfiniteScroll, IonInput, IonSearchbar } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { BrowserProService } from 'src/app/angular-services/browser.pro.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { UIStringPro } from 'src/app/lang/UIStringPro';
import { environment } from 'src/environments/environment';
import { SignupProService } from 'src/app/angular-services/signup.pro.service';
import { AlertProService } from 'src/app/angular-services/alert.pro.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage extends PageBaseProComponent implements OnInit {
  userInfo: any = {};
  @ViewChild('searchbar') searchBar: IonSearchbar;
  @ViewChild('infiniteScroll') private infiniteScroll: IonInfiniteScroll;
  businesses: Business[];
  loading: boolean;
  searchValue: string;
  batchSize: number = 15;
  authUser: any;
  mode: 'signup' | 'login' = 'login';
  form: FormGroup;
  formSaveInProgress: boolean = false;
  title: string;
  actionLabel: string;
  switchLabel: string;
  errorMessage: string;

  @ViewChild('passwordInput') public passwordInput: IonInput;
  showPassword: boolean = false;

  validation_messages = {
    email: [
      {
        type: 'required',
        message: UIStringPro.format('LABEL_EMAIL_REQUIRED'),
      },
      {
        type: 'email',
        message: UIStringPro.format('LABEL_EMAIL_VALID'),
      },
    ],
    name: [
      {
        type: 'required',
        message: UIStringPro.format('LABEL_FIRST_LAST_REQUIRED'),
      },
    ],
    password: [
      {
        type: 'required',
        message: UIStringPro.format('LABEL_PASSWORD_REQUIRED'),
      },
      {
        type: 'minlength',
        message: UIStringPro.format('LABEL_PASSWORD_LENGTH'),
      },
    ],
  };

  isProd: boolean = false;

  constructor(
    private router: Router,
    backendService: BackendProService,
    private auth: AngularFireAuth,
    private route: ActivatedRoute,
    private browser: BrowserProService,
    private formBuilder: FormBuilder,
    private signup: SignupProService,
    private alerts: AlertProService
  ) {
    super(backendService, 'login');

    if (environment.database == BackendServiceBase.DB_PROD) {
      this.isProd = true;
    } else {
      this.isProd = false;
    }

    this.loading = false;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      name: new FormControl('', Validators.required),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
    this.setMode('login');
  }

  protected setupBackendSubscriptions(): void {
    this.addBackendSubscription(
      this.auth.authState.subscribe((u) => {
        this.log('authState event, is login: ' + !!u);
        this.authUser = u;
        if (u == null) {
          this.formSaveInProgress = false;
        }
      })
    );
  }

  /**
   * if we get here, a user is logged in. this could happen on signup, page load, or when component is re-shown
   *
   * if they do not have a business, redirect them to `/claimbusiness`
   *
   * @param u
   */
  protected async onUserReady(u: UserData): Promise<void> {
    if (!u.businessId) {
      this.formSaveInProgress = false;

      this.log(
        'user finished logging in, has no business. navigating to /claimbusiness'
      );

      let redirectUrl: string = this.backendService.consumeRedirectOnLogin();
      if (redirectUrl) {
        this.log('ignoring original url ' + redirectUrl);
      }

      await this.router.navigateByUrl('/claimbusiness');
      // this.form.patchValue({ email: '', password: '' });

      this.form.reset();
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key).setErrors(null);
      });
    }
  }

  protected async onBizReady(biz: Business): Promise<void> {
    // if we get here, a user with a business finished logging in,
    // or is already logged in.
    // so, redirect to the app

    this.log('onBizReady, user finished logging in, has biz: ' + biz.name);

    this.formSaveInProgress = false;

    let redirectUrl: string = this.backendService.consumeRedirectOnLogin();

    if (redirectUrl) {
      this.log('navigating to original url ' + redirectUrl);
      await this.router.navigateByUrl(redirectUrl);
    } else {
      this.log('navigating to app');
      await this.router.navigateByUrl('/tabs');
    }

    // this.form.patchValue({ email: '', password: '' });
    this.form.reset();
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setErrors(null);
    });

    await this.alerts.openAlertToast('Welcome, ' + biz.name + '!');
  }

  private createNameInput() {
    return new FormControl('', this.nameRequiredFactory());
  }

  /**
   * create a name validator
   * @returns
   */
  nameRequiredFactory() {
    return (c: AbstractControl): ValidationErrors | null => {
      this.log('name value: ' + c.value);

      if (this.mode == 'login') {
        // name not required
        return null;
      }

      return c.value ? null : { required: true };
    };
  }

  setMode(newMode: 'login' | 'signup') {
    this.mode = newMode;

    if (this.mode === 'login') {
      // remove controls if needed
      if (this.form.get('name')) {
        this.form.removeControl('name');
      }
    } else {
      // add controls if needed
      if (!this.form.get('name')) {
        this.form.addControl('name', this.createNameInput());
      }
    }

    this.title =
      newMode == 'login'
        ? UIStringPro.format('LABEL_LOGIN')
        : UIStringPro.format('LABEL_SIGNUP');
    this.actionLabel = this.title;
    this.switchLabel =
      newMode == 'login'
        ? UIStringPro.format('LABEL_SWITCH_TO_SIGNUP')
        : UIStringPro.format('LABEL_SWITCH_TO_LOGIN');
  }

  switchMode() {
    if (this.mode == 'login') {
      this.setMode('signup');
    } else {
      this.setMode('login');
    }
  }

  /**
   * process form submission
   * @param values
   */
  async onSubmit(values: any) {
    this.log('submit, got values: ', JSON.stringify(values));
    this.errorMessage = null;

    this.formSaveInProgress = true;
    if (this.mode == 'login') {
      try {
        const userCredential = await this.auth.signInWithEmailAndPassword(
          values['email'],
          values['password']
        );
        this.log('logged in', userCredential.user);
      } catch (e) {
        const code = e.code;
        let message = e.message;
        if (code == 'auth/wrong-password') {
          message = UIStringPro.format('NOTIF_LOGIN_FAILED');
        } else if (code == 'auth/user-not-found') {
          message = UIStringPro.format('NOTIF_LOGIN_FAILED_NOUSER');
        }

        this.formSaveInProgress = false;
        this.errorMessage = message;
      }
    } else if (this.mode == 'signup') {
      if (!this.isProd) {
        // sign up the normal way
        try {
          await this.backendService.createUser(
            values['email'],
            values['name'],
            values['password']
          );

          this.form.reset();
          this.setMode('login');

          // const userCredential = await this.auth.createUserWithEmailAndPassword(
          //   values['email'],
          //   values['password']
          // );
          // this.log(`signed up ${values['name']} / ${values['email']}`);
          // const name = values['name'];
          // this.log(name);
          // if (name != null && name != '') {
          //   this.log('updating display name to ' + name);
          //   await userCredential.user.updateProfile({
          //     displayName: name,
          //   });
          //   this.log('updated display name');
          // }
        } catch (e) {
          // var errorCode = e.code;
          // var errorMessage = e.message;

          this.errorMessage = e.message;
        } finally {
          this.formSaveInProgress = false;
        }
      } else {
        // signup using firebase backend function
        try {
          const response = await this.signup.createUser(
            values['email'],
            values['password'],
            undefined,
            values['name']
          );
          this.formSaveInProgress = false;

          if (response.success) {
            this.log('create user success', response);
            // log user in
            await this.auth.signInWithEmailAndPassword(
              values['email'],
              values['password']
            );
            this.form.reset();
            this.setMode('login');
          } else {
            this.errorMessage =
              response.message || 'An error occurred, please try again later.';
            this.log('create user failed', JSON.stringify(response));
          }
        } catch (e) {
          this.log('error', e);
          this.formSaveInProgress = false;
          this.errorMessage = e.message;
        }
      }
    }
  }

  async privacyPolicy() {
    await this.browser.openPrivacyPolicy();
  }

  async terms() {
    await this.browser.openTerms();
  }

  async resetPassword() {
    await this.browser.openResetPassword();
  }

  /**
   * toggle password visibility
   */
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
    this.passwordInput.type = this.showPassword ? 'text' : 'password';
  }

  uiShownCallback() {
    this.log('fbui shown');
  }
}
