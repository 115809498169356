import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
// import { Events } from '@ionic/angular'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ionic5-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: StarRating,
      multi: true,
    },
  ],
})
export class StarRating implements ControlValueAccessor, OnInit {
  ngOnInit(): void {
    this.rating = this.rating; //default after input`s initialization
    for (var i = 0; i < this.maxRating; i++) {
      this.iconsArray.push(i);
    }
  }

  public readonly eventInfo = (() => {
    const id = new Date().getTime();
    const topic = `star-rating:${id}:changed`;
    return {
      topic,
    };
  })();

  private _rating: number;
  private onChange: any;
  private onTouched: any;
  public disabled: boolean;

  writeValue(obj: number): void {
    this.rating = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  @Input() public set rating(val: number) {
    console.log(`ionic5-star-rating: rating set to ${val}`);
    this._rating = val;
    // for form
    if (this.onChange) {
      this.onChange(val);
    }
  }

  public get rating(): number {
    return this._rating;
  }

  @Output()
  ratingChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  readonly: boolean = false;
  @Input()
  activeColor: string = '#488aff';
  @Input()
  defaultColor: string = '#aaaaaa';
  @Input()
  activeIcon: string = 'ios-star';
  @Input()
  defaultIcon: string = 'ios-star-outline';
  @Input()
  halfIcon: string = 'ios-star-half';
  @Input()
  halfStar: string = 'false';
  @Input()
  maxRating: number = 5;
  @Input()
  fontSize: string = '28px';
  Math: any;
  parseFloat: any;
  iconsArray: number[] = [];

  constructor() {
    this.Math = Math;
    this.parseFloat = parseFloat;
  }

  changeRating(event) {
    if (this.readonly) return;
    // event is different for firefox and chrome
    let id = event.target.id
      ? parseInt(event.target.id)
      : parseInt(event.target.parentElement.id);
    if (this.halfStar && this.halfStar === 'true') {
      this.rating =
        this.rating - id > 0 && this.rating - id <= 0.5 ? id + 1 : id + 0.5;
    } else {
      this.rating = id + 1;
    }

    // subscribe this event to get the changed value in your parent compoanent

    console.log('ionic5-star-rating: emitting event ' + this.rating);
    this.ratingChanged.emit(this.rating);

    // this.events.publish(`star-rating:changed`, this.rating); //common event for all instances included for backwards compatibility
    // this.events.publish(this.eventInfo.topic, this.rating); //common event for all instances
    // unique event
    // this.ratingChanged.emit(this.rating)
  }
}
