import { Injectable, OnInit } from '@angular/core';
import firebase from 'firebase/app';

import { Platform } from '@ionic/angular';
import {} from 'google.maps';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { BackendProService } from './backend.pro.service';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
  RegistrationError,
} from '@capacitor/push-notifications';
import {
  PushData,
  PushTokenInfoRTDB,
  PushTokenRTDB,
  PushTypes,
  RTDBPaths,
  ToglInfo,
} from '@mojoapps1/mojoapps1common';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subject } from 'rxjs';

/**
 * manages push notifications
 */
@Injectable({
  providedIn: 'root',
})
export class PushNotifProService {
  private _token: Token;
  private _userId: string;
  private _onPushNotif: Subject<PushData> = new Subject<PushData>();

  constructor(private rtdb: AngularFireDatabase, private platform: Platform) {}

  /**
   * are push notifications supported on this platform?
   */
  get isSupported(): boolean {
    return (
      this.platform.is('hybrid') &&
      (this.platform.is('android') || this.platform.is('ios'))
    );
  }

  /**
   * register for push notifications and save token
   *
   * - check whether push notifications are supported
   * - check permissions
   * - request permissions from user
   * - if permissions granted, get token from backend
   * - save token to our database
   *
   * @returns
   */
  async onLogin(userId: string) {
    if (!this.isSupported) {
      console.log('pushnotifpro: push notifications not supported');
      return;
    }

    this._userId = userId;

    console.log(
      'pushnotifpro: mobile platform, registering for push notifications'
    );

    // remove all previous listeners if any
    await PushNotifications.removeAllListeners();

    // successful token request handler
    await PushNotifications.addListener('registration', async (token: Token) =>
      this.handleTokenReceived(token)
    );

    // registration error handler
    await PushNotifications.addListener(
      'registrationError',
      (e: RegistrationError) => this.handleRegistrationError(e)
    );

    // push notification received handler
    await PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification) => this.handlePushNotification(notification)
    );

    // handler for when user taps on notification
    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (action: ActionPerformed) => {
        console.log('pushnotifpro: Push action performed: ', action);
        return this.handlePushNotification(action.notification);
      }
    );

    // request permissions if needed
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    // user disabled notifications. TODO: decide what to do
    if (permStatus.receive !== 'granted') {
      console.log('pushnotifpro: user has disabled notifications.');
    }

    // register for push notifications
    await PushNotifications.register();
  }

  /**
   * unsubscribe from push notifications and delete token. must call before logging out
   */
  async onLogout() {
    if (this.isSupported) {
      await PushNotifications.removeAllListeners();
    }
    await this.deleteToken();
    if (this._onPushNotif) {
      this._onPushNotif.complete();
      this._onPushNotif = new Subject<PushData>();
    }
  }

  /**
   * observable for push notification events
   * @returns
   */
  onPushNotif() {
    return this._onPushNotif.asObservable();
  }

  /**
   * get a db ref to list of push tokens for the current user
   * @returns null if user not logged in
   */
  private _getRef() {
    if (!this._userId) return null;

    return this.rtdb.database.ref(
      `${RTDBPaths.PUSHTOKENS_PRO}/${this._userId}`
    );
  }

  /**
   * called when push registration is successful. broadcasts to observers and completes.
   *
   * @param token
   */
  private async handleTokenReceived(token: Token) {
    console.log(
      'pushnotifpro: Push registration success, token: ' + token.value
    );
    const oldToken: string = this._token ? this._token.value : '';

    if (oldToken == token.value) {
      console.log('pushnotifpro: oldtoken == newtoken');
    }
    if (oldToken === token.value) {
      console.log('pushnotifpro: oldtoken === newtoken');
    }

    this._token = token;

    if (this._userId && oldToken != token.value) {
      await this.saveToken(token.value);
    }
  }

  /**
   * called if push registration fails
   * @param e
   */
  private handleRegistrationError(e: RegistrationError) {
    console.log('pushnotifpro: Error on registration: ', e.error);
  }

  /**
   * handle a push notification
   * @param notification
   * @returns
   */
  private async handlePushNotification(notification: PushNotificationSchema) {
    console.log('pushnotifpro: Push received: ', notification);
    let data = notification.data as PushData;

    if (!data) return;

    console.log(`pushnotifpro: type ${data.type}`, JSON.stringify(data));

    // broadcast to observers
    this._onPushNotif.next(data);
  }

  /**
   * adds the push token to the user's list in the database if it doesn't already exist, or update the timestamp
   * @param token
   */
  private async saveToken(token: string) {
    if (!this._userId) return;

    const existingTokens = await this.getTokenList();
    const existingToken = existingTokens.find((t) => t.value == token);
    const timestamp = Math.round(Date.now());
    if (existingToken) {
      // update timestamp
      const ref = this._getRef().child(existingToken.key);
      await ref.update({
        timestamp,
      });
      console.log('pushnotifpro: updated timestamp for ' + existingToken.value);
    } else {
      // add new token
      const ref = this._getRef();
      await ref.push().set({
        value: token,
        timestamp,
      });
      console.log('pushnotifpro: added ' + token);
    }
  }

  /**
   * get list of tokens for current user
   */
  private async getTokenList() {
    if (!this._userId) return null;

    const ref = await this._getRef();

    const snap = await ref.once('value');

    let ret: PushTokenInfoRTDB[] = [];
    snap.forEach((child) => {
      let val: PushTokenRTDB = child.val() as PushTokenRTDB;
      let info: PushTokenInfoRTDB = {
        ...val,
        key: child.key,
      };
      ret.push(info);
    });

    return ret;
  }

  /**
   * remove a push token from the user's list in the database
   */
  private async deleteToken() {
    if (!this._token || !this._userId) return;
    const existingTokens = await this.getTokenList();
    const existingToken = existingTokens.find(
      (t) => t.value == this._token.value
    );
    if (existingToken) {
      const ref = this._getRef().child(existingToken.key);
      await ref.remove();
    }
    this._token = null;
  }
}
