import { Component, OnInit, Input } from '@angular/core';
import { DarkmodeService } from 'src/app/angular-services/darkmode.pro.service';

@Component({
  selector: 'app-header-pro',
  templateUrl: './header-pro.component.html',
  styleUrls: ['./header-pro.component.scss'],
})
export class HeaderProComponent implements OnInit {
  @Input() isBackButton: boolean;
  @Input() backsendURL: String;

  isDark: boolean;
  logoUrl: string;

  constructor(private darkMode: DarkmodeService) {}

  setDark(isDark: boolean) {
    this.isDark = isDark;
    this.logoUrl = isDark
      ? '/assets/logo/logo-pro-header-dark.png'
      : '/assets/logo/logo-pro-header-light.png';
  }

  ngOnInit() {
    // console.log('header-pro: this.isBackButton: ' + this.isBackButton);
    // console.log('header-pro: this.backsendURL: ' + this.backsendURL);

    this.setDark(this.darkMode.isDark);
    this.darkMode.onChange.subscribe((v) => this.setDark(v));
  }
}
