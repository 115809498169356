import {
  animate,
  animation,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';

export const DEFAULT_TIME: number = 300;
export const DEFAULT_EASE: string = 'ease-out';

/**
 * scale in from top animation
 *
 * params: time (e.g. 300ms ease-out)
 */
export const animScaleInFromTop = animation([
  style({ transform: 'scaleY(0)', transformOrigin: 'top center' }),
  animate('{{ time }}'),
]);

/**
 * scale out to top animation
 *
 * params: time (e.g. 300ms ease-out)
 */
export const animScaleOutToTop = animation([
  style({ transformOrigin: 'top center' }),
  animate('{{ time }}', style({ transform: 'scaleY(0)' })),
]);

/**
 * fade in animation
 *
 * params: time (e.g. 300ms ease-out)
 */
export const animFadeIn = animation([
  style({ opacity: 0, transformOrigin: 'top center' }),
  animate('{{ time }}'),
]);

/**
 * fade out animation
 *
 * params: time (e.g. 300ms ease-out)
 */
export const animFadeOut = animation([
  animate('{{ time }}', style({ opacity: 0 })),
]);

// trigger('fadeOut', [
//   transition(':leave', animate(500, style({ opacity: 0 }))),
// ]),
// trigger('fadeIn', [
//   transition(':enter', [style({ opacity: 0 }), animate(500)]),
// ]),

/**
 * creates a `scaleInOutFromTop` animation trigger
 *
 * the element will enter by scaling down from the top, and leave by scaling up to the top
 *
 * template usage: `@scaleInOutFromTop` combined with `*ngIf` if desired
 *
 * @param triggerName default `scaleInOutFromTop`
 * @param duration default `300` (ms)
 * @param ease default `ease-out`
 * @returns
 */
export const triggerScaleInOutFromTop = function (
  triggerName: string = 'scaleInOutFromTop',
  duration: number = DEFAULT_TIME,
  ease: string = DEFAULT_EASE
) {
  const time = `${duration}ms ${ease}`;
  return trigger(triggerName, [
    transition(':enter', [
      useAnimation(animScaleInFromTop, {
        params: { time },
      }),
    ]),
    transition(':leave', [
      useAnimation(animScaleOutToTop, {
        params: { time },
      }),
    ]),
  ]);
};

/**
 * creates a `fadeInOut` animation trigger
 *
 * the element will enter by fading in from 0 opacity, and leave by fading out to 0 opacity
 *
 * template usage: `@fadeInOut` combined with `*ngIf` if desired
 *
 * @param triggerName default `fadeInOut`
 * @param duration default `300` (ms)
 * @param ease default `ease-out`
 * @returns
 */
export const triggerFadeInOut = function (
  triggerName: string = 'fadeInOut',
  duration: number = DEFAULT_TIME,
  ease: string = DEFAULT_EASE
) {
  const time = `${duration}ms ${ease}`;
  return trigger(triggerName, [
    transition(':enter', [
      useAnimation(animFadeIn, {
        params: { time },
      }),
    ]),
    transition(':leave', [
      useAnimation(animFadeOut, {
        params: { time },
      }),
    ]),
  ]);
};

// trigger('fadeOut', [
//   transition(':leave', animate(500, style({ opacity: 0 }))),
// ]),
// trigger('fadeIn', [
//   transition(':enter', [style({ opacity: 0 }), animate(500)]),
// ]),

// export const animTransition = animation([
//   style({
//     height: '{{ height }}',
//     opacity: '{{ opacity }}',
//     backgroundColor: '{{ backgroundColor }}'
//   }),
//   animate('{{ time }}')
// ]);

// export const triggerAnimation = trigger('openClose', [
//   transition('open => closed', [
//     useAnimation(transitionAnimation, {
//       params: {
//         height: 0,
//         opacity: 1,
//         backgroundColor: 'red',
//         time: '1s'
//       }
//     })
//   ])
// ]);

// trigger('scaleInOutFromTop', [
//   transition(':enter', [
//     style({ transform: 'scaleY(0)' }),
//     animate('300ms ease-out'),
//   ]),
//   transition(
//     ':leave',
//     animate('300ms ease-out', style({ transform: 'scaleY(0)' }))
//   ),
// ]),
