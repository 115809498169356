import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import { BackendProService } from 'src/app/angular-services/backend.pro.service';
import {
  AlertController,
  IonDatetime,
  IonFab,
  IonToggle,
  ModalController,
} from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import {
  BizService,
  Service,
  BizServiceInfo,
  BizServices,
  BizSamedaySlot,
  DateUtil,
  CollectionNames,
  ToglDataItem,
  ToglDataType2,
  ToglDataDef,
} from '@mojoapps1/mojoapps1common';

import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
} from '@angular/forms';

import {
  trigger,
  state,
  transition,
  animate,
  style,
} from '@angular/animations';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { UIStringPro } from 'src/app/lang/UIStringPro';
import { ToglDataProService } from 'src/app/angular-services/togldata.pro.service';
import {
  IconInfo,
  IconsProService,
} from 'src/app/angular-services/icons.pro.service';
import { SubscriptionProService } from 'src/app/angular-services/subscription.pro.service';
import { add, formatISO, parseISO } from 'date-fns';
import { DateUtil2 } from 'src/app/util/DateUtil2';
import {
  ModalEndTime,
  ModalEndTimeResult,
} from '../modal-endtime/modal-endtime.component';

@Component({
  selector: 'app-service-autoresponder',
  templateUrl: './service-autoresponder.component.html',
  styleUrls: ['./service-autoresponder.component.scss'],
  animations: [
    trigger('fadeOut', [
      transition(':leave', animate(500, style({ opacity: 0 }))),
    ]),
    trigger('fadeIn', [
      transition(':enter', [style({ opacity: 0 }), animate(500)]),
    ]),
  ],
})
export class ServiceAutoresponderComponent
  implements OnInit, OnDestroy, AfterViewInit {
  /**
   * the service object we're rendering
   */
  @Input() set serviceInfo(val: BizServiceInfo) {
    this._serviceInfo = val;
    console.log('service-autoresponder: set serviceInfo:');
    console.log(val);
  }
  get serviceInfo(): BizServiceInfo {
    return this._serviceInfo;
  }
  private _serviceInfo: BizServiceInfo;

  @Input() hideType: boolean = true;

  /**
   * the color of the icon, an ionic color (i.e. "primary", "secondary", etc). defaults to primary
   */
  @Input() iconColor: string;

  /**
   * path to an svg icon
   */
  iconSvgPath: string;
  /**
   * fallback ion-icon name if no svg given
   */
  iconFallback: string;

  @Input() businessId: string;

  @ViewChild('toggle') private toggle: IonToggle;
  @ViewChild('datetime') private datetime: IonDatetime;

  @Output()
  onSaved: EventEmitter<BizServiceInfo> = new EventEmitter<BizServiceInfo>();

  @Output()
  delete: EventEmitter<BizServiceInfo> = new EventEmitter<BizServiceInfo>();

  minuteValues: string;

  endTime: Date;

  // formGroup: FormGroup;

  private keyEnabled: string = 'autorespondEnabled';
  private keyEndTime: string = 'autorespondEndTime';

  pickerOptions = { backdropDismiss: false };

  // samedaySlots: BizSamedaySlot[];

  constructor(
    private formBuilder: FormBuilder,
    private backendService: BackendProService,
    private modalCtrl: ModalController,
    private toglData: ToglDataProService,
    private icons: IconsProService,
    private subscription: SubscriptionProService,
    private alertCtrl: AlertController
  ) {}

  ngOnInit() {
    if (!this.businessId) throw new Error('businessId required');

    // default to primary color
    if (!this.iconColor) this.iconColor = 'primary';

    console.log(
      'service-autoresponder: onInit: ' + this._serviceInfo.serviceId
    );

    // calculate minute values for the picker
    let tmpArray = [];
    // hard coded to 15 minute granularity
    for (let i = 0; i < 60; i += 15) {
      tmpArray.push(i);
    }
    this.minuteValues = tmpArray.join(',');

    if (this._serviceInfo.autorespondEndTime) {
      // an end time was already chosen

      // coerce start and end time to today's date
      let today: Date = new Date();
      this.endTime = this._serviceInfo.autorespondEndTime.toDate();
      this.endTime.setDate(today.getDate());
      this.endTime.setMonth(today.getMonth());
      this.endTime.setFullYear(today.getFullYear());
    } else {
      // no end time yet
      // calculate end time
      this.endTime = DateUtil2.soonestValidStarttime(15);
    }

    // this.formGroup = this.formBuilder.group({
    //   endTime: new FormControl(this.formatDate(this.endTime), (c) => {
    //     if (DateUtil2.isValidStarttime(parseISO(c.value), new Date(), 15)) {
    //       return null;
    //     }
    //     return { nopast: true };
    //   }),
    // });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    console.log(
      'service-autoresponder: ondestroy: ' + this._serviceInfo.serviceId
    );
  }

  validation_messages = {
    startTime: [
      {
        type: 'nopast',
        message: UIStringPro.format('VALIDATION_TIMESLOT_START_NOPAST'),
      },
    ],
    endTime: [
      {
        type: 'nopast',
        message: UIStringPro.format('VALIDATION_TIMESLOT_END_NOPAST'),
      },
      {
        type: 'nostart',
        message: UIStringPro.format('VALIDATION_TIMESLOT_END_BEFORESTART'),
      },
    ],
  };

  /**
   * when the datetime is canceled
   * @param event
   */
  // async endTimeOnCancel(event) {
  //   console.log('service-autoresponder: endTimeOnCancel');
  //   console.log(
  //     `service-autoresponder: toggle state: ${
  //       this.toggle.checked
  //     }, form: ${JSON.stringify(this.formGroup.value)}, form valid: ${
  //       this.formGroup.valid
  //     }`
  //   );

  //   // TODO: if they didn't pick a date, and they were trying to turn it on, turn it off
  // }

  // async endTimeOnChange(event) {
  //   const endTime = parseISO(event.target.value);
  //   console.log('service-autoresponder: endTimeOnChange', endTime);
  //   console.log(
  //     `service-autoresponder: toggle state: ${
  //       this.toggle.checked
  //     }, form: ${JSON.stringify(this.formGroup.value)}, form valid: ${
  //       this.formGroup.valid
  //     }`
  //   );

  //   // if time is invalid, and they've got auto-confirm turned on, turn it off
  //   // if (!this.formGroup.valid && this._serviceInfo.autorespondEnabled) {
  //   //   await this.updateBackend(false);
  //   // }
  // }

  /**
   * user turned auto-confirm on or off
   */
  async onToggle(e) {
    if (!this.businessId) throw new Error('missing business id');

    let oldValue = this._serviceInfo[this.keyEnabled];
    let newValue: boolean = this.toggle.checked ? true : false;

    // filter extraneous toggle events
    if (oldValue != newValue) {
      console.log(
        'service-autoresponder: toggle, ' +
          this.keyEnabled +
          ', ' +
          oldValue +
          ' => ' +
          newValue
      );

      if (newValue) {
        // if the switch was turned on, make them choose a date
        await this.chooseEndTime(false, true);
      } else {
        // if the switch was turned off, turn off auto-confirm
        await this.updateBackend({ value: false });
      }

      // if the time is valid, we can turn on auto-confirm.
      // if not, we can't turn it on, and we should turn it off automatically
      // if (this.formGroup.valid) {
      //   // save to backend if the time is valid
      //   if (newValue) {
      //     // toggle on
      //     await this.updateBackend({
      //       value: newValue,
      //       endTime: parseISO(this.formGroup.value['endTime']),
      //     });
      //   } else {
      //     // toggle off
      //     await this.updateBackend({
      //       value: newValue,
      //     });
      //   }
      // } else {
      //   if (newValue) {
      //     // turn it off, time is invalid
      //     this._serviceInfo.autorespondEnabled = false;
      //     await this.updateBackend({ value: false });
      //   } else {
      //     // time is invalid and they're turning it off, shouldn't actually happen...
      //   }
      // }
    }
  }

  async clickEndTime() {
    await this.chooseEndTime(true);
  }

  async chooseEndTime(editMode: boolean, triggeredViaToggle: boolean = false) {
    const modal = await this.modalCtrl.create({
      component: ModalEndTime,
      cssClass: 'timeslot-modal auto-height',
      componentProps: {
        granularity: DateUtil2.DEFAULT_GRANULARITY,
        title: 'End Time',
        subtitle: 'Choose when to turn Auto-Confirm off.',
        editMode,
        time: this._serviceInfo.autorespondEndTime,
      },
    });
    await modal.present();

    // wait for modal to dismiss, and then process results
    const { data, role } = await modal.onDidDismiss();

    console.log(
      `service-autoresponder: modal returned, role=${role}`,
      JSON.stringify(data)
    );
    const result: ModalEndTimeResult = data as ModalEndTimeResult;

    if (role == 'add') {
      const endTime = result.end.toDate();
      this._serviceInfo.autorespondEndTime = result.end;
      console.log('service-autoresponder: endTime picked: ' + endTime);

      await this.updateBackend({ value: this.toggle.checked, endTime });
    } else if (role == 'update') {
      const endTime = result.end.toDate();
      this._serviceInfo.autorespondEndTime = result.end;
      console.log('service-autoresponder: endTime picked: ' + endTime);
      await this.updateBackend({ endTime });
    } else {
      // no time chosen
      if (triggeredViaToggle) {
        this._serviceInfo.autorespondEnabled = false;
        this.toggle.checked = false;
      }
    }
  }

  private async updateBackend(params: { value?: boolean; endTime?: Date }) {
    // save to backend
    let updateData: any = {};

    if (params.value != undefined) {
      updateData[`${this._serviceInfo.serviceId}.${this.keyEnabled}`] =
        params.value;

      if (params.endTime == undefined && !params.value) {
        // remove the end time if we're turning it off
        updateData[
          `${this._serviceInfo.serviceId}.${this.keyEndTime}`
        ] = firebase.firestore.FieldValue.delete();
      }
    }

    if (params.endTime != undefined) {
      updateData[
        `${this._serviceInfo.serviceId}.${this.keyEndTime}`
      ] = firebase.firestore.Timestamp.fromDate(params.endTime);
    }

    await this.backendService
      .docRef<BizServices>(CollectionNames.BIZ_SERVICES, this.businessId)
      .update(updateData);
    console.log(`service-autoresponder: ` + JSON.stringify(params));
  }

  formatDate(d: Date): string {
    return formatISO(d);
  }

  endTimeLabel() {
    if (!this._serviceInfo.autorespondEndTime) return '';
    return (
      'End Time: ' +
      DateUtil2.formatTime(this._serviceInfo.autorespondEndTime.toDate())
    );
  }

  isValidTime(): boolean {
    return DateUtil2.isValidStarttime(
      this._serviceInfo.autorespondEndTime.toDate()
    );
  }
}
