import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

export type DarkModeOption = 'system' | 'dark' | 'light';

/**
 * provides methods for querying and managing dark mode
 */
@Injectable({
  providedIn: 'root',
})
export class DarkmodeService {
  private _onChange: Subject<boolean> = new Subject<boolean>();
  private _darkModeOption: DarkModeOption = 'light';
  private _defaultSystem: boolean = false;

  get darkModeOption() {
    return this._darkModeOption;
  }

  /**
   * set dark mode to light, dark, or system.
   */
  set darkModeOption(v: DarkModeOption) {
    this._darkModeOption = v;

    switch (v) {
      case 'dark':
        this.toggleDarkTheme(true);
        break;
      case 'light':
        this.toggleDarkTheme(false);
        break;
      case 'system':
        this.toggleDarkTheme(this.getDarkmodePreference());
        break;
    }
  }

  /**
   * Add or remove the "dark" class on the body which activates dark mode or not. broadcasts a change event.
   * @param shouldAdd
   */
  private toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark', shouldAdd);
    this._onChange.next(shouldAdd);
  }

  /**
   * whether the ui is currently in dark mode or not. returns checks the system preference if `darkModeOption` is `'system'`
   */
  get isDark() {
    switch (this._darkModeOption) {
      case 'dark':
        return true;
      case 'light':
        return false;
      case 'system':
        return this.getDarkmodePreference();
    }
  }

  /**
   * whether dark mode is currently preferred by user
   * @returns
   */
  getDarkmodePreference() {
    const prefersDark = this.getMatchMedia();
    return prefersDark.matches;
  }

  private getMatchMedia() {
    return window.matchMedia('(prefers-color-scheme: dark)');
  }

  /**
   * broadcasts future dark mode toggle events
   */
  get onChange() {
    return this._onChange.asObservable();
  }

  /**
   * call this to initialize dark mode after platform is ready
   */
  initialize() {
    this.darkModeOption = this._defaultSystem ? 'system' : 'light';

    // Use matchMedia to check the user preference
    const prefersDark = this.getMatchMedia();
    // console.log('darkmode service: prefersdark: ' + prefersDark.matches);
    // this.toggleDarkTheme(prefersDark.matches);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addEventListener('change', (mediaQuery) => {
      // match the system if needed
      if (this._darkModeOption == 'system') {
        this.toggleDarkTheme(mediaQuery.matches);
      }
    });
  }
}
