import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BackendProService } from '../../angular-services/backend.pro.service';
import { PageBaseProComponent } from 'src/app/pagebasepro.component';

import { Observable, Subject } from 'rxjs';
import {
  Business,
  CollectionNames,
  UserData,
  UserRole,
} from '@mojoapps1/mojoapps1common';
import { IonInfiniteScroll, IonSearchbar } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { UIStringPro } from 'src/app/lang/UIStringPro';
import { AlertProService } from 'src/app/angular-services/alert.pro.service';

@Component({
  selector: 'app-claim-business',
  templateUrl: './claim-business.page.html',
  styleUrls: ['./claim-business.page.scss'],
})
export class ClaimBusinessPage extends PageBaseProComponent implements OnInit {
  userInfo: any = {};
  @ViewChild('searchbar') searchBar: IonSearchbar;
  @ViewChild('infiniteScroll') private infiniteScroll: IonInfiniteScroll;
  businesses: Business[];
  loading: boolean;
  searchValue: string;
  batchSize: number = 15;
  user: UserData;

  constructor(
    private router: Router,
    backendService: BackendProService,
    private alerts: AlertProService
  ) {
    super(backendService, 'claim-business');

    this.loading = false;
  }

  /**
   * when user is ready
   * @param u
   * @returns
   */
  protected async onUserReady(u: UserData): Promise<void> {
    this.user = u;

    //TODO: do this in a guard
    // if the user already has a business, bail
    if (this.user.businessId) {
      await this.router.navigateByUrl('/tabs/active-togls');
      return;
    }

    // if user is not sales or admin, go to create new biz screen
    let userRole: string = this.user.role;
    if (userRole !== UserRole.ADMIN && userRole !== UserRole.SALES) {
      await this.router.navigateByUrl('/tabs/setupbusiness/businesssetup');
    }
  }

  logout() {
    this.backendService.logout();
  }

  /**
   * user clicks create new business
   */
  async newBusiness() {
    this.log('create new business');
    const confirmation = await this.alerts.genericConfirm2(
      UIStringPro.format('NOTIF_CREATE_BUSINESS')
    );

    if (confirmation) {
      this.log('yes clicked');

      // send them to setup business screen with no business, let them create one from scratch
      await this.router.navigateByUrl('/tabs/setupbusiness/businesssetup');
    }
  }

  /**
   * when user clicks claim
   * @param biz
   */
  async claimBusiness(biz: Business) {
    this.log('clicked claim: ' + biz.businessID);

    const confirmation = await this.alerts.genericConfirm2(
      UIStringPro.format('NOTIF_CONFIRM_CLAIM_BIZ', {
        bizname: biz.name,
        address: biz.displayAddressOneLine,
      })
    );

    if (confirmation) {
      this.log('claiming business: ' + biz.businessID);
      try {
        await this.backendService.claimBusinessForCurrentUser(biz.businessID);
        this.log('setting business ' + biz.businessID);
        await this.backendService.setCurrentBusiness(biz.businessID);

        await this.alerts.alertOk(
          UIStringPro.format('NOTIF_CLAIM_BIZ_SUCCESS')
        );
        this.log('going to edit biz page');
        await this.router.navigateByUrl('/tabs/setupbusiness/businesssetup');
      } catch (e) {
        await this.alerts.alertOk(UIStringPro.format('NOTIF_CLAIM_BIZ_FAILED'));
      }
    }
  }

  onSearchInput() {
    this.loading = this.searchBar.value != '';
  }

  onSearchChange() {
    this.searchValue = this.searchBar.value;

    let searchValueExists = !(
      this.searchValue == null || this.searchValue == ''
    );

    this.log(
      `searchvaluexists=${searchValueExists}, value=${this.searchValue}`
    );

    if (searchValueExists) {
      // do the search

      this.loading = true;
      this.infiniteScroll.disabled = false;

      this.backendService
        .searchBusinessName(this.searchValue, null, this.batchSize)
        .then((list) => {
          this.log('search returned: ', list);
          this.businesses = list;
          this.loading = false;
        });
    } else {
      this.businesses = [];
      this.loading = false;
    }
  }

  searchMore() {
    if (this.businesses.length < 1) return;
    let lastBiz = this.businesses[this.businesses.length - 1];
    this.backendService
      .searchBusinessName(this.searchValue, lastBiz.name, this.batchSize)
      .then((list) => {
        this.log('searchMore returned: ', list);
        this.businesses = this.businesses.concat(list);
        if (list.length === this.batchSize) {
          this.infiniteScroll.complete();
        } else {
          this.infiniteScroll.disabled = true;
        }
      });
  }

  handleInfiniteScroll() {
    this.searchMore();
  }

  // login() {
  //   console.log('login');
  //   this.router.navigateByUrl('/tabs/active-togls');
  // }

  // loginTwitter() {
  //   this.router.navigateByUrl('/tabs/active-togls');
  // }

  // loginFacebook() {
  //   this.router.navigateByUrl('/tabs/active-togls');
  // }
}
