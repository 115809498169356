import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { UIStringPro } from '../lang/UIStringPro';

/**
 * handles various alerts for pro app
 */
@Injectable({
  providedIn: 'root',
})
export class AlertProService {
  constructor(
    private alertCtrl: AlertController,
    private router: Router,
    public toastCtrl: ToastController
  ) {}

  /**
   * simple promise-based alert with one button. does NOT use the bell background image alert style
   * @param message message for the alert box
   * @param header optional header
   * @param usePlainStyle whether to use plain style, without the bell icon, or normal, with the bell icon. default true
   * @returns a promise that resolves when the user has dismissed the dialog box
   */
  async alertOk(
    message: string,
    header?: string,
    usePlainStyle: boolean = true
  ) {
    return new Promise<void>((resolve) => {
      return this.alertCtrl
        .create({
          cssClass: usePlainStyle ? 'ok-alert-style' : 'noneactive-alert-style',
          mode: 'md',
          message,
          header,
          buttons: [
            {
              text: UIStringPro.format('BTN_OK'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => resolve(),
            },
          ],
        })
        .then((alert) => alert.present());
    });
  }

  /**
   * promise-based generic confirm function
   * @param message message to display
   * @param yesHandler
   * @param header optional header text
   * @returns a promise that evaluates to true if user confirmed, false if user did not
   */
  async genericConfirm2(message: string, header?: string) {
    return new Promise<boolean>((resolve) => {
      return this.alertCtrl
        .create({
          mode: 'md',
          header: header,
          message: message,
          cssClass: 'ok-alert-style',
          buttons: [
            {
              text: UIStringPro.format('BTN_NO'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => resolve(false),
            },
            {
              text: UIStringPro.format('BTN_YES'),
              handler: () => resolve(true),
            },
          ],
        })
        .then((alert) => alert.present());
    });
  }

  /**
   * DEPRECATED
   * @param message
   * @param yesHandler
   * @param header
   * @returns
   */
  async genericConfirm(
    message: string,
    yesHandler: () => void,
    header?: string
  ) {
    const alert = await this.alertCtrl.create({
      mode: 'md',
      header: header,
      message: message,
      cssClass: 'ok-alert-style',
      buttons: [
        {
          text: UIStringPro.format('BTN_NO'),
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: UIStringPro.format('BTN_YES'),
          handler: yesHandler,
        },
      ],
    });
    return alert.present();
  }

  /**
   * show a simple toast at the top of the screen
   * @param message
   */
  async openAlertToast(message: string) {
    /*
    const toast = await this.toastCtrl.create({
      message: "This is a Notification Example",
      duration: 4000,
    });
    toast.present();
    */
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'top',
      duration: 5000,
      cssClass: 'toast-style',
      buttons: [
        {
          text: UIStringPro.format('BTN_DONE'),
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    toast.present();
  }

  /**
   * promise-based function to display an alert with yes, no, and a text input
   * @param message message to display
   * @param header optional header text
   * @returns a promise that resolves to an object with the user's button and text responses
   */
  async yesNoTextInput(message: string, header?: string) {
    return new Promise<{ response: string; confirmation: boolean }>(
      async (resolve, reject) => {
        const alert = await this.alertCtrl.create({
          mode: 'md',
          header,
          message,
          cssClass: 'ok-alert-style',
          inputs: [
            {
              name: 'response',
              type: 'text',
              placeholder: 'Cancelation Reason',
            },
          ],
          buttons: [
            {
              text: UIStringPro.format('BTN_NO'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: (values) => resolve({ ...values, confirmation: false }),
            },
            {
              text: UIStringPro.format('BTN_YES'),
              handler: (values) => resolve({ ...values, confirmation: true }),
            },
          ],
        });
        await alert.present();
      }
    );
  }
}
