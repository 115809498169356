import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UserRole } from '@mojoapps1/mojoapps1common';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BackendProService } from '../angular-services/backend.pro.service';

/**
 * ensures user is logged in
 */
@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(
    private backendService: BackendProService,
    private router: Router,
    private auth: AngularFireAuth
  ) {}

  /**
   * if user is logged in, return true. otherwise, set redirect url and return login page
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    console.log('loggedin.guard: canActivate');
    return this.auth.authState.pipe(
      take(1),
      map((user) => {
        if (user) {
          // logged in!
          console.log('loggedin.guard: user is logged in: ' + user.uid);
          return true;
        }

        // not logged in
        console.log(
          'loggedin.guard: no logged in user, redirecting to login, requested url is ' +
            state.url
        );
        this.backendService.setRedirectOnLogin(state.url);
        return this.router.parseUrl('/login');
      })
    );
  }
}
