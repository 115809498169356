import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ServiceToggleComponent } from './service-toggle/service-toggle.component';
import { ToglCardProComponent } from './togl-card-pro/togl-card-pro.component';
import { ToglListitemProComponent } from './togl-listitem-pro/togl-listitem-pro.component';
import { ServicesetupToggleComponent } from './servicesetup-toggle/servicesetup-toggle.component';
import { ListPopover } from './list-popover/list-popover.component';
import { HeaderProComponent } from './header-pro/header-pro.component';

import { ReactiveFormsModule } from '@angular/forms';
import { StarRatingModule } from './star-rating/star-rating.module';
import { ModalTimeslot } from './modal-timeslot/modal-timeslot.component';
import { ModalChooseService } from './modal-choose-service/modal-choose-service.component';
import { CategoryItemComponent } from './modal-choose-service/category-item.component';
import { ServiceItemComponent } from './modal-choose-service/service-item.component';
import { AllowHtmlPipe } from '../pipes/allowhtml.pipe';
import { BizServiceSetupComponent } from './bizservice-setup/bizservice-setup.component';
import { AvgUserRating } from './avg-user-rating/avg-user-rating.component';
import { CatSvcProIconComponent } from './catsvcpro-icon/catsvcpro-icon.component';
import { PhoneMaskProDirective } from '../directives/phone-mask.pro.directive';
import { ServiceAutoresponderComponent } from './service-autoresponder/service-autoresponder.component';
import { ModalEndTime } from './modal-endtime/modal-endtime.component';
import { ImageShellComponent } from './image-shell/image-shell.component';
import { AspectRatioComponent } from './aspect-ratio/aspect-ratio.component';
import { ModalPhotoPro } from './modal-photo-pro/modal-photo-pro.component';
import { ModalChoosePhoto } from './modal-choose-photo/modal-choose-photo.component';
import { ModalUserHistory } from './modal-user-history/modal-user-history.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    StarRatingModule,
    RouterModule,
  ],
  declarations: [
    ToglCardProComponent,
    HeaderProComponent,
    ServiceToggleComponent,
    ToglListitemProComponent,
    ServicesetupToggleComponent,
    BizServiceSetupComponent,
    ServiceAutoresponderComponent,
    ListPopover,
    ModalTimeslot,
    ModalEndTime,
    ModalChooseService,
    CategoryItemComponent,
    ServiceItemComponent,
    AllowHtmlPipe,
    AvgUserRating,
    CatSvcProIconComponent,
    PhoneMaskProDirective,
    ImageShellComponent,
    AspectRatioComponent,
    ModalPhotoPro,
    ModalChoosePhoto,
    ModalUserHistory,
  ],
  exports: [
    ToglCardProComponent,
    HeaderProComponent,
    ServiceToggleComponent,
    BizServiceSetupComponent,
    ServiceAutoresponderComponent,
    ToglListitemProComponent,
    ServicesetupToggleComponent,
    ListPopover,
    ModalTimeslot,
    ModalEndTime,
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalChooseService,
    CategoryItemComponent,
    ServiceItemComponent,
    AllowHtmlPipe,
    AvgUserRating,
    CatSvcProIconComponent,
    PhoneMaskProDirective,
    ImageShellComponent,
    AspectRatioComponent,
    ModalPhotoPro,
    ModalChoosePhoto,
    ModalUserHistory,
  ],
})
export class CustomComponentsProModule {}
