import { Injectable, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
// import {} from 'google.maps';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { RTDBPaths } from '@mojoapps1/mojoapps1common';

/**
 * sent when user's position changes
 */
export interface PositionUpdate {
  /**
   * whether we have permission to get user's position
   */
  permitted: boolean;
  /**
   * the position if permitted, undefined if not
   */
  position?: google.maps.LatLngLiteral;
}

@Injectable({
  providedIn: 'root',
})
export class LocationProService {
  private _boisePos: google.maps.LatLngLiteral = {
    lat: 43.700245,
    lng: -116.5064,
  };
  get defaultPosition() {
    return { ...this._boisePos };
  }

  constructor(private rtdb: AngularFireDatabase) {}

  /**
   * get updated on a user position when it changes in the realtime database
   * @returns
   */
  getUserPositionSnapshotChanges(userId: string) {
    return this.rtdb
      .object<PositionUpdate>(`${RTDBPaths.LOCATION}/${userId}`)
      .snapshotChanges();
  }

  /**
   * open a google map url with directions to a particular street address, with no origin specified
   * @param address
   */
  getDirectionsToAddress(address: string) {
    const destinationAddress = encodeURIComponent(address);

    const url = `https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${destinationAddress}`;

    // old - uses user's position from UserData as origin for some reason...
    // let userPosition = encodeURIComponent(
    //   this.userData.position.lat + ',' + this.userData.position.long
    // );
    // let url = `https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&origin=${userPosition}&destination=${destinationAddress}`;

    // let url = "https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=" + encodeURIComponent(this.toglInfo.business.lat + ',' + this.toglInfo.business.long);
    window.open(url);
  }
}
