import { Injectable } from '@angular/core';

import { AverageRating, RTDBPaths } from '@mojoapps1/mojoapps1common';
import { AngularFireDatabase } from '@angular/fire/database';

/**
 * get user and business ratings from RTDB for pro app
 */
@Injectable({
  providedIn: 'root',
})
export class RatingProService {
  constructor(private rtdb: AngularFireDatabase) {}

  getUserRatingValueChangesRTDB(userId: string) {
    return this.rtdb
      .object<AverageRating>(`/${RTDBPaths.USER_RATINGS}/${userId}/_avg`)
      .valueChanges();
  }

  async getUserRatingOnce(userId: string) {
    const snap = await this.rtdb.database
      .ref(`/${RTDBPaths.USER_RATINGS}/${userId}/_avg`)
      .once('value');
    return snap.val() as AverageRating;
  }

  /**
   * convenience function to just get user rating updates as a string suitable for frontend display
   * @param userId user id
   * @param onLabelChange a function that's called with the updated label
   * @returns a subscription, save this and call unsubscribe on it when done
   */
  subscribeUserRatingLabelChanges(
    userId: string,
    onLabelChange: (label: string) => void
  ) {
    return this.getUserRatingValueChangesRTDB(userId).subscribe((val) => {
      const label = val ? val.avg.toFixed(1) : 'unknown';
      return onLabelChange(label);
    });
  }

  getBusinessRatingValueChangesRTDB(businessId: string) {
    return this.rtdb
      .object<AverageRating>(`/${RTDBPaths.BIZ_RATINGS}/${businessId}/_avg`)
      .valueChanges();
  }

  async getBusinessRatingOnce(businessId: string) {
    const snap = await this.rtdb.database
      .ref(`/${RTDBPaths.BIZ_RATINGS}/${businessId}/_avg`)
      .once('value');
    return snap.val() as AverageRating;
  }

  /**
   * convenience function to just get business rating updates as a string suitable for frontend display
   * @param bizId business id
   * @param onLabelChange a function that's called with the updated
   * @returns a subscription, save this and call unsubscribe on it when done
   */
  subscribeBusinessRatingLabelChanges(
    bizId: string,
    onLabelChange: (label: string) => void
  ) {
    return this.getBusinessRatingValueChangesRTDB(bizId).subscribe((val) => {
      const label = val ? val.avg.toFixed(1) : 'unknown';
      return onLabelChange(label);
    });
  }
}
