import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Business, UserData } from '@mojoapps1/mojoapps1common';
import { Subscription } from 'rxjs';
import { BackendProService } from './angular-services/backend.pro.service';
import { UIStringPro } from './lang/UIStringPro';

@Component({
  selector: 'app-page-base',
  template: ``,
})
export class PageBaseProComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();

  /**
   * stores subscriptions to backend data
   */
  private _backendSubscriptions: Subscription;

  /**
   * to distinguish between initial component showings and subsequent ones
   */
  protected _componentHasBeenShown: boolean = false;

  /**
   * to distinguish between when the child class has added subscriptions and when it has not
   */
  protected _backendSubscriptionsExist: boolean = false;

  /**
   * override in child classes
   */
  protected _className: string = 'base';

  constructor(
    protected backendService: BackendProService,
    @Inject(String) className: string
  ) {
    this._className = className;
  }

  /**
   * component init. essentially does nothing thanks to Ionic weirdness.
   *
   * just a stub, nothing to see here
   */
  ngOnInit() {
    this.baseLog(`init`);
  }

  /**
   * just a stub, nothing to see here.
   */
  ngOnDestroy() {
    this.baseLog(`destroy`);
  }

  /**
   * view is being shown
   *
   * subclasses must call superclass version
   *
   * do all your initialization here instead of `ngOnInit` since Ionic doesn't always destroy components.
   *
   * registers `onBizReady` and `onUserReady` with backend service.
   *
   * calls `setupBackendSubscriptions` if `addBackendSubscription` hasn't been called yet (i.e. if `_backendSubscriptionsExist` is `false`).
   */
  ionViewWillEnter() {
    this.baseLog(
      `view will enter, hasbeenshown=${this._componentHasBeenShown}`
    );

    if (!this._backendSubscriptions) {
      this._backendSubscriptions = new Subscription();
      this._backendSubscriptionsExist = false;
    }
    this.baseLog('view will enter, subscribing to biz/user ready events');
    this._backendSubscriptions.add(
      this.backendService
        .onUserReady()
        .subscribe((user) => this.onUserReady(user))
    );
    this._backendSubscriptions.add(
      this.backendService.onBizReady().subscribe((biz) => this.onBizReady(biz))
    );
    if (!this._backendSubscriptionsExist) {
      this.baseLog('setting up backend subscriptions');
      this.setupBackendSubscriptions();
    }
    this._componentHasBeenShown = true;
  }

  /**
   * when component is hidden. calls `clearBackendSubscriptions`
   *
   * subclasses must call superclass version
   */
  ionViewWillLeave() {
    this.baseLog(`view will leave`);

    if (this._backendSubscriptions) {
      this._backendSubscriptions.unsubscribe();
      this._backendSubscriptions = null;
    }
    this._backendSubscriptionsExist = false;
    this.clearBackendSubscriptions();
  }

  private baseLog(...args) {
    args.unshift(`base (${this._className}):`);
    console.log.apply(this, args);
  }

  /**
   * child class log function
   * @param args
   */
  protected log(...args) {
    args.unshift(`${this._className}:`);
    console.log.apply(this, args);
  }

  /**
   * stub to override in child class.
   *
   * called by `ionViewWillEnter` when biz ready event fires
   */
  protected async onBizReady(biz: Business) {
    this.baseLog('onBizReady');
  }

  /**
   * stub to override in child class, called when user ready event fires.
   *
   * called by `ionViewWillEnter`
   */
  protected async onUserReady(u: UserData) {
    this.baseLog('onUserReady');
  }

  /**
   * convenience function to make langauge files available to template code
   * @param key
   * @param replaceValues
   * @returns
   */
  uistring(key: string, replaceValues?: any): string {
    return UIStringPro.format(key, replaceValues);
  }

  /**
   * DEPRECATED
   * add an auto-releasing subscription
   * @param s
   */
  addObserverSubscription(s: Subscription) {
    this._subscriptions.add(s);
  }

  /**
   * DEPRECATED
   * clear all auto-releasing subscriptions.
   */
  clearObserverSubscriptions() {
    this._subscriptions.unsubscribe();
    this._subscriptions = new Subscription();
  }

  /**
   * DEPRECATED
   *
   * call to enable or disable subscriptions to backend data.
   *
   * called with `true` in `ionViewWillEnter`.
   *
   * called with `false` in `ionViewWillLeave`.
   *
   * designed to be overridden by child classes. subclasses must call superclass version first.
   *
   * they can check `_backendSubscriptionsExist` to see if `addBackendSubscription` has been called.
   *
   * @param value whether to enable or disable. calling it twice in a row with `true` won't add more subscriptions.
   */
  protected manageBackendSubscriptions(value: boolean) {
    this.baseLog(`managebackend: ${value}`);
    if (value) {
      if (!this._backendSubscriptions) {
        this._backendSubscriptions = new Subscription();
        this._backendSubscriptionsExist = false;
      }
    } else {
      if (this._backendSubscriptions) {
        this._backendSubscriptions.unsubscribe();
        this._backendSubscriptions = null;
        this._backendSubscriptionsExist = false;
      }
    }
  }

  /**
   * set up your backend subscriptions!
   *
   * stub to be overridden in child class
   *
   * called by `ionViewWillEnter`
   */
  protected setupBackendSubscriptions() {
    this.baseLog('setupBackendSubscriptions');
  }

  /**
   * clear your backend subscriptions!
   *
   * stub to be overridden in child class
   *
   * called by `ionViewWillLeave`
   */
  protected clearBackendSubscriptions() {
    this.baseLog('clearBackendSubscriptions');
  }

  /**
   * add a subscription. don't override in child class
   * @param s
   */
  protected addBackendSubscription(s: Subscription) {
    if (this._backendSubscriptions) {
      this._backendSubscriptions.add(s);
      this._backendSubscriptionsExist = true;
    }
  }
}
