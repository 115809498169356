import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import {
  AngularFirestoreModule,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
} from '@angular/fire/firestore';
import {
  AngularFireDatabaseModule,
  USE_EMULATOR as USE_DATABASE_EMULATOR,
} from '@angular/fire/database';
import {
  AngularFireRemoteConfigModule,
  DEFAULTS,
  SETTINGS,
} from '@angular/fire/remote-config';
import { environment } from '../environments/environment';
import { IonicStorageModule } from '@ionic/storage';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireAuthModule } from '@angular/fire/auth';
import {
  AngularFireFunctionsModule,
  ORIGIN as FUNCTIONS_ORIGIN,
} from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggedInGuard } from './guards/loggedin.guard';
import { RemoteConfig } from '@mojoapps1/mojoapps1common';
import { LoginPage } from './pages/login/login.page';
import { TabsPage } from './tabs/tabs.page';
import { ClaimBusinessPage } from './pages/claim-business/claim-business.page';
import { LoggedOutGuard } from './guards/loggedout.guard';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { CustomComponentsProModule } from './components/customcomponentspro.module';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import remoteConfigDefaults from '../environments/remote_config_defaults.json';
// import { CallNumber } from '@ionic-native/call-number/ngx';

// const firebaseUiAuthConfig: firebaseui.auth.Config = {
//   signInFlow: 'popup',
//   signInOptions: [
//     {
//       provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
//       disableSignUp: {
//         status: true,
//         adminEmail: 'csupport@toglon.com',
//       },
//     },
//   ],
//   tosUrl: () => BrowserProService.terms(),
//   privacyPolicyUrl: () => BrowserProService.privacyPolicy(),
// };

@NgModule({
  declarations: [AppComponent, LoginPage, ClaimBusinessPage],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireRemoteConfigModule,
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireStorageModule,
    HttpClientModule,
    HttpClientJsonpModule,
    CustomComponentsProModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot({
      mode: 'md', // force material design
      // animated:false,
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: DEFAULTS,
      useValue: remoteConfigDefaults,
    },
    {
      provide: SETTINGS,
      useFactory: () =>
        environment.production ? {} : { minimumFetchIntervalMillis: 10_000 },
    },

    LoggedInGuard,
    LoggedOutGuard,
    InAppPurchase2,
    // CallNumber,
    // { provide: USE_FIRESTORE_EMULATOR, useValue: ['localhost', '8080'] }, // uncomment to use firestore emulator
    // { provide: USE_DATABASE_EMULATOR, useValue: ['localhost', '9000'] }, // uncomment to use rtdb emulator
    // { provide: FUNCTIONS_ORIGIN, useValue: 'http://localhost:5001' }, // uncomment to use functions emulator
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
