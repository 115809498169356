import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Category } from '@mojoapps1/mojoapps1common';
import {
  IconInfo,
  IconsProService,
} from 'src/app/angular-services/icons.pro.service';

@Component({
  selector: 'app-catsvcpro-icon',
  templateUrl: './catsvcpro-icon.component.html',
  styleUrls: ['./catsvcpro-icon.component.scss'],
})
export class CatSvcProIconComponent implements OnInit {
  @Input() color: string;
  /**
   * either an SVG filename or an ionic icon name
   */
  @Input() set icon(v: string) {
    this._icon = v;

    // load from service
    this.icons.getIconInfo(this._icon).then((iconInfo) => {
      this.iconInfo = iconInfo;
      console.log('catsvc-icon:', iconInfo);
    });
  }
  get icon() {
    return this._icon;
  }
  private _icon: string;

  iconInfo: IconInfo;

  constructor(private icons: IconsProService) {}

  ngOnInit() {}
}
