import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  IAPProduct,
  IAPProducts,
  InAppPurchase2,
} from '@ionic-native/in-app-purchase-2/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { currentAndFutureValuesObservable } from '../util/currentAndFutureValuesObservable';
import { environment } from '../../environments/environment';
import { BackendServiceBase } from '@mojoapps1/mojoapps1common';

interface ProductInfo {
  id: string;
  isAndroid: boolean;
  isIos: boolean;
}

/**
 * manages in app purchasing
 */
@Injectable({
  providedIn: 'root',
})
export class IAPProService {
  /**
   * updates to the entire product list
   */
  private _productListUpdates: Subject<IAPProduct[]>;
  /**
   * current products
   */
  private _products: IAPProduct[];

  /**
   * updates for individual store products
   */
  private _productUpdates: {
    [key: string]: Subject<IAPProduct>;
  };

  private _initStoreCalled: boolean = false;

  /**
   * all the products the app recognizes across ios and android
   */
  private _allProductsList: ProductInfo[] = [
    {
      id: 'toglonpro.premier.annual',
      isAndroid: false,
      isIos: true,
    },
    {
      id: 'toglonpro.premierplus.annual',
      isAndroid: false,
      isIos: true,
    },
    // {
    //   id: 'toglonpro.premier.semiannual',
    //   isAndroid: true,
    //   isIos: true,
    // },
    // {
    //   id: 'toglonpro.premierplus.semiannual',
    //   isAndroid: true,
    //   isIos: true,
    // },
  ];

  /**
   * product IDs for the current platform
   */
  private _productIdsForCurrentPlatform: string[];
  get productIdsForCurrentPlatform() {
    return this._productIdsForCurrentPlatform;
  }

  constructor(
    private store: InAppPurchase2,
    private platform: Platform,
    private alertController: AlertController,
    private auth: AngularFireAuth
  ) {
    this.log('constructor');

    // initialize observables
    this._productListUpdates = new Subject<IAPProduct[]>();
    this._productUpdates = {};
    for (let product of this._allProductsList) {
      this._productUpdates[product.id] = new Subject<IAPProduct>();
    }
    this._initStoreCalled = false;

    // respond to auth events, save user id in store to associate purchases with user
    this.auth.authState.subscribe(async (u) => {
      await this.platform.ready();
      if (u) {
        this.store.applicationUsername = u.uid;
        this.log(`set applicationUserName to ${u.uid}`);
        this.log(`initStoreCalled: ${this._initStoreCalled}`);
        if (!this._initStoreCalled) {
          // initialize the store when we first have a user
          this.log('initializing store');
          this.platform.ready().then(() => this.initStore());
        }
      } else {
        this.store.applicationUsername = null;
        this.log(`set applicationUserName to null`);
      }
    });
  }

  /**
   * whether the store is finished with initial setup, promisified
   */
  storeReady() {
    return new Promise((resolve) => this.store.ready(resolve));
  }

  /**
   * whether we're in production environment
   * @returns
   */
  isProdEnv(): boolean {
    return environment.database === BackendServiceBase.DB_PROD;
  }

  /**
   * which mobile platform we're on, if any
   * @returns
   */
  getMobilePlatform(): 'android' | 'ios' | undefined {
    if (!this.isNative()) {
      return undefined;
    }
    if (this.isAndroid()) {
      return 'android';
    } else if (this.isIos()) {
      return 'ios';
    } else {
      return undefined;
    }
  }

  /**
   * whether we're in a native mobile app (as opposed to webapp)
   * @returns
   */
  isNative(): boolean {
    return (
      this.platform.is('hybrid') &&
      (this.platform.is('android') || this.platform.is('ios'))
    );
  }

  /**
   * whether we're on android native
   * @returns
   */
  isAndroid(): boolean {
    return this.isNative() && this.platform.is('android');
  }

  /**
   * whether we're on ios native
   * @returns
   */
  isIos(): boolean {
    return this.isNative() && this.platform.is('ios');
  }

  /**
   * get any products the user owns
   * @returns
   */
  getOwnedProducts() {
    if (!this._products) return [];

    return this._products.filter((p) => p.owned);
  }

  /**
   * refresh products from store data.
   */
  private refreshProducts() {
    this.log('refreshing products...');
    this._products = this.store.products
      .filter((p) => this._productIdsForCurrentPlatform.includes(p.id))
      .sort((a, b) => (a.id == 'toglonpro.premierplus.annual' ? -1 : 1));
    this.logProductsShort(`product list:`);
  }

  /**
   * refresh and broadcast product changes. sends a new array each time
   */
  private broadcastProductChanges() {
    this.refreshProducts();
    this.log('broadcasting product changes');
    this._productListUpdates.next(
      this._products ? this._products.map((p) => ({ ...p })) : []
    );
  }

  /**
   * initializes the store conection, only run after deviceready is fired
   */
  private initStore() {
    if (!this.isNative()) {
      this.log('initstore: only on device');
      return;
    }

    // figure out what platform we're on
    const mobilePlatform = this.getMobilePlatform();
    if (!mobilePlatform) {
      this.log('initStore: unknown platform');
      return;
    }

    this.log('initStore: registering IAP products');
    this.store.verbosity = this.store.DEBUG;

    // this.isProdEnv()
    //   ? this.store.ERROR
    //   : this.store.DEBUG;

    // get the list of products for current platform
    this._productIdsForCurrentPlatform = this._allProductsList
      .filter((v) => (mobilePlatform === 'android' ? v.isAndroid : v.isIos))
      .map((v) => v.id);
    this.log(
      `products for this platform: ${this._productIdsForCurrentPlatform.join(
        ', '
      )}`
    );

    // setup receipt validation
    this.store.validator =
      'https://validator.fovea.cc/v1/validate?appName=com.toglon.toglonpro&apiKey=46b38b0d-53c2-45f7-bf32-a8452aa25605';

    // User closed the native purchase dialog
    // this.store.when('toglonpro.premier.semiannual').cancelled((product) => {
    //   this.log('purchase cancelled: toglonpro.premier.semiannual');
    // });
    // this.store.when('toglonpro.premierplus.semiannual').cancelled((product) => {
    //   this.log('purchase cancelled: toglonpro.premierplus.semiannual');
    // });

    // Track all store errors
    this.store.error((err) => {
      this.log('Store Error: ', JSON.stringify(err));
    });

    // register products
    const toRegister = this._productIdsForCurrentPlatform.map((v) => ({
      id: v,
      type: this.store.PAID_SUBSCRIPTION,
    }));
    this.log('registering products: ', JSON.stringify(toRegister));
    this.store.register(toRegister);

    // handle purchase flow and validation, including resuming when it's been interrupted
    for (let productId of this._productIdsForCurrentPlatform) {
      this.store
        .when(productId)
        .approved((p: IAPProduct) => {
          // when product approved, verify transaction
          this.log(`verifying transaction for ${p.id}`);
          this.broadcastProductChanges();
          p.verify()
            .expired((p: IAPProduct) => {
              this.log(`finishing expired transaction for ${p.id}`);
              this.broadcastProductChanges();
              p.finish();
            })
            .success((p: IAPProduct) => {
              this.log(`transaction verified for ${p.id}, finishing`);
              this.broadcastProductChanges();
              // unlock app access here

              p.finish();
            })
            .error((err) => {
              this.log('transaction failed verification', JSON.stringify(err));
            });
        })

        // log when transaction is finished
        .finished((p: IAPProduct) => {
          this.log(`transaction finished for ${p.id}`);
          this.broadcastProductChanges();
        })

        // notify subscribers when product is updated
        .updated((p: IAPProduct) => {
          // this._productUpdates[p.id].next(p);
          if (p.owned) {
            this.log(`${p.id} is owned by ${this.store.applicationUsername}`);
            this.broadcastProductChanges();
          }
        });

      // Run some code only when the store is ready to be used
      this.store.ready(() => {
        this.log('store is ready');

        // refresh products
        this.broadcastProductChanges();

        //  when subscription updated event
        this.store.when('subscription').updated(() => {
          try {
            this.broadcastProductChanges();
          } catch (e) {
            this.log(`error on subscription update event`, JSON.stringify(e));
          }
        });
      });
    }

    // this.store.autoFinishTransactions = true; // to flush out old transactions in testing/sandbox mode

    this._initStoreCalled = true;

    // load in-app products from server
    this.log('refreshing store');
    this.store.refresh();
  }

  purchase(productId) {
    this.store.order(productId).then(
      (p) => {
        // Purchase in progress!
        this.log('purchase in progress');
      },
      (e) => {
        this.presentAlert('Failed', `Failed to purchase: ${e}`);
      }
    );
  }

  // To comply with AppStore rules
  restore() {
    this.store.refresh();
  }

  logProduct(p: IAPProduct) {
    this.log(`debug output for product ${p.id}:`);
    for (let key of Object.keys(p)) {
      if (key != 'transaction' && p[key] != null) {
        this.log(`    product: ${key} = ${p[key]}`);
      }
    }
  }

  async presentAlert(header, message) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  private log(...args) {
    args.unshift(`iapservice:`);
    console.log.apply(this, args);
  }

  private logProductsShort(msg) {
    if (!this._products) {
      this.log(msg, JSON.stringify([]));
      return;
    } else {
      this.log(
        msg,
        JSON.stringify(
          this._products.map((v) => ({
            id: v.id,
            owned: v.owned,
            canPurchase: v.canPurchase,
            expiryDate: v.expiryDate,
          }))
        )
      );
    }
  }

  /**
   * get current products, no updates
   */
  getProductList(): IAPProduct[] {
    return this._products;
  }

  /**
   * be notified when the list of products is loaded or updated
   */
  getProductListUpdates(): Observable<IAPProduct[]> {
    return currentAndFutureValuesObservable<IAPProduct[]>(
      this._products,
      this._productListUpdates.asObservable()
    );
  }

  /**
   * get product updates over time
   */
  getUpdatesForProduct(id: string): Observable<IAPProduct> {
    return currentAndFutureValuesObservable<IAPProduct>(
      this.getProduct(id),
      this._productUpdates[id].asObservable()
    );
  }

  /**
   * get one product now
   * @param id
   * @returns
   */
  getProduct(id: string) {
    return this.store.get(id);
  }

  /**
   * convenience function to manage subscriptions on mobile app
   */
  manageSubscriptions() {
    this.store.manageSubscriptions();
  }
}
