import { Component, OnInit, Input } from '@angular/core';
import { Service } from '@mojoapps1/mojoapps1common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackendProService } from 'src/app/angular-services/backend.pro.service';
import {
  IconInfo,
  IconsProService,
} from 'src/app/angular-services/icons.pro.service';

@Component({
  selector: 'app-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss'],
})
export class ServiceItemComponent implements OnInit {
  /**
   * the service object we're rendering
   */
  @Input() item: Service;

  /**
   * the color of the icon, an ionic color (i.e. "primary", "secondary", etc). defaults to primary
   */
  @Input() iconColor: string;

  /**
   * path to an svg icon
   */
  // iconSvgPath: string;
  /**
   * fallback ion-icon name if no svg given
   */
  // iconFallback: string;

  onReadySubscription: Subscription;

  icon: IconInfo;

  constructor(private icons: IconsProService) {}

  async ngOnInit() {
    // default to primary color
    if (!this.iconColor) this.iconColor = 'primary';

    // this.icon = await this.icons.getIconInfo(this.item.icon);
  }

  ngOnDestroy() {
    if (this.onReadySubscription) {
      this.onReadySubscription.unsubscribe();
      this.onReadySubscription = null;
    }
  }
}
