import { Component } from '@angular/core';

import { MenuController, Platform } from '@ionic/angular';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { UIStringPro } from 'src/app/lang/UIStringPro';
import { Router } from '@angular/router';
import { BackendProService } from './angular-services/backend.pro.service';
import { UserData, UserRole } from '@mojoapps1/mojoapps1common';
import { SplashScreen } from '@capacitor/splash-screen';
import { IAPProService } from './angular-services/iap.pro.service';
import { DarkmodeService } from './angular-services/darkmode.pro.service';

interface MenuItem {
  id: string;
  link?: string;
  label: string;
  icon: string;
  enabled: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  labelBusinessSetup: string;

  menuDefinition: MenuItem[];
  menuModel: MenuItem[];

  constructor(
    private platform: Platform,
    public menuCtrl: MenuController,
    private router: Router,
    private backendService: BackendProService,
    private darkMode: DarkmodeService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.menuDefinition = [
      {
        id: 'about',
        link: '/tabs/setupbusiness/businesssetup',
        label: UIStringPro.format('LABEL_BUSINESS_SETUP_ABOUT'),
        icon: 'information-circle-outline',
        enabled: true,
      },
      // {
      //   id: 'services',
      //   link: '/tabs/setupbusiness/servicessetup',
      //   label: UIStringPro.format('LABEL_BUSINESS_SETUP_SERVICES'),
      //   icon: 'folder-outline',
      //   enabled: true,
      // },
      {
        id: 'gallery',
        link: '/tabs/setupbusiness/photogallery',
        label: UIStringPro.format('TITLE_GALLERY'),
        icon: 'image-outline',
        enabled: true,
      },
      {
        id: 'covid19',
        link: '/tabs/setupbusiness/covidsetup',
        label: UIStringPro.format('LABEL_BUSINESS_SETUP_COVID19'),
        icon: 'checkbox-outline',
        enabled: true,
      },
      // {
      //   id: 'iap',
      //   link: '/tabs/setupbusiness/iap',
      //   label: 'IAP Test',
      //   icon: 'calendar-outline',
      //   enabled: true,
      // },
      {
        id: 'logout',
        label: UIStringPro.format('LABEL_LOGOUT'),
        icon: 'log-out-outline',
        enabled: true,
      },
    ];

    this.labelBusinessSetup = UIStringPro.format('HEADER_BUSINESS_SETUP');

    await this.platform.ready();
    SplashScreen.hide();
    // this.statusBar.styleDefault();
    // this.splashScreen.hide();

    // dark mode support
    this.darkMode.initialize();
    // // Use matchMedia to check the user preference
    // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    // console.log('app.component: prefersdark: ' + prefersDark.matches);
    // this.toggleDarkTheme(prefersDark.matches);

    // // Listen for changes to the prefers-color-scheme media query
    // prefersDark.addEventListener('change', (mediaQuery) =>
    //   this.toggleDarkTheme(mediaQuery.matches)
    // );

    this.backendService.onUserReady().subscribe((u) => {
      console.log('app.component: user ready');
      this.initializeMenu(u);
    });

    this.backendService.userDataValueChanges().subscribe((userData) => {
      this.initializeMenu(userData);
    });
  }

  /**
   * Add or remove the "dark" class based on if the media query matches
   * @param shouldAdd
   */
  toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark', shouldAdd);
  }

  initializeMenu(u: UserData) {
    this.menuModel = [];
    for (const item of this.menuDefinition) {
      let newItem: MenuItem = { ...item };

      if (u) {
        // user is logged in, change menu enabled status
        if (u.role === UserRole.BUSINESS && !u.businessId) {
          // business user with no business can only create a business or log out
          newItem.enabled = item.id == 'about' || item.id == 'logout';
        }
      }

      this.menuModel.push(newItem);
    }
  }

  async clickMenuItem(item: MenuItem) {
    // special log out behavior
    if (item.id === 'logout') {
      const confirmation = await this.backendService.logoutWithConfirmation();
      if (confirmation) {
        return this.toggleMenu();
      }
    }

    // navigate to link if any
    if (item.link) {
      await this.router.navigateByUrl(item.link);
      return this.toggleMenu();
    }
  }

  async toggleMenu() {
    // console.log('toggle');
    return this.menuCtrl.toggle();
  }

  public enableSwipe(): void {
    console.log('enableSwipe');
    //this.menuCtrl.swipeEnable(true);
  }

  public disableSwipe(): void {
    console.log('disableSwipe');
    //this.menuCtrl.swipeEnable(false);
  }

  onLogout() {}
}
