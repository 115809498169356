import {
  add,
  addMinutes,
  differenceInMinutes,
  format,
  isAfter,
  roundToNearestMinutes,
} from 'date-fns';
import firebase from 'firebase/app';
import 'firebase/firestore';

/**
 * helps with date calculations
 */
export class DateUtil2 {
  /**
   * default granularity between valid timeslots is 15 minutes
   */
  public static readonly DEFAULT_GRANULARITY: number = 15;

  /**
   * returns now or the passed in date if any
   * @param start
   * @returns
   */
  public static nowOrStart(start?: Date): Date {
    return roundToNearestMinutes(start || new Date(), { nearestTo: 1 });
  }

  /**
   * add a number of minutes to an optional start date (or now)
   * @param min number of minutes
   * @param start optional
   * @returns
   */
  public static minutesFrom(min: number, start?: Date): Date {
    let from: Date = DateUtil2.nowOrStart(start);
    return addMinutes(from, min);
  }

  /**
   * format date using `yyyy-MM-dd`
   * @param d
   * @returns
   */
  public static formatDate(d: Date): string {
    return format(d, 'yyyy-MM-dd');
  }

  /**
   * format time using `h:mm a`
   * @param d
   */
  public static formatTime(d: Date): string;
  public static formatTime(d: firebase.firestore.Timestamp);
  public static formatTime(d: Date | firebase.firestore.Timestamp): string {
    if (d instanceof firebase.firestore.Timestamp) d = d.toDate();
    return format(d, 'h:mm a');
  }

  /**
   * is `time` at least `granularity` minutes ahead of `from` (or now)?
   * @param time the time in question
   * @param from start time, defaults to now
   * @param granularity optional, defaults to `DEFAULT_GRANULARITY` (15 min)
   * @returns
   */
  public static isValidStarttime(
    time: Date,
    from?: Date,
    granularity?: number
  ) {
    let timeB = DateUtil2.minutesFrom(
      granularity || DateUtil2.DEFAULT_GRANULARITY,
      from
    );
    const deltaMinutes = differenceInMinutes(time, timeB);
    return deltaMinutes > 0;
  }

  /**
   * round a date to the nearest `granularity` minutes
   * @param d
   * @param granularity
   * @returns
   */
  public static roundDate(d: Date, granularity?: number) {
    return roundToNearestMinutes(d, {
      nearestTo: granularity || DateUtil2.DEFAULT_GRANULARITY,
    });
  }

  /**
   * calculate the soonest valid start time for a timeslot
   * @param granularity
   * @param from
   * @returns
   */
  public static soonestValidStarttime(granularity?: number, from?: Date): Date {
    // determine the soonest start time for this timeslot
    // start with now, round to desired granularity, add 1 unit of granularity
    // if it's not valid, add one more unit of granularity

    let gran = granularity || DateUtil2.DEFAULT_GRANULARITY;
    let rounded = DateUtil2.roundDate(DateUtil2.nowOrStart(from), gran);
    let start = addMinutes(rounded, gran);
    if (!DateUtil2.isValidStarttime(start, from, gran)) {
      // add 1 unit of granularity
      start = addMinutes(start, gran);
    }
    return start;
  }

  /**
   * soonest valid end time for a timeslot
   * @param start
   * @param granularity
   * @returns
   */
  public static soonestValidEndtime(start?: Date, granularity?: number): Date {
    if (!start) {
      start = DateUtil2.soonestValidStarttime(granularity);
    }

    return add(start, {
      minutes: granularity || DateUtil2.DEFAULT_GRANULARITY,
    });
  }
}
