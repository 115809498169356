import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  BizSamedaySlot,
  BizServiceInfo,
  Business,
  DateUtil,
  CollectionNames,
  Togl,
} from '@mojoapps1/mojoapps1common';
import {
  add,
  isAfter,
  parseISO,
  roundToNearestMinutes,
  formatISO,
} from 'date-fns';
import { BackendProService } from 'src/app/angular-services/backend.pro.service';
import { PageBaseProComponent } from 'src/app/pagebasepro.component';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { AngularFirestore } from '@angular/fire/firestore';
import { UIStringPro } from 'src/app/lang/UIStringPro';
import { LoadingScreenProService } from 'src/app/angular-services/loadingscreen.pro.service';
import { DateUtil2 } from 'src/app/util/DateUtil2';
import { AlertProService } from 'src/app/angular-services/alert.pro.service';

export interface ModalEndTimeResult {
  end: firebase.firestore.Timestamp;
}

/**
 * choose a date
 *
 * on dismiss, returns `role` `update`, `add`, or `delete`
 */
@Component({
  selector: 'modal-endtime',
  templateUrl: './modal-endtime.component.html',
  styleUrls: ['./modal-endtime.component.scss'],
})
export class ModalEndTime extends PageBaseProComponent {
  @Input() editMode: boolean;
  @Input() granularity: number;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() time: firebase.firestore.Timestamp;

  formGroup: FormGroup;

  endTime: Date;

  minuteValues: string;

  business: Business;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    backendService: BackendProService,
    private loadingScreen: LoadingScreenProService,
    private alerts: AlertProService
  ) {
    super(backendService, 'modal-endtime');
  }

  /**
   * when biz is ready
   */
  protected async onBizReady(biz: Business) {
    this.business = this.backendService.currentBusiness;

    // set default granularity
    if (this.granularity == null || this.granularity == 0) {
      this.granularity = DateUtil2.DEFAULT_GRANULARITY;
    }

    // validate granularity
    if (60 % this.granularity !== 0) {
      throw new Error(
        'granularity ' + this.granularity + ' does not evenly divide into 60'
      );
    }

    // calculate minute values for the picker
    let tmpArray = [];
    for (let i = 0; i < 60; i += this.granularity) {
      tmpArray.push(i);
    }
    this.minuteValues = tmpArray.join(',');

    if (!this.editMode) {
      // choose start and end time
      this.endTime = DateUtil2.soonestValidStarttime(this.granularity);
    } else {
      // coerce start and end time to today's date
      let today: Date = new Date();

      this.endTime = this.time.toDate();
      this.endTime.setDate(today.getDate());
      this.endTime.setMonth(today.getMonth());
      this.endTime.setFullYear(today.getFullYear());
    }

    this.log(this.formatDate(this.endTime));

    this.formGroup = this.formBuilder.group({
      endTime: new FormControl(this.formatDate(this.endTime), (c) => {
        if (
          DateUtil2.isValidStarttime(
            parseISO(c.value),
            new Date(),
            this.granularity
          )
        ) {
          return null;
        }
        return { nopast: true };
      }),
    });

    if (this.editMode) {
      this.formGroup.controls['endTime'].updateValueAndValidity();
    }
  }

  /**
   * when save button clicked, return data and role `update` or `add` to calling code
   * to update or create timeslot
   */
  async onSaveClick() {
    // grab the values
    this.log('save');
    let end = firebase.firestore.Timestamp.fromDate(
      parseISO(this.formGroup.value['endTime'])
    );

    // return the results to calling code
    await this.modalCtrl.dismiss({ end }, this.editMode ? 'update' : 'add');
  }

  formatDate(d: Date): string {
    return formatISO(d);
  }

  roundDate(d: Date) {
    return roundToNearestMinutes(d, {
      nearestTo: this.granularity,
    });
  }

  validation_messages = {
    endTime: [
      {
        type: 'nopast',
        message: UIStringPro.format('VALIDATION_TIMESLOT_END_NOPAST'),
      },
    ],
  };

  /**
   * dismiss the modal and send a `cancel` role
   */
  async dismiss() {
    await this.modalCtrl.dismiss(undefined, 'cancel');
  }

  endTimeOnChange(event) {
    this.log('endTimeOnChange');
    this.log(event.target.value);

    this.endTime = parseISO(event.target.value);
    this.log(this.endTime);
  }
}
