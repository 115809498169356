import { Component, OnInit, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { Category } from '@mojoapps1/mojoapps1common';

import { BackendProService } from '../../angular-services/backend.pro.service';
import {
  IconInfo,
  IconsProService,
} from 'src/app/angular-services/icons.pro.service';

@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.scss'],
})
export class CategoryItemComponent implements OnInit {
  /**
   * the category object we're rendering
   */
  @Input() item: Category;
  /**
   * the color of the icon, an ionic color (i.e. "primary", "secondary", etc). defaults to primary
   */
  @Input() iconColor: string;
  /**
   * path to an svg icon
   */
  // iconSvgPath: string;
  /**
   * fallback ion-icon name if no svg given
   */
  // iconFallback: string;
  // services: Observable<Service[]>;
  servicesLabel: string;

  // icon: IconInfo;

  constructor(
    private backendService: BackendProService,
    private icons: IconsProService
  ) {}

  async ngOnInit() {
    // default to primary color for icon
    if (!this.iconColor) this.iconColor = 'primary';

    // this.icon = await this.icons.getIconInfo(this.categoryItem.icon);

    // ignore disabled
    this.backendService
      .getServicesForCategoryCached(this.item.id)
      .then((services) => {
        this.servicesLabel = services.map((s) => s.title).join(', ');
      });

    // this.servicesLabel = this.services.map(v => v.title).join(', ');
    // console.log(this.servicesLabel);
  }
}
