import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UserRole } from '@mojoapps1/mojoapps1common';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BackendProService } from '../angular-services/backend.pro.service';

/**
 * ensures user is logged out
 */
@Injectable()
export class LoggedOutGuard implements CanActivate {
  constructor(private router: Router, private auth: AngularFireAuth) {}

  /**
   * if user is logged out, return true. otherwise, return app homepage
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    console.log('loggedut.guard: canActivate');
    return this.auth.authState.pipe(
      take(1),
      map((user) => {
        if (!user) {
          // logged out!
          console.log('loggedout.guard: user is logged out');
          return true;
        }

        // logged in
        console.log('loggedout.guard: user is logged in, redirecting to /tabs');
        return this.router.parseUrl('/tabs');
      })
    );
  }
}
