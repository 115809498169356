import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  BizSamedaySlot,
  BizServiceInfo,
  Business,
  DateUtil,
  CollectionNames,
  Togl,
} from '@mojoapps1/mojoapps1common';
import {
  add,
  isAfter,
  parseISO,
  roundToNearestMinutes,
  formatISO,
} from 'date-fns';
import { BackendProService } from 'src/app/angular-services/backend.pro.service';
import { PageBaseProComponent } from 'src/app/pagebasepro.component';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { AngularFirestore } from '@angular/fire/firestore';
import { UIStringPro } from 'src/app/lang/UIStringPro';
import { LoadingScreenProService } from 'src/app/angular-services/loadingscreen.pro.service';
import { DateUtil2 } from 'src/app/util/DateUtil2';
import { AlertProService } from 'src/app/angular-services/alert.pro.service';

export interface ModalTimeslotResult {
  start: firebase.firestore.Timestamp;
  end: firebase.firestore.Timestamp;
}

/**
 * add or edit a sameday slot.
 *
 * on dismiss, returns `role` `update`, `add`, or `delete`
 */
@Component({
  selector: 'modal-timeslot',
  templateUrl: './modal-timeslot.component.html',
  styleUrls: ['./modal-timeslot.component.scss'],
})
export class ModalTimeslot extends PageBaseProComponent {
  @Input() serviceInfo: BizServiceInfo;
  @Input() granularity: number;
  @Input() editMode: boolean;
  @Input() slot: BizSamedaySlot;

  title: string;
  subtitle: string;
  formGroup: FormGroup;

  startTime: Date;
  endTime: Date;

  minuteValues: string;

  business: Business;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    backendService: BackendProService,
    private loadingScreen: LoadingScreenProService,
    private alerts: AlertProService
  ) {
    super(backendService, 'modal-timeslot');
  }

  /**
   * when biz is ready
   */
  protected async onBizReady(biz: Business) {
    this.business = this.backendService.currentBusiness;

    // set default granularity
    if (this.granularity == null || this.granularity == 0) {
      this.granularity = DateUtil2.DEFAULT_GRANULARITY;
    }

    // validate granularity
    if (60 % this.granularity !== 0) {
      throw new Error(
        'granularity ' + this.granularity + ' does not evenly divide into 60'
      );
    }

    // title of form
    this.title = UIStringPro.format(
      !this.editMode ? 'TITLE_ADD_TIMESLOT' : 'TITLE_EDIT_TIMESLOT'
    );

    this.subtitle = this.serviceInfo.title;

    // calculate minute values for the picker
    let tmpArray = [];
    for (let i = 0; i < 60; i += this.granularity) {
      tmpArray.push(i);
    }
    this.minuteValues = tmpArray.join(',');

    // choose start and end time
    if (!this.editMode) {
      this.startTime = DateUtil2.soonestValidStarttime(this.granularity);
      this.endTime = add(this.startTime, { minutes: 60 });
    } else {
      // coerce start and end time to today's date
      let today: Date = new Date();

      this.startTime = this.slot.start.toDate();
      this.startTime.setDate(today.getDate());
      this.startTime.setMonth(today.getMonth());
      this.startTime.setFullYear(today.getFullYear());

      this.endTime = this.slot.end.toDate();
      this.endTime.setDate(today.getDate());
      this.endTime.setMonth(today.getMonth());
      this.endTime.setFullYear(today.getFullYear());
    }

    this.log(this.formatDate(this.startTime));
    this.log(this.formatDate(this.endTime));

    this.formGroup = this.formBuilder.group({
      startTime: new FormControl(this.formatDate(this.startTime), (c) => {
        if (
          DateUtil2.isValidStarttime(
            parseISO(c.value),
            new Date(),
            this.granularity
          )
        ) {
          return null;
        }
        return { nopast: true };

        // start time can't be before now
        // let soonestValid: Date = this.soonestValidStarttime();
        // if (isAfter(soonestValid, parseISO(c.value))) {
        //   return { error: true };
        // }
        // return null;
      }),
      endTime: new FormControl(this.formatDate(this.endTime), (c) => {
        // end time can't be before now
        let ret: any = {};
        let d: Date = parseISO(c.value);
        if (isAfter(DateUtil2.soonestValidEndtime(null, this.granularity), d)) {
          ret.nopast = true;
        }

        // end time can't be before start time
        if (
          isAfter(
            DateUtil2.soonestValidEndtime(this.startTime, this.granularity),
            d
          )
        ) {
          ret.nostart = true;
        }

        if (Object.keys(ret).length > 0) return ret;

        return null;
      }),
    });

    if (this.editMode) {
      this.formGroup.controls['startTime'].updateValueAndValidity();
      this.formGroup.controls['endTime'].updateValueAndValidity();
    }
  }

  /**
   * when save button clicked, return data and role `update` or `add` to calling code
   * to update or create timeslot
   */
  async onSaveClick() {
    // grab the values
    this.log('save');
    let start = firebase.firestore.Timestamp.fromDate(
      parseISO(this.formGroup.value['startTime'])
    );
    let end = firebase.firestore.Timestamp.fromDate(
      parseISO(this.formGroup.value['endTime'])
    );

    // return the results to calling code
    await this.modalCtrl.dismiss(
      { start, end },
      this.editMode ? 'update' : 'add'
    );

    // if (!this.editMode) {
    //   // add a new document
    //   let data: BizSamedaySlot = {
    //     start: start,
    //     end: end,
    //     businessId: this.business.businessID,
    //     serviceId: this.serviceInfo.serviceId,
    //     id: this.firestore.createId(),
    //   };
    //   await this.backendService
    //     .docRef(CollectionNames.BIZ_SAMEDAYSLOTS, data.id)
    //     .set(data);
    //   console.log('modal-timeslot: saved new data');
    //   this.dismiss();
    // } else {
    //   // update existing data
    //   let updateData: any = {
    //     start: start,
    //     end: end,
    //   };

    //   console.log(updateData);

    //   // update the backend
    //   await this.backendService
    //     .docRef(CollectionNames.BIZ_SAMEDAYSLOTS, this.slot.id)
    //     .update(updateData);
    //   console.log('modal-timeslot: updated data');
    //   this.dismiss();
    // }
  }

  /**
   * when delete button clicked, make sure deletion is allowed and then send role `delete`
   * to calling code to delete timeslot
   * @returns
   */
  async onDeleteClick() {
    if (!this.editMode) return;

    // don't allow deletion if there's a togl associated with it
    await this.loadingScreen.showLoading();
    const snap = await this.backendService
      .collectionRef<Togl>(CollectionNames.TOGLS, (ref) =>
        ref
          .where('businessId', '==', this.business.businessID)
          .where('samedaySlotId', '==', this.slot.id)
      )
      .get()
      .toPromise();

    this.log(`deleteClick, slot has ${snap.size} togls`);
    if (!snap.empty) {
      // cannot delete
      await this.loadingScreen.hideLoading();
      await this.alerts.alertOk(
        UIStringPro.format('NOTIF_EDIT_TIMESLOT_HAS_TOGL')
      );
    } else {
      // we can delete
      await this.loadingScreen.hideLoading();
      const confirmation = await this.alerts.genericConfirm2(
        UIStringPro.format('NOTIF_DELETE_TIMESLOT'),
        UIStringPro.format('HEADER_ARE_YOU_SURE')
      );
      if (confirmation) {
        await this.modalCtrl.dismiss(undefined, 'delete');
      }
    }
  }

  // soonestValidStarttime(): Date {
  //   // determine the soonest start time for this timeslot
  //   // start with now, round to desired granularity, add 1 unit of granularity
  //   // add one more unit of granularity so that it's at least one unit in future

  //   let start = add(this.roundDate(new Date()), {
  //     minutes: this.granularity,
  //   });
  //   if (isAfter(Date.now(), start)) {
  //     // add 1 unit of granularity
  //     start = add(start, {
  //       minutes: this.granularity,
  //     });
  //   }
  //   return start;
  // }

  // soonestValidEndtime(start?: Date): Date {
  //   if (!start) {
  //     start = this.soonestValidStarttime();
  //   }

  //   return add(start, {
  //     minutes: this.granularity,
  //   });
  // }

  formatDate(d: Date): string {
    return formatISO(d);
  }

  roundDate(d: Date) {
    return roundToNearestMinutes(d, {
      nearestTo: this.granularity,
    });
  }

  validation_messages = {
    startTime: [
      {
        type: 'nopast',
        message: UIStringPro.format('VALIDATION_TIMESLOT_START_NOPAST'),
      },
    ],
    endTime: [
      {
        type: 'nopast',
        message: UIStringPro.format('VALIDATION_TIMESLOT_END_NOPAST'),
      },
      {
        type: 'nostart',
        message: UIStringPro.format('VALIDATION_TIMESLOT_END_BEFORESTART'),
      },
    ],
  };

  /**
   * dismiss the modal and send a `cancel` role
   */
  async dismiss() {
    await this.modalCtrl.dismiss(undefined, 'cancel');
  }

  startTimeOnChange(event) {
    this.log('startTimeOnChange');
    this.log(event.target.value);

    this.startTime = parseISO(event.target.value);
    this.log(this.startTime);

    // set end time to an hour from start time, unless that puts it into tomorrow!
    let hourFromStart = add(this.startTime, { minutes: 60 });
    if (
      DateUtil2.formatDate(hourFromStart) ===
      DateUtil2.formatDate(this.startTime)
    ) {
      // didn't get pushed into tomorrow
      if (this.endTime.valueOf() < hourFromStart.valueOf()) {
        this.endTime = add(this.startTime, { minutes: 60 });
      }
    }
    // make sure that changing start time didn't invalidate end time
    setTimeout(() => {
      this.formGroup.controls['endTime'].updateValueAndValidity();
    }, 0);
  }

  endTimeOnChange(event) {
    this.log('endTimeOnChange');
    this.log(event.target.value);

    this.endTime = parseISO(event.target.value);
    this.log(this.endTime);

    // make sure that changing end time didn't invalidate start time
    this.formGroup.controls['startTime'].updateValueAndValidity();
  }
}
