import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import { BackendProService } from 'src/app/angular-services/backend.pro.service';
import {
  AlertController,
  AlertInput,
  IonFab,
  IonToggle,
  ModalController,
} from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import {
  BizService,
  Service,
  BizServiceInfo,
  BizServices,
  BizSamedaySlot,
  DateUtil,
  CollectionNames,
  ToglDataItem,
  ToglDataType2,
  ToglDataDef,
} from '@mojoapps1/mojoapps1common';

import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
} from '@angular/forms';

import {
  trigger,
  state,
  transition,
  animate,
  style,
} from '@angular/animations';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { UIStringPro } from 'src/app/lang/UIStringPro';
import { toglDataRowValidator } from './validators';
import { ToglDataProService } from 'src/app/angular-services/togldata.pro.service';
import {
  IconInfo,
  IconsProService,
} from 'src/app/angular-services/icons.pro.service';
import { SubscriptionProService } from 'src/app/angular-services/subscription.pro.service';

// const TYPESELECT_OPTION_DATA: {
//   label: string;
//   type: ToglDataType2;
//   premierPlusOnly: boolean;
// }[] = [
//   { label: 'Price', type: 'price', premierPlusOnly: false },
//   { label: 'Quantity', type: 'quantity', premierPlusOnly: false },
//   { label: 'Custom Text', type: 'text', premierPlusOnly: true },
//   { label: 'Length of Time', type: 'duration', premierPlusOnly: false },
//   { label: 'Time of Day', type: 'time', premierPlusOnly: true },
//   { label: 'Link', type: 'url', premierPlusOnly: true },
// ];

// const LABELSELECT_OPTION_DATA: {
//   labels: string[];
//   type: ToglDataType2;
//   label: string;
//   premierPlusOnly: boolean;
// }[] = [
//   {
//     type: 'price',
//     label: 'Price',
//     premierPlusOnly: false,
//     labels: [
//       'Price',
//       'Price Per',
//       'Base Price',
//       'Ticket Price',
//       'Rate',
//       'Cover Chg',
//       'Avg Price',
//       'Hourly Rate',
//       'Minimum',
//       'Fee',
//       'Service Call Fee',
//       'Diagnostic Fee',
//       'Service Fee',
//       'Price Range',
//       'Office Visit',
//       'Adult Admission',
//       'Child Admission',
//       'Senior Admission',
//       'Starting Price',
//       'Consultation Fee',
//       'Exam',
//       'Adjustment',
//       'Physical',
//       'New Patient',
//       'Initial Visit',
//       'Returning Patient',
//       'Session',
//     ],
//   },
//   {
//     type: 'quantity',
//     label: 'Quantity',
//     premierPlusOnly: false,
//     labels: [
//       'Quantity',
//       'Available',
//       'Capacity',
//       'Maximum',
//       'Party Size',
//       'Openings',
//       'Range',
//     ],
//   },
//   {
//     type: 'text',
//     label: 'Custom Text',
//     premierPlusOnly: true,
//     labels: [
//       'Name',
//       'Type',
//       'Featured',
//       'Special',
//       'Specialty',
//       'Exhibit',
//       'Menu',
//       'Online Ordering',
//       'MLS Listing',
//       'Requirement',
//       'Location',
//       'Package',
//       'Free',
//       'Min Age',
//       'Range',
//       'Will Call',
//       'Pickup Location',
//       'Item',
//       'Cuisine',
//       'Details',
//       'Available',
//       'Size',
//       'Artist',
//       'Stylist',
//       'Consultation',
//       'Band',
//       'Event',
//       'Open House',
//       'Options',
//       'Address',
//       'Agent',
//       'Company',
//       'Broker',
//       'sqft',
//       'Realtor',
//       'Exam',
//       'Adjustment',
//       'Physical',
//       'New Patient',
//       'Session',
//     ],
//   },
//   {
//     type: 'time',
//     label: 'Time of Day',
//     premierPlusOnly: true,
//     labels: [
//       'Closing Time',
//       'Start Time',
//       'Showtime',
//       'Doors Open',
//       'Delivery Cutoff',
//       'Time',
//       'Maximum Time',
//     ],
//   },
//   {
//     type: 'duration',
//     label: 'Length of Time',
//     premierPlusOnly: false,
//     labels: [
//       'Duration',
//       'Wait List',
//       'Wait Time',
//       'Available Time',
//       'Average Wait',
//     ],
//   },
//   {
//     type: 'url',
//     label: 'Link',
//     premierPlusOnly: true,
//     labels: [
//       'Menu',
//       'Online Ordering',
//       'MLS Listing',
//       'Website',
//       'Photos',
//       'Virtual Tour',
//       'Details',
//       'Listing',
//       'Open House',
//     ],
//   },
// ];

interface FormConfig {
  interfaceOptions?: {
    header?: string;
    subHeader?: string;
    cssClass?: string;
    backdropDismiss?: boolean;
  };
  placeholder?: string;
}

@Component({
  selector: 'app-bizservice-setup',
  templateUrl: './bizservice-setup.component.html',
  styleUrls: ['./bizservice-setup.component.scss'],
  animations: [
    trigger('fadeOut', [
      transition(':leave', animate(500, style({ opacity: 0 }))),
    ]),
    trigger('fadeIn', [
      transition(':enter', [style({ opacity: 0 }), animate(500)]),
    ]),
  ],
})
export class BizServiceSetupComponent
  implements OnInit, OnDestroy, AfterViewInit {
  form: FormGroup;
  typeSelectInterfaceOptions: any;
  labelSelectInterfaceOptions: any;
  labelSelectOptions: any[];

  // icon: IconInfo;

  /**
   * holds language strings etc
   */
  formConfig: {
    [key: string]: FormConfig;
  };

  /**
   * template shortcut
   */
  get toglDataFormArray() {
    return this.form.controls['toglDataFormArray'] as FormArray;
  }

  /**
   * definitions for togldata
   */
  @Input() toglDataDefs: ToglDataDef[];

  /**
   * the service object we're rendering
   */
  @Input() set serviceInfo(val: BizServiceInfo) {
    this._serviceInfo = val;
    console.log('bizservice-setup: set serviceInfo:');
    console.log(val);

    // this.icons.getIconInfo(val.icon).then((icon) => (this.icon = icon));

    // quantity can't be 0 or null
    if (!this._serviceInfo.quantity) this._serviceInfo.quantity = 1;

    // setup form
    this.form = this.formBuilder.group({
      toglDataFormArray: this.formBuilder.array([]),
    });

    // populate with existing data
    if (this._serviceInfo.toglData) {
      console.log('bizservice-setup: toglData:', this._serviceInfo.toglData);
      for (const item of this._serviceInfo.toglData) {
        this.addToglDataItemFromExisting(item);
      }
      // this.toglDataFormArray.setValue(this._serviceInfo.toglData);
    }

    // set up language strings
    this.formConfig = {
      type: {
        interfaceOptions: {
          header: UIStringPro.format('SERVICESELECT_TYPE_HEADER'),
          subHeader: UIStringPro.format('SERVICESELECT_TYPE_DESCRIPTION', {
            service: this._serviceInfo.title,
          }),
          cssClass: 'bizservice-select',
          backdropDismiss: false,
        },
        placeholder: UIStringPro.format('SERVICESELECT_TYPE_PLACEHOLDER'),
      },
    };
    console.log(this.formConfig);

    // initialize flags
    this.needSave = false;
    this.showSaveButton = false;
    this.saveInProgress = false;
    this.saveFinished = false;
  }
  get serviceInfo(): BizServiceInfo {
    return this._serviceInfo;
  }
  private _serviceInfo: BizServiceInfo;

  @Input() hideType: boolean = true;

  /**
   * the color of the icon, an ionic color (i.e. "primary", "secondary", etc). defaults to primary
   */
  @Input() iconColor: string;

  /**
   * path to an svg icon
   */
  iconSvgPath: string;
  /**
   * fallback ion-icon name if no svg given
   */
  iconFallback: string;

  @Input() businessId: string;

  @ViewChild('toggle') private toggle: IonToggle;
  @ViewChild('samedayToggle') private samedayToggle: IonToggle;

  @Output()
  onSaved: EventEmitter<BizServiceInfo> = new EventEmitter<BizServiceInfo>();

  @Output()
  delete: EventEmitter<BizServiceInfo> = new EventEmitter<BizServiceInfo>();

  needSave: boolean;
  saveInProgress: boolean;
  saveFinished: boolean;
  showSaveButton: boolean;

  // samedaySlots: BizSamedaySlot[];

  constructor(
    private formBuilder: FormBuilder,
    private backendService: BackendProService,
    private modalCtrl: ModalController,
    private toglData: ToglDataProService,
    private icons: IconsProService,
    private subscription: SubscriptionProService,
    private alertCtrl: AlertController
  ) {}

  ngOnInit() {
    if (!this.businessId) throw new Error('businessId required');

    // default to primary color
    if (!this.iconColor) this.iconColor = 'primary';

    console.log('bizservice-setup: onInit: ' + this._serviceInfo.serviceId);
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    console.log('bizservice-setup: ondestroy: ' + this._serviceInfo.serviceId);
  }

  async onClickAdd() {
    const isPremierPlus = this.subscription.isPremierPlus();

    const typeOptions = this.generateTypeSelectOptions();
    const typeInputs: AlertInput[] = typeOptions
      .filter((v) => !v.disabled)
      .map((v) => {
        return { label: v.label, type: 'radio', value: v.value };
      });

    // let the user choose which type of metadata to add
    const typeAlert = await this.alertCtrl.create({
      header: UIStringPro.format('SERVICESELECT_TYPE_HEADER'),
      subHeader: UIStringPro.format('SERVICESELECT_TYPE_DESCRIPTION', {
        service: this._serviceInfo.title,
      }),
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        { text: 'Ok', role: 'confirm' },
      ],
      inputs: typeInputs,
      backdropDismiss: false,
    });

    // this.toglDataDefs
    //     .filter((t) => isPremierPlus || !t.premierPlusOnly)
    //     .map((t) => {
    //       return { label: t.label, type: 'radio', value: t.type };
    //     })

    await typeAlert.present();
    const { data: typeData, role: typeRole } = await typeAlert.onDidDismiss();

    if (typeRole == 'confirm' && typeData && typeData.values) {
      // let the user choose label for the metadata

      const metadataType: ToglDataType2 = typeData.values as ToglDataType2;
      console.log(`bizservice-setup: metadata type: ${metadataType}`);

      const labelOptions = this.generateLabelSelectOptionsForType(metadataType);
      const labelInputs: AlertInput[] = labelOptions.map((v) => {
        return { label: v.label, type: 'radio', value: v.value };
      });
      console.log(
        `bizservice-setup: label options: ${JSON.stringify(labelInputs)}`
      );

      const labelInterfaceOptions = this.generateLabelSelectInterfaceOptionsForType(
        metadataType
      );
      const labelAlert = await this.alertCtrl.create({
        header: labelInterfaceOptions.header,
        subHeader: labelInterfaceOptions.subHeader,
        buttons: [
          { text: 'Cancel', role: 'cancel' },
          { text: 'Ok', role: 'confirm' },
        ],
        inputs: labelInputs,
        backdropDismiss: labelInterfaceOptions.backdropDismiss,
      });
      await labelAlert.present();
      const {
        data: labelData,
        role: labelRole,
      } = await labelAlert.onDidDismiss();

      if (labelRole == 'confirm' && labelData && labelData.values) {
        const label = labelData.values as string;
        console.log(`bizservice-setup: data returned: ${label}`);

        // add a data item according to what they chose
        this.addToglDataItemFromType(metadataType, label);
      }
    }

    // const modal = await this.modalCtrl.create({
    //   component: ModalChooseMetadataType,
    //   cssClass: 'chose-metadata-type-modal',
    //   componentProps: {
    //     toglDataDefs: this.toglDataDefs,
    //     header: UIStringPro.format('SERVICESELECT_TYPE_HEADER'),
    //     subHeader: UIStringPro.format('SERVICESELECT_TYPE_DESCRIPTION'),
    //   },
    // });
    // await modal.present();

    // // wait for modal to dismiss, and then process results
    // const { data, role } = await modal.onDidDismiss();

    // console.log(`servicesetup-toggle: type modal returned, role=${role}`, data);
  }

  /**
   * add a row to the togl data form from existing data
   */
  addToglDataItemFromExisting(
    item: ToglDataItem,
    bypassSaveCheck: boolean = false
  ) {
    const group = this.formBuilder.group(
      {
        type: [item.type],
        label: [{ value: item.label, disabled: !item }],
        value: [item.value],
      },
      { validators: toglDataRowValidator }
    );
    this.toglDataFormArray.push(group);

    if ((!item || !item.label) && !bypassSaveCheck) {
      this.needSave = true;
      this.showSaveButton = true;
    }
  }

  /**
   * add a new row to the togl data form
   */
  addToglDataItemFromType(type: ToglDataType2, label?: string) {
    const group = this.formBuilder.group(
      {
        type: [type],
        label: [label ? label : ''],
        value: [''],
      },
      { validators: toglDataRowValidator }
    );
    this.toglDataFormArray.push(group);

    this.needSave = true;
    this.showSaveButton = true;
  }

  /**
   * remove a row from the togl data form
   * @param i
   */
  deleteFormArrayRow(i) {
    // if (
    //   this.toglDataFormArray.controls.length === 1 &&
    //   !this._serviceInfo.toglData
    // ) {
    //   return;
    // }

    this.toglDataFormArray.removeAt(i);
    this.needSave = true;
    this.showSaveButton = true;
  }

  /**
   * generate options for a type select
   * @returns
   */
  generateTypeSelectOptions() {
    const isPremierPlus = this.subscription.isPremierPlus();

    const options: { label: string; value: string; disabled: boolean }[] = [];
    for (const t of this.toglDataDefs) {
      const shouldBeDisabled = t.premierPlusOnly && !isPremierPlus;
      options.push({
        label: t.label,
        value: t.type,
        disabled: shouldBeDisabled,
      });
    }
    return options;
  }

  /**
   * template function to get the type label for a particular row
   * @param i
   * @returns
   */
  getSecondColumnLabel(i) {
    if (!this.hideType) {
      return 'Label';
    }
    const row = this.toglDataFormArray.controls[i] as FormGroup;
    const type = row.value.type;
    if (!this.toglDataDefs) return 'Label';
    const option = this.toglDataDefs.find((i) => i.type == type);
    return option ? option.label : 'Label';
  }

  generateLabelSelectOptionsForType(
    type: ToglDataType2
  ): { label: string; value: string }[] {
    const option = this.toglDataDefs.find((i) => i.type == type);
    const options = [];
    if (!option || !option.labels) {
      return options;
    }

    for (let j = 0; j < option.labels.length; j++) {
      const label = option.labels[j];
      if (type == 'url') {
        let newLabel = label;
        if (
          option.linkIsPostConfirmOnly &&
          option.linkIsPostConfirmOnly.length > j
        ) {
          const prePostText = option.linkIsPostConfirmOnly[j] ? 'Post' : 'Pre';
          newLabel = `${label} - ${prePostText}`;
        }
        options.push({ label: newLabel, value: label });
      } else {
        options.push({ label, value: label });
      }
    }
    options.sort((a, b) => (a.label < b.label ? -1 : 1));

    return options;
  }

  /**
   * generate options for a label select, based on the type selected for the row
   * @param i which row in the form to generate options for
   */
  generateLabelSelectOptions(i) {
    const row = this.toglDataFormArray.controls[i] as FormGroup;
    const type = row.value.type;

    return this.generateLabelSelectOptionsForType(type);

    // const option = this.toglDataDefs.find((i) => i.type == type);
    // const options = [];
    // if (!option || !option.labels) {
    //   return options;
    // }

    // for (let j = 0; j < option.labels.length; j++) {
    //   const label = option.labels[j];
    //   if (type == 'url') {
    //     let newLabel = label;
    //     if (
    //       option.linkIsPostConfirmOnly &&
    //       option.linkIsPostConfirmOnly.length > j
    //     ) {
    //       const prePostText = option.linkIsPostConfirmOnly[j] ? 'Post' : 'Pre';
    //       newLabel = `${label} - ${prePostText}`;
    //     }
    //     options.push({ label: newLabel, value: label });
    //   } else {
    //     options.push({ label, value: label });
    //   }
    // }
    // options.sort((a, b) => (a.label < b.label ? -1 : 1));

    // return options;
  }

  generateLabelSelectInterfaceOptionsForType(type: string) {
    const option = this.toglDataDefs.find((i) => i.type == type);

    const subHeader =
      type == 'url'
        ? UIStringPro.format('SERVICESELECT_LABEL_DESCRIPTION_LINKS')
        : UIStringPro.format('SERVICESELECT_LABEL_DESCRIPTION');

    return {
      header: UIStringPro.format('SERVICESELECT_LABEL_HEADER', {
        type: option.label,
      }),
      subHeader,
      cssClass: 'bizservice-select',
      backdropDismiss: false,
    };
  }

  /**
   * generate interface options for a label select, based on the type selected for the row
   * @param i which row in the form to generate options for
   */
  generateLabelSelectInterfaceOptions(i) {
    const row = this.toglDataFormArray.controls[i] as FormGroup;
    const type = row.value.type;
    return this.generateLabelSelectInterfaceOptionsForType(type);
  }

  /**
   * get placeholder string for template
   * @returns
   */
  getLabelSelectPlaceholder() {
    return UIStringPro.format('SERVICESELECT_LABEL_PLACEHOLDER');
  }

  /**
   *
   * @param matchesType
   * @returns
   */
  private getAddonConfig(matchesType: ToglDataType2) {
    return this.toglData.getAddonConfig(matchesType);
  }

  /**
   * template function, does this type have a start addon
   * @param matchesType
   * @returns
   */
  hasAddonStart(matchesType: ToglDataType2) {
    const config = this.getAddonConfig(matchesType);
    return config ? config.placement == 'start' : false;
  }

  /**
   * template function, does this type have an end addon
   * @param matchesType
   * @returns
   */
  hasAddonEnd(matchesType: ToglDataType2) {
    const config = this.getAddonConfig(matchesType);
    return config ? config.placement == 'end' : false;
  }

  /**
   * template function, get addon text
   * @param matchesType
   * @returns
   */
  getAddonText(matchesType: ToglDataType2) {
    let config = this.getAddonConfig(matchesType);
    return config ? config.text : null;
  }

  getAddonConfigValue(matchesType: ToglDataType2, key: string) {
    let config = this.getAddonConfig(matchesType);
    return config ? config[key] : null;
  }

  /**
   * when a type select's value changes
   * @param event
   * @returns
   */
  onTypeSelectChange(event) {
    const id: string = event.target.id;
    if (!id) return;

    console.log(event);
    console.log(event.detail);

    const i: number = parseInt(id.split('-')[1]);
    const value = event.detail.value;
    const row = this.toglDataFormArray.controls[i] as FormGroup;
    const oldValue = row.value['type'];
    console.log(
      `bizservice-setup: type select change ${i}: ${oldValue} - ${value}`
    );

    // clear selection on corresponding label select
    row.patchValue({ label: null });
    row.get('label').enable();

    // add or remove addons as necessary on value box depending on type

    this.needSave = true;
    this.showSaveButton = true;
  }

  /**
   * when a label select's value changes
   * @param event
   * @returns
   */
  onLabelSelectChange(event) {
    const id: string = event.target.id;
    if (!id) return;
    const i: number = parseInt(id.split('-')[1]);
    const value = event.detail.value;
    console.log(`bizservice-setup: label select change ${i}: ${value}`);

    this.needSave = true;
    this.showSaveButton = true;
  }

  /**
   * save service data
   */
  clickSave() {
    if (!this.businessId) throw new Error('missing business id');

    this.saveInProgress = true;
    this.showSaveButton = false;

    this._serviceInfo.toglData = this.toglDataFormArray.value;
    console.log('bizservice-setup: clickSave', this._serviceInfo.toglData);

    // prep update data
    let updateData: any = {
      [this._serviceInfo.serviceId + '.toglData']: this._serviceInfo.toglData,
    };

    // update backend
    setTimeout(async () => {
      await this.backendService
        .docRef<BizServices>(CollectionNames.BIZ_SERVICES, this.businessId)
        .update(updateData);

      console.log(
        'bizservice-setup: updated biz services data for ' +
          this._serviceInfo.serviceId
      );
      this.needSave = false;
      this.saveInProgress = false;

      // update model post-save
      this._serviceInfo.toglData = this.toglDataFormArray.value;
      this.onSaved.emit({ ...this._serviceInfo });
    }, 250);
  }

  /**
   * emit a delete event to request the containing page to delete this service
   */
  clickDelete() {
    this.delete.emit({ ...this._serviceInfo });
  }

  /**
   * cancel saving of service data
   */
  clickCancel() {
    console.log('bizservice-setup: click cancel', this._serviceInfo.toglData);
    this.needSave = false;
    this.showSaveButton = false;

    this.toglDataFormArray.clear();
    if (this._serviceInfo.toglData) {
      for (const item of this._serviceInfo.toglData) {
        this.addToglDataItemFromExisting(item);
      }
      this.toglDataFormArray.setValue(this._serviceInfo.toglData, {
        emitEvent: false,
      });
    }
  }

  onToglDataValueChange(event) {
    console.log(event.detail.value);
    this.needSave = true;
    this.showSaveButton = true;
  }

  onToglDataValueBlur(event) {}

  onTextChange(event) {
    let key: string = event.target.id;
    let oldValue = this._serviceInfo[key];
    let newValue = this.form.value[key];

    if (oldValue != newValue) {
      this.needSave = true;
      this.showSaveButton = true;
    }
  }

  onTextBlur(event) {
    let key: string = event.target.id;
    let oldValue = this._serviceInfo[key];
    let newValue = this.form.value[key];

    if (oldValue != newValue) {
      console.log(
        'bizservice-setup: blur, ' + key + ', ' + oldValue + ' => ' + newValue
      );

      this.needSave = true;
      this.showSaveButton = true;
      // this.serviceInfo[key] = newValue;

      // automatically toggle off if they become invalidated
      if (this._serviceInfo.activeNow && !this.form.valid) {
        // if there is a toggle, it can be accomplished this way
        if (this.toggle) {
          this.toggle.checked = false;
        } else {
          // otherwise, save this way
          this.backendService
            .docRef<BizServices>(CollectionNames.BIZ_SERVICES, this.businessId)
            .update({
              [this._serviceInfo.serviceId + '.activeNow']: false,
            } as any)
            .then(() => {
              console.log(
                'bizservice-setup: ' +
                  this._serviceInfo.serviceId +
                  '.activeNow' +
                  '=' +
                  newValue
              );
              this._serviceInfo.activeNow = newValue;
            });
        }
      }
    }
  }
}
