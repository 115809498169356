import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BackendServiceBase,
  UserData,
  UserRole,
} from '@mojoapps1/mojoapps1common';
import { environment } from 'src/environments/environment';
import { AlertProService } from './alert.pro.service';
import { AngularFireFunctions } from '@angular/fire/functions';

/**
 * contains info about subscription types
 */
export interface ToglonProSubscriptionInfo {
  wcProductIds: string[];
  name: string;
  type: ToglonProSubscriptionType;
  isDefault?: boolean;
  keyword?: string;
}

/**
 * contains information about a user's toglon pro subscription
 */
export interface ToglonProSubscription {
  /**
   * whether subscription is active
   */
  active: boolean;
  /**
   * date subscription expires/expired
   */
  expiration: Date;
  /**
   * platform the purchase was made on, apple/google/web
   */
  platform: string;
  /**
   * human readable platform name
   */
  platformName: string;
  /**
   * payment method
   */
  paymentMethod: string;

  /**
   * name of subscription
   */
  subscriptionName: string;

  /**
   * product id in woocommerce
   */
  productId: string;
  /**
   * subscription type
   */
  type: ToglonProSubscriptionType;
}

/**
 * types of subscriptions
 */
export enum ToglonProSubscriptionType {
  PREMIER = 'premier',
  PREMIER_PLUS = 'premierplus',
  NONE = 'none',
}

/**
 * example schema
 * ```
 * {
    "active": false,
    "expiration": 1658284108000,
    "platform": "apple",
    "paymentMethod": "iap",
    "lineItems": [
      {
        "id": 994,
        "name": "Togl-On Premier Plus",
        "product_id": 1695,
        "variation_id": 0,
        "quantity": 1,
        "tax_class": "",
        "subtotal": "699.99",
        "subtotal_tax": "0.00",
        "total": "699.99",
        "total_tax": "0.00",
        "taxes": [],
        "meta_data": [],
        "sku": "togl0301",
        "price": 699.99,
        "parent_name": null
      }
    ],
    "name": "Togl-On Premier Plus",
    "productId": 1695
  }
  ```
 */
interface SubscriptionResponse {
  active: boolean;
  expiration: number;
  platform: string;
  paymentMethod: string;
  name: string;
  productId: string;
  lineItems: any[];
}

/**
 * handles subscriptions for pro app
 */
@Injectable({
  providedIn: 'root',
})
export class SubscriptionProService {
  public static readonly SUBSCRIPTION_INFO: ToglonProSubscriptionInfo[] = [
    {
      wcProductIds: ['1459'],
      name: 'Togl-On Premier',
      type: ToglonProSubscriptionType.PREMIER,
      isDefault: true,
    },
    {
      wcProductIds: ['1695'],
      name: 'Togl-On Premier Plus',
      type: ToglonProSubscriptionType.PREMIER_PLUS,
      keyword: 'plus',
      isDefault: false,
    },
  ];

  /**
   * demo subscription type
   */
  private _demoSubscriptionType: ToglonProSubscriptionType =
    ToglonProSubscriptionType.PREMIER_PLUS;
  /**
   * whether demo sub is active
   */
  private _demoSubActive: boolean = true;

  private _user: UserData;
  private _subscription: ToglonProSubscription | null;

  constructor(
    private fns: AngularFireFunctions,
    private alerts: AlertProService,
    private router: Router
  ) {
    console.log(`subscriptionservice: constructor`);
  }

  /**
   * to be called by backendservice when user logs in/out, so we can get the whole user object,
   * and not just the firebase user that the auth service gives
   * @param u
   */
  async onUserEvent(u: UserData) {
    if (u != null) {
      // login
      this._user = { ...u };
      console.log(`subscriptionservice: user: ${u.id}`);

      // on login, fetch subscription info
      await this.checkSubscriptionStatus();
    } else {
      // logout
      this._user = null;
      this._subscription = null;
      console.log(`subscriptionservice: user logout`);
    }
  }

  /**
   * get the subscription info as it exists right now without checking the network
   * @returns
   */
  getCachedSubscriptionStatus() {
    return this._subscription
      ? ({ ...this._subscription } as ToglonProSubscription)
      : null;
  }

  /**
   * whether user has active premier OR premier plus sub
   * @returns
   */
  isActive() {
    if (this._subscription == null) {
      return false;
    }
    return (
      this._subscription.active &&
      (this._subscription.type == ToglonProSubscriptionType.PREMIER ||
        this._subscription.type == ToglonProSubscriptionType.PREMIER_PLUS)
    );
  }

  /**
   * whether user has an active premier subscription
   * @returns
   */
  isPremier() {
    if (this._subscription == null) {
      return false;
    }
    return (
      this._subscription.active &&
      this._subscription.type == ToglonProSubscriptionType.PREMIER
    );
  }

  /**
   * whether user is at least premier plus level, active subscription
   * @returns
   */
  isPremierPlus() {
    if (this._subscription == null) {
      return false;
    }
    return (
      this._subscription.active &&
      this._subscription.type == ToglonProSubscriptionType.PREMIER_PLUS
    );
  }

  /**
   * upsell the user to premier
   */
  async userPromptPremierLevel() {
    const confirmation = await this.alerts.genericConfirm2(
      'You must have at least a premier membership to do that. Would you like to upgrade now?'
    );
    if (confirmation) {
      await this.router.navigateByUrl('/tabs/settings/subscription');
    }
  }

  /**
   * upsell the user to premier plus
   */
  async userPromptPremierPlusLevel() {
    const confirmation = await this.alerts.genericConfirm2(
      'You must have a premier plus membership to do that. Would you like to upgrade now?'
    );
    if (confirmation) {
      await this.router.navigateByUrl('/tabs/settings/subscription');
    }
  }

  /**
   * convert wc product id to subscription type
   * @param productId
   * @returns
   */
  getSubscriptionTypeFromWCProductId(productId: string) {
    for (const info of SubscriptionProService.SUBSCRIPTION_INFO) {
      if (info.wcProductIds.includes(productId)) {
        return info.type;
      }
    }
    return ToglonProSubscriptionType.NONE;
  }

  /**
   * figure out subscription type from api response
   * @param response
   * @returns
   */
  getSubscriptionTypeFromResponse(response: SubscriptionResponse) {
    // check for product id match
    for (const info of SubscriptionProService.SUBSCRIPTION_INFO) {
      if (info.wcProductIds.includes(response.productId)) {
        return info.type;
      }
    }

    // check for keyword in name if any
    for (const info of SubscriptionProService.SUBSCRIPTION_INFO) {
      if (info.keyword) {
        const keyword = info.keyword.toLowerCase();
        const name = response.name.toLowerCase();
        if (name.includes(keyword)) {
          return info.type;
        }
      }
    }

    // nothing matched, look for a default subscription and return that
    const defaultSub = SubscriptionProService.SUBSCRIPTION_INFO.find(
      (v) => v.isDefault
    );
    if (response && defaultSub) return defaultSub.type;

    // didn't match anything
    return ToglonProSubscriptionType.NONE;
  }

  /**
   * check subscription status with the server
   * @returns
   */
  async checkSubscriptionStatus() {
    if (!this._user) throw new Error('not initialized!');

    // example function return schema:
    // {
    //   "success": true,
    //   "subscription": {
    //     "active": false,
    //     "expiration": 1658284108000,
    //     "platform": "apple",
    //     "paymentMethod": "iap",
    //     "lineItems": [
    //       {
    //         "id": 994,
    //         "name": "Togl-On Premier Plus",
    //         "product_id": 1695,
    //         "variation_id": 0,
    //         "quantity": 1,
    //         "tax_class": "",
    //         "subtotal": "699.99",
    //         "subtotal_tax": "0.00",
    //         "total": "699.99",
    //         "total_tax": "0.00",
    //         "taxes": [],
    //         "meta_data": [],
    //         "sku": "togl0301",
    //         "price": 699.99,
    //         "parent_name": null
    //       }
    //     ],
    //     "name": "Togl-On Premier Plus",
    //     "productId": 1695
    //   }
    // }

    return new Promise<ToglonProSubscription | null>((resolve, reject) => {
      // if not production, OR if sales/admin, use dummy values

      let userOverride = false;
      if (this._user != null) {
        const role = this._user.role;
        if (role === UserRole.ADMIN || role === UserRole.SALES) {
          userOverride = true;
        }
      }

      // use subscription demo mode?
      if (environment.database !== BackendServiceBase.DB_PROD || userOverride) {
        const yearInFuture = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365);
        const weekAgo = new Date(Date.now() - 1000 * 60 * 60 * 24 * 7);
        if (
          this._demoSubscriptionType === ToglonProSubscriptionType.PREMIER_PLUS
        ) {
          this._subscription = {
            active: this._demoSubActive,
            expiration: this._demoSubActive ? weekAgo : yearInFuture,
            platform: 'apple',
            platformName: this.platformName('apple'),
            paymentMethod: 'debug',
            subscriptionName: 'Togl-On Premier Plus (demo)',
            productId: '1695',
            type: ToglonProSubscriptionType.PREMIER_PLUS,
          };
        } else if (
          this._demoSubscriptionType === ToglonProSubscriptionType.PREMIER
        ) {
          this._subscription = {
            active: this._demoSubActive,
            expiration: this._demoSubActive ? weekAgo : yearInFuture,
            platform: 'web',
            platformName: this.platformName('web'),
            paymentMethod: 'debug',
            subscriptionName: 'Togl-On Premier (demo)',
            productId: '1459',
            type: ToglonProSubscriptionType.PREMIER,
          };
        } else if (
          this._demoSubscriptionType === ToglonProSubscriptionType.NONE
        ) {
          this._subscription = null;
        }
        resolve(this._subscription);
        return;
      }

      let callable = this.fns.httpsCallable('checkSubscriptionStatus');
      callable({
        userId: this._user.id,
      }).subscribe(
        (response) => {
          console.log(
            'subscriptionservice: checksubscriptionStatus:',
            JSON.stringify(response)
          );
          const subResponse: SubscriptionResponse = response.subscription;
          const platformName: string = subResponse
            ? this.platformName(subResponse.platform)
            : null;
          const productId = subResponse ? `${subResponse.productId}` : null;
          const type: ToglonProSubscriptionType = subResponse
            ? this.getSubscriptionTypeFromResponse(subResponse)
            : null;
          const sub: ToglonProSubscription = subResponse
            ? {
                active: subResponse.active,
                expiration: new Date(subResponse.expiration),
                platform: subResponse.platform,
                platformName,
                paymentMethod: subResponse.paymentMethod,
                subscriptionName: subResponse.name,
                productId,
                type,
              }
            : null;
          this._subscription = sub ? { ...sub } : null;
          console.log(
            `subscriptionservice: subscription info retrieved:`,
            JSON.stringify(this._subscription)
          );

          resolve(sub);
        },
        (err) => {
          console.log(
            'subscriptionservice: checksubscriptionStatus error:',
            JSON.stringify(err)
          );
          reject(err);
        }
      );
    });
  }

  platformName(platform: string) {
    return platform == 'apple'
      ? 'Apple App Store'
      : platform == 'google'
      ? 'Google Play Store'
      : platform == 'web'
      ? 'Website'
      : '';
  }

  /**
   * configure demo sub
   */
  public setDemoSubscription(
    type: ToglonProSubscriptionType,
    active: boolean = true
  ) {
    this._demoSubscriptionType = type;
    this._demoSubActive = !!active;
  }
}
