import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  canActivate,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
import { LoggedInGuard } from './guards/loggedin.guard';
import { LoginPage } from './pages/login/login.page';
import { ClaimBusinessPage } from './pages/claim-business/claim-business.page';
import { TabsPage } from './tabs/tabs.page';
import { LoggedOutGuard } from './guards/loggedout.guard';

const redirectLoggedInToApp = () => {
  console.log('app-routing: checking authorization to view /login');
  return redirectLoggedInTo('/tabs/active-togls');
};

const routes: Routes = [
  {
    // login page. must be logged out to access.
    path: 'login',
    canActivate: [LoggedOutGuard],
    component: LoginPage,
  },
  {
    // claim a business. must be logged in and must not have a business
    path: 'claimbusiness',
    canActivate: [LoggedInGuard],
    component: ClaimBusinessPage,
  },
  {
    // tabs contains most of the app. must be logged in to access
    path: 'tabs',
    canActivate: [LoggedInGuard],
    // component: TabsPage,
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  { path: '', redirectTo: 'tabs', pathMatch: 'full' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
