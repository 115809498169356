import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {
  AlertController,
  IonInput,
  IonRadioGroup,
  ModalController,
} from '@ionic/angular';
import {
  AverageRating,
  BizSamedaySlot,
  DateUtil,
  Service,
  ToglDataItem,
  ToglDataType2,
  ToglInfo,
  ToglState,
  UserData,
} from '@mojoapps1/mojoapps1common';
import { format } from 'date-fns';
import { BackendProService } from '../../angular-services/backend.pro.service';
import { UIStringPro } from 'src/app/lang/UIStringPro';
import { Router } from '@angular/router';
import { ToglDataProService } from 'src/app/angular-services/togldata.pro.service';
import { LoadingScreenProService } from 'src/app/angular-services/loadingscreen.pro.service';
import { DateUtil2 } from 'src/app/util/DateUtil2';
import { LocationProService } from 'src/app/angular-services/location.pro.service';
import { AlertProService } from 'src/app/angular-services/alert.pro.service';
import { ModalUserHistory } from '../modal-user-history/modal-user-history.component';
// import { CallNumber } from '@ionic-native/call-number/ngx';

@Component({
  selector: 'app-togl-card-pro',
  templateUrl: './togl-card-pro.component.html',
  styleUrls: ['./togl-card-pro.component.scss'],
})
export class ToglCardProComponent implements OnInit, OnDestroy {
  toglDataDisplay: ToglDataItem[];

  service: Service;
  user: UserData;
  distanceLabel: string;
  dateLabel: string;
  pendingExpirationLabel: string;
  pendingExpirationColor: string;

  header: string;
  slotTime: string;
  slotStartLabel: string;

  needsRating: boolean;
  rating: number;

  mainContent: string;

  declineReasons: any[];

  starColor: string = '#3366cc';

  private _refreshInterval;

  @ViewChild('declineReason') declineReason: IonRadioGroup;
  @ViewChild('customDeclineReason') customDeclineReason: IonInput;

  constructor(
    private backendService: BackendProService,
    private alertCtrl: AlertController,
    private router: Router,
    private toglData: ToglDataProService,
    private loadingScreen: LoadingScreenProService,
    private location: LocationProService,
    private modalCtrl: ModalController,
    private alerts: AlertProService // private callNumber: CallNumber
  ) {
    let curDeclineIndex: number = 1;
    this.declineReasons = [];
    // grab decline reasons until there are no more
    while (UIStringPro.isValid(`TEXT_TOGL_DECLINE_REASON${curDeclineIndex}`)) {
      this.declineReasons.push({
        value: UIStringPro.format(`TEXT_TOGL_DECLINE_REASON${curDeclineIndex}`),
      });
      curDeclineIndex++;
    }
  }

  /**
   * user position for distance calculation
   */
  @Input() set userPosition(val: google.maps.LatLngLiteral) {
    this._userPosition = val;
    this.updateDistance();
  }
  get userPosition(): google.maps.LatLngLiteral {
    return this._userPosition;
  }
  private _userPosition: google.maps.LatLngLiteral;

  /**
   * user rating
   */
  @Input() userRating: AverageRating;

  /**
   * togl data to be displayed by the component
   */
  @Input() set toglItem(val: ToglInfo) {
    this._toglItem = val;

    if (this._toglItem.userReceivedRating != null) {
      this.needsRating = false;
      this.rating = this._toglItem.userReceivedRating;
      console.log('togl-card-pro: set rating to ' + this.rating);
    } else {
      this.needsRating = true;
      this.rating = 0;
    }

    const preConfirmStates = [
      ToglState.DENIED,
      ToglState.USER_PENDING,
      ToglState.EXPIRED,
    ];
    const isPostConfirm = !preConfirmStates.includes(this._toglItem.state);

    if (this._toglItem.toglData) {
      // filter togl data for display
      this.toglData
        .filterToglData2(val.toglData, isPostConfirm)
        .then((data) => {
          this.toglDataDisplay = data;
        });
    } else {
      this.toglDataDisplay = null;
    }

    // this.toglDataDisplay = this.toglData.filterToglData(
    //   this._toglItem.toglData,
    //   this._toglItem.state == ToglState.USER_PENDING
    //     ? [ToglDataType2.URL]
    //     : null
    // );

    // unsub if needed and resub
    this.manageBackendSubscriptions(false);
    this.manageBackendSubscriptions(true);

    this.refreshView();
  }
  get toglItem(): ToglInfo {
    return this._toglItem;
  }
  private _toglItem: ToglInfo;

  /**
   * component initialization
   */
  ngOnInit() {
    this.refreshView();
  }

  /**
   * destroy component
   */
  ngOnDestroy() {
    this.manageBackendSubscriptions(false);
  }

  /**
   * setup or tear down backend subscriptions
   * @param value
   */
  private manageBackendSubscriptions(value: boolean) {
    if (value) {
      // subscribe
      if (!this._refreshInterval) {
        // refresh the view every so often so the time is kept up to date
        this._refreshInterval = setInterval(() => this.refreshView(), 10000);
      }
    } else {
      // unsubscribe
      if (this._refreshInterval) {
        clearInterval(this._refreshInterval);
        this._refreshInterval = null;
      }
    }
  }

  /**
   * render ui
   */
  private refreshView() {
    this.service = this._toglItem.service;
    this.user = this._toglItem.user;

    this.dateLabel = this.backendService.getToglinfoDateLabel(
      this._toglItem,
      true
    );

    if (this._toglItem.state == ToglState.USER_PENDING) {
      console.log(this._toglItem._pendingExpirationSeconds);
      let pendingExpirationMillis =
        this._toglItem.createdDate.valueOf() +
        (this._toglItem._pendingExpirationSeconds || 0) * 1000;
      let diff = pendingExpirationMillis - Date.now();
      if (diff > 0) {
        this.pendingExpirationLabel =
          'TOGL request expires in ' +
          this.backendService.millisecondsToHumanReadable(diff);
        this.pendingExpirationColor =
          diff <= 1000 * 5 * 60 ? 'danger' : 'primary';
      }
    }

    this.updateDistance();

    this.header = this._toglItem.service.title;
    if (this._toglItem.samedaySlot) {
      this.slotTime = this.generateTimeslotLabel(this._toglItem.samedaySlot);
      this.slotStartLabel = DateUtil2.formatTime(
        this._toglItem.samedaySlot.start
      );
    }

    switch (this._toglItem.state) {
      case ToglState.USER_PENDING:
        this.mainContent = UIStringPro.format('TEXT_TOGL_DETAIL_PENDING', {
          user: this._toglItem.user.displayName,
        });
        break;
      case ToglState.CONFIRMED:
        if (!this._toglItem.samedaySlot) {
          // regular TOGL

          if (!this._toglItem.serviceAddress) {
            // regular service
            this.mainContent = UIStringPro.format(
              'TEXT_TOGL_DETAIL_CONFIRMED',
              {
                user: this._toglItem.user.displayName,
              }
            );
          } else {
            // mobile service
            this.mainContent = UIStringPro.format(
              'TEXT_TOGL_DETAIL_CONFIRMED_MOBILE',
              {
                address: this._toglItem.serviceAddress,
                user: this._toglItem.user.displayName,
              }
            );
          }
        } else {
          // same-day TOGL

          if (!this._toglItem.serviceAddress) {
            // regular service
            this.mainContent = UIStringPro.format(
              'TEXT_TOGL_DETAIL_CONFIRMED_SAMEDAY',
              {
                user: this._toglItem.user.displayName,
                time: this.slotStartLabel,
              }
            );
          } else {
            // mobile service
            this.mainContent = UIStringPro.format(
              'TEXT_TOGL_DETAIL_CONFIRMED_SAMEDAY_MOBILE',
              {
                address: this._toglItem.serviceAddress,
                user: this._toglItem.user.displayName,
                time: this.slotStartLabel,
              }
            );
          }
        }
        break;
      case ToglState.COMPLETED:
        this.mainContent = UIStringPro.format('TEXT_TOGL_DETAIL_COMPLETED');
        break;
      case ToglState.TAKEN:
        this.mainContent = UIStringPro.format('TEXT_TOGL_DETAIL_TAKEN', {
          user: this._toglItem.user.displayName,
        });
        break;
      case ToglState.USER_CANCELED:
        this.mainContent = UIStringPro.format(
          'TEXT_TOGL_DETAIL_USER_CANCELED',
          { user: this._toglItem.user.displayName }
        );
        break;
      case ToglState.USER_CANCELED_ATFAULT:
        this.mainContent = UIStringPro.format(
          'TEXT_TOGL_DETAIL_USER_CANCELED_ATFAULT',
          { user: this._toglItem.user.displayName }
        );
        break;
      case ToglState.DENIED:
        this.mainContent = UIStringPro.format('TEXT_TOGL_DETAIL_DECLINED');
        break;
      case ToglState.EXPIRED:
        this.mainContent = UIStringPro.format('TEXT_TOGL_DETAIL_EXPIRED');
        break;
      case ToglState.CANCELED_ATFAULT:
        this.mainContent = UIStringPro.format(
          'TEXT_TOGL_DETAIL_CANCELED_ATFAULT',
          { bizname: this._toglItem.business.name }
        );
        break;
      case ToglState.NOSHOW_ATFAULT:
        this.mainContent = UIStringPro.format(
          'TEXT_TOGL_DETAIL_NOSHOW_ATFAULT',
          { user: this._toglItem.user.displayName }
        );
        break;
    }
  }

  /**
   * view function to update distance display
   *
   * note: this is only shown in pending and confirmed states
   */
  private updateDistance() {
    if (!this._toglItem.serviceAddress) {
      // distance to user
      let dist: number = -1;
      if (this._userPosition) {
        dist = this.backendService.getDistanceToPoint(this._userPosition);
      }

      this.distanceLabel =
        dist === -1
          ? 'Distance unknown'
          : dist.toFixed(1) + UIStringPro.format('LABEL_RANGE_MILES');
    } else {
      // distance to service address
      let dist: number = -1;
      if (
        this._toglItem.serviceAddressLat &&
        this._toglItem.serviceAddressLong
      ) {
        const tmpPoint: google.maps.LatLngLiteral = {
          lat: this._toglItem.serviceAddressLat,
          lng: this._toglItem.serviceAddressLong,
        };
        dist = this.backendService.getDistanceToPoint(tmpPoint);
      }

      this.distanceLabel =
        dist === -1
          ? 'Distance unknown'
          : dist.toFixed(1) + UIStringPro.format('LABEL_RANGE_MILES');
    }
  }

  /**
   * when the decline reason radio button changes
   * @param event
   */
  onDeclineReasonChange(event) {
    // console.log(this.declineReason.value);
  }

  /**
   * when the custom decline reason box gets focus
   */
  onCustomDeclineReasonFocus() {
    // set the radio button to custom
    if (this.declineReason.value !== 'custom') {
      this.declineReason.value = 'custom';
      // console.log(this.customDeclineReason.value);
    }
  }

  /**
   * must choose a reason for declining
   * @returns
   */
  private async chooseReasonAlert() {
    await this.alerts.alertOk(
      UIStringPro.format('NOTIF_TOGL_DECLINE_REASON', {
        user: this._toglItem.user.displayName,
      })
    );
  }

  /**
   * if custom reason chosen, it can't be empty
   * @returns
   */
  private async emptyCustomReasonAlert() {
    await this.alerts.alertOk(
      UIStringPro.format('NOTIF_TOGL_DECLINE_CUSTOM_REASON', {
        user: this._toglItem.user.displayName,
      })
    );
  }

  /**
   * get user confirmation and then decline togl
   * @param reason
   * @returns
   */
  private async confirmDecline(reason: string) {
    const confirmation = await this.alerts.genericConfirm2(
      UIStringPro.format('NOTIF_CONFIRM_TOGL_DECLINE')
    );

    if (confirmation) {
      await this.declineTogl(reason);
    }
  }

  /**
   * decline togl with given message
   * @param stateMessage
   */
  private async declineTogl(stateMessage: string) {
    console.log('togl-card-pro: stateMessage:' + stateMessage);
    await this.backendService.setToglDenied(this._toglItem, stateMessage);

    await this.alerts.alertOk(UIStringPro.format('ALERT_TOGL_DECLINED'));
  }

  async onRatingChange(rating) {
    console.log('togl-card-pro: changed rating:', rating);

    if (rating < 0 || rating > 5) return;

    // `Do you want to rate ${this._toglItem.business.name} ${rating} stars? You can't change your rating after you save it. Please refer to the guide below for information.`
    // make sure they want to save the rating
    const confirmation = await this.alerts.genericConfirm2(
      UIStringPro.format('NOTIF_CONFIRM_RATING', {
        name: this._toglItem.user.displayName,
        rating: rating,
      }),

      UIStringPro.format('HEADER_SAVE_RATING')
    );

    if (confirmation) {
      try {
        await this.loadingScreen.showLoading();
        await this.backendService.setToglUserRating(this._toglItem.id, rating);
        await this.loadingScreen.hideLoading();
        await this.alerts.alertOk(UIStringPro.format('NOTIF_RATING_SAVED'));
      } catch (e) {
        await this.loadingScreen.hideLoading();
        await this.alerts.alertOk(
          'Error, please try again later: ' + e.message
        );
      }
    } else {
      this.rating = 0;
    }
  }

  /////////////////////
  // button handlers //
  /////////////////////

  /**
   * when user taps confirm button. confirms togl and navigates to pending togl list.
   */
  async onClickConfirm() {
    await this.backendService.setToglConfirmed(this._toglItem);

    let header: string = UIStringPro.format('HEADER_TOGL_CONFIRMED');
    let msg: string;

    if (!this._toglItem.service.isMobile) {
      if (!this._toglItem.samedaySlot) {
        msg = UIStringPro.format('NOTIF_TOGL_CONFIRMED', {
          name: this._toglItem.user.displayName,
        });
      } else {
        msg = UIStringPro.format('NOTIF_TOGL_CONFIRMED_SAMEDAY', {
          name: this._toglItem.user.displayName,
          time: DateUtil2.formatTime(this._toglItem.samedaySlot.start),
        });
      }
    } else {
      if (!this._toglItem.samedaySlot) {
        msg = UIStringPro.format('NOTIF_TOGL_CONFIRMED_MOBILE', {
          name: this._toglItem.user.displayName,
          address: this._toglItem.serviceAddress,
        });
      } else {
        msg = UIStringPro.format('NOTIF_TOGL_CONFIRMED_SAMEDAY_MOBILE', {
          name: this._toglItem.user.displayName,
          time: DateUtil2.formatTime(this._toglItem.samedaySlot.start),
          address: this._toglItem.serviceAddress,
        });
      }
    }

    await this.alerts.alertOk(msg, header);
    await this.router.navigateByUrl(`/tabs/pending`);
  }

  /**
   * when user taps complete button. completes togl and navigates to individual togl page.
   */
  async onClickComplete() {
    await this.backendService.setToglComplete(this._toglItem);

    // show the individual togl page since it's now completed
    let url = '/tabs/togls/' + this._toglItem.id;
    let msg = UIStringPro.format('NOTIF_TOGL_COMPLETED', {
      name: this._toglItem.user.displayName,
    });

    return this.backendService.openNotificationAlert(
      UIStringPro.format('HEADER_TOGL_COMPLETED'),
      msg,
      url,
      UIStringPro.format('BTN_YES'),
      UIStringPro.format('BTN_NO')
    );
  }

  /**
   * when user taps no show button. sets togl state to no show and does not navigate.
   */
  async onClickNoShow() {
    const alert = await this.alertCtrl.create({
      cssClass: 'ok-alert-style',
      mode: 'md',
      header: UIStringPro.format('HEADER_ARE_YOU_SURE'),
      message: UIStringPro.format('NOTIF_CONFIRM_TOGL_NOSHOW'),

      buttons: [
        {
          text: UIStringPro.format('BTN_YES'),
          handler: async () => this.cancelToglNoshow(),
        },
        {
          text: UIStringPro.format('BTN_NO'),
          role: 'cancel',
          cssClass: 'secondary',
        },
      ],
    });

    return alert.present();
  }

  /**
   * set a togl state to no-show
   */
  private async cancelToglNoshow() {
    await this.backendService.setToglNoshowAtfault(this._toglItem);

    //no bell
    await this.alerts.alertOk(UIStringPro.format('ALERT_TOGL_NOSHOW'));
  }

  /**
   * when user taps cancel button. sets togl state to canceled and does not navigate.
   */
  async onClickCancel() {
    const msg: string = UIStringPro.format('NOTIF_CONFIRM_CANCEL_TOGL', {
      name: this._toglItem.user.displayName,
    });
    const header: string = UIStringPro.format('HEADER_ARE_YOU_SURE');
    const result = await this.alerts.yesNoTextInput(msg, header);
    console.log('togl-card-pro: yes/no box returned', JSON.stringify(result));

    if (result.confirmation) {
      if (!result.response) {
        // didn't provide a reason
        await this.alerts.alertOk(
          'You must provide a reason to cancel a TOGL after you have confirmed it.'
        );
      } else {
        // cancel the togl and include the reason
        await this.cancelToglAtFault(result.response);
      }
    }
  }

  /**
   * set a togl state to canceled at fault
   */
  private async cancelToglAtFault(statusMessage: string) {
    await this.loadingScreen.showLoading();
    try {
      await this.backendService.setToglCanceledAtFault(
        this._toglItem,
        statusMessage
      );
      await this.loadingScreen.hideLoading();
      await this.alerts.alertOk(UIStringPro.format('ALERT_TOGL_CANCELED'));
    } catch (e) {
      await this.loadingScreen.hideLoading();
      await this.alerts.alertOk('Error, please try again later: ' + e.message);
    }
  }

  /**
   * when user taps decline button. declines togl and does not navigate.
   */
  async onClickDecline() {
    if (!this.declineReason.value) {
      // no reason chosen!
      await this.chooseReasonAlert();
    } else {
      if (this.declineReason.value === 'custom') {
        // custom reason needs to be filled out
        if (!this.customDeclineReason.value) {
          await this.emptyCustomReasonAlert();
        } else {
          console.log(
            'togl-card-pro: custom decline reason: ' +
              this.customDeclineReason.value
          );
          await this.confirmDecline(this.customDeclineReason.value as string);
        }
      } else {
        // use chosen canned response
        console.log(
          'togl-card-pro: decline reason: ' + this.declineReason.value
        );
        await this.confirmDecline(this.declineReason.value as string);
      }
    }
  }

  /**
   * when user taps get directions
   */
  onClickGetDirections() {
    if (this._toglItem.serviceAddress) {
      this.location.getDirectionsToAddress(this._toglItem.serviceAddress);
    }
  }

  /**
   * when user taps call button
   */
  onClickCallConsumer() {
    if (!this.user.phoneNumber) return;

    window.open('tel:' + this.user.phoneNumber);

    // this.callNumber
    //   .callNumber(this.user.phoneNumber, true)
    //   .then((res) => console.log('togl-card-pro: Launched dialer!', res))
    //   .catch((err) =>
    //     console.log('togl-card-pro: Error launching dialer', err)
    //   );
  }

  async onClickUser() {
    const modal = await this.modalCtrl.create({
      component: ModalUserHistory,
      cssClass: '',
      componentProps: { id: this._toglItem.userId },
    });
    await modal.present();

    // wait for modal to dismiss, and then process results
    const { data, role } = await modal.onDidDismiss();

    console.log(
      `togl-card-pro: modal returned, role=${role}`,
      JSON.stringify(data)
    );
  }

  // utility functions //////

  /**
   * helper function for accessing language file
   * @param key
   * @param replaceValues
   * @returns
   */
  uistring(key: string, replaceValues?: any): string {
    return UIStringPro.format(key, replaceValues);
  }

  /**
   * generate a label for a timeslot
   * @param slot
   * @returns
   */
  private generateTimeslotLabel(slot: BizSamedaySlot): string {
    return (
      DateUtil2.formatTime(slot.start) + ' - ' + DateUtil2.formatTime(slot.end)
    );
  }

  //////////////////////////////
  // state template functions //
  //////////////////////////////

  isStateActive() {
    return this.backendService.isActiveToglState(this._toglItem.state);
  }

  isStateUserPending() {
    return this._toglItem.state === ToglState.USER_PENDING;
  }

  isStateConfirmed() {
    return this._toglItem.state === ToglState.CONFIRMED;
  }

  isStateCompleted() {
    return this._toglItem.state === ToglState.COMPLETED;
  }

  isStateTaken() {
    return this._toglItem.state === ToglState.TAKEN;
  }

  isStateDenied() {
    return this._toglItem.state === ToglState.DENIED;
  }

  isStateExpired() {
    return this._toglItem.state === ToglState.EXPIRED;
  }

  isStateCanceledAtFault() {
    return this._toglItem.state === ToglState.CANCELED_ATFAULT;
  }

  isStateUserCanceled() {
    return this._toglItem.state === ToglState.USER_CANCELED;
  }

  isStateUserCanceledAtFault() {
    return this._toglItem.state === ToglState.USER_CANCELED_ATFAULT;
  }

  isStateNoshow() {
    return this._toglItem.state === ToglState.NOSHOW_ATFAULT;
  }
}
