import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[phoneMask]',
})
export class PhoneMaskProDirective {
  @Input('phoneMask') control: AbstractControl;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let trimmed = input.value.replace(/\s+/g, '');

    if (trimmed.length > 12) {
      trimmed = trimmed.substr(0, 12);
    }

    trimmed = trimmed.replace(/-/g, '');

    let numbers = [];
    numbers.push(trimmed.substr(0, 3));
    if (trimmed.substr(3, 3) !== '') numbers.push(trimmed.substr(3, 3));
    if (trimmed.substr(6, 4) != '') numbers.push(trimmed.substr(6, 4));
    const newVal = numbers.join('-');
    this.control.setValue(newVal);

    // this is needed in ionic or the input value isn't set right, dumb!
    input.value = newVal;
  }
}
