import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

/**
 * create users
 */
@Injectable({
  providedIn: 'root',
})
export class SignupProService {
  constructor(private fns: AngularFireFunctions) {}

  /**
   * create a user via firebase functions
   * @param email
   * @param password
   * @param username
   * @param name
   * @returns
   */
  createUser(
    email: string,
    password: string,
    username?: string,
    name?: string
  ) {
    return new Promise<any>((resolve, reject) => {
      let callable = this.fns.httpsCallable('createUser');
      callable({
        email,
        password,
        username,
        name,
      }).subscribe(
        (response) => {
          console.log('signupservice: createUser', response);
          resolve(response);
        },
        (err) => {
          console.log('signupservice: createUser error');
          console.log(err);
          console.log(JSON.stringify(err));
          reject(err);
        }
      );
    });
  }
}
