import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { UIStringPro } from 'src/app/lang/UIStringPro';

@Component({
  selector: 'list-popover',
  templateUrl: 'list-popover.component.html',
  styleUrls: ['list-popover.component.scss'],
})
export class ListPopover implements OnInit {
  @Input() list: any[];
  @Input() cancelText: string = UIStringPro.format('BTN_CANCEL');

  constructor(public popoverController: PopoverController) {}

  ngOnInit(): void {
    console.log(this.list);
  }

  onClick(item: any) {
    if (item.click) {
      item.click();
    }
    this.popoverController.dismiss();
  }
}
