import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { ModalController } from '@ionic/angular';
import {
  AverageRating,
  Business,
  CollectionNames,
  Togl,
  ToglState,
  UserData,
} from '@mojoapps1/mojoapps1common';
import {} from 'google.maps';
import { BackendProService } from 'src/app/angular-services/backend.pro.service';
import { RatingProService } from 'src/app/angular-services/rating.pro.service';
import { PageBaseProComponent } from 'src/app/pagebasepro.component';
import { AvgUserRating } from '../avg-user-rating/avg-user-rating.component';

/**
 * choose an image from photo gallery. returns role=choose and the path of the chosen photo
 */
@Component({
  selector: 'modal-user-history',
  templateUrl: './modal-user-history.component.html',
  styleUrls: ['./modal-user-history.component.scss'],
})
export class ModalUserHistory
  extends PageBaseProComponent
  implements OnInit, OnDestroy {
  business: Business;
  loading: boolean;
  @Input() set id(val: string) {
    this._id = val;
    this.log(`user id: ${val}`);
  }
  get id() {
    return this._id;
  }
  private _id: string;
  user: UserData;
  avatarUrl: string;
  userRating: AverageRating;
  noshowTogls: Togl[] = [];
  canceledTogls: Togl[] = [];
  completedTogls: Togl[] = [];

  constructor(
    private modalCtrl: ModalController,
    private afStorage: AngularFireStorage,
    private ratings: RatingProService,
    backendService: BackendProService
  ) {
    super(backendService, 'modal-user-history');
  }

  protected setupBackendSubscriptions(): void {
    // subscribe to user rating changes
    if (!this._id) {
      this.log(`no user id, can't set up backend subscriptions`);
      return;
    }

    this.addBackendSubscription(
      this.ratings.getUserRatingValueChangesRTDB(this._id).subscribe((r) => {
        this.log(`user rating received for ${this._id}:`, r);
        this.userRating = r;
      })
    );
  }

  protected async onBizReady(biz: Business): Promise<void> {
    this.log(`onbizready`);

    await this.loadData();
  }

  private async loadData() {
    if (!this._id) {
      // no user given, bail
      this.log(`no user id provided, dismissing`);
      this.modalCtrl.dismiss();
      return;
    }

    this.loading = true;

    // load user record
    const userSnap = await this.backendService
      .docRef<UserData>(CollectionNames.USERS, this._id)
      .get()
      .toPromise();
    if (!userSnap.exists) {
      // user doesn't exist, bail
      this.log(`user ${this._id} doesn't exist, dismissing`);
      this.modalCtrl.dismiss();
      return;
    }
    this.user = userSnap.data();

    // load user avatar
    if (this.user.avatar != null) {
      const url = await this.afStorage
        .ref(this.user.avatar)
        .getDownloadURL()
        .toPromise();

      if (url) {
        this.avatarUrl = url;
      } else {
        this.avatarUrl = `assets/defaultavatar.png`;
      }
    } else {
      this.avatarUrl = `assets/defaultavatar.png`;
    }

    // load number of no show togls
    const noshowSnap = await this.backendService
      .collectionRef<Togl>(CollectionNames.TOGLS, (ref) =>
        ref
          .where('userId', '==', this._id)
          .where('state', '==', ToglState.NOSHOW_ATFAULT)
      )
      .get()
      .toPromise();
    this.noshowTogls = noshowSnap.docs.map((doc) => doc.data());
    // load number of canceled togls
    const canceledSnap = await this.backendService
      .collectionRef<Togl>(CollectionNames.TOGLS, (ref) =>
        ref
          .where('userId', '==', this._id)
          .where('state', '==', ToglState.USER_CANCELED_ATFAULT)
      )
      .get()
      .toPromise();
    this.canceledTogls = canceledSnap.docs.map((doc) => doc.data());
    // load number of completed togls
    const completedSnap = await this.backendService
      .collectionRef<Togl>(CollectionNames.TOGLS, (ref) =>
        ref
          .where('userId', '==', this._id)
          .where('state', '==', ToglState.COMPLETED)
      )
      .get()
      .toPromise();
    this.completedTogls = completedSnap.docs.map((doc) => doc.data());

    this.loading = false;
  }

  // async pickPhoto(photo: GalleryPhoto) {
  //   this.modalCtrl.dismiss(photo.path, 'choose');
  // }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
