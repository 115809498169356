import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { ModalController } from '@ionic/angular';
import { Business, UserData } from '@mojoapps1/mojoapps1common';
import {} from 'google.maps';
import { BackendProService } from 'src/app/angular-services/backend.pro.service';
import { PageBaseProComponent } from 'src/app/pagebasepro.component';

interface GalleryPhoto {
  path: string;
  url?: string;
}

/**
 * choose an image from photo gallery. returns role=choose and the path of the chosen photo
 */
@Component({
  selector: 'modal-choose-photo',
  templateUrl: './modal-choose-photo.component.html',
  styleUrls: ['./modal-choose-photo.component.scss'],
})
export class ModalChoosePhoto
  extends PageBaseProComponent
  implements OnInit, OnDestroy {
  business: Business;
  loading: boolean;
  photos: GalleryPhoto[];
  @Input() title: string;

  constructor(
    private modalCtrl: ModalController,
    private afStorage: AngularFireStorage,
    backendService: BackendProService
  ) {
    super(backendService, 'modal-choose-photo');
    this.loading = false;
  }

  protected async onBizReady(biz: Business): Promise<void> {
    this.business = biz;

    await this.regenerateDownloadUrls();
  }

  async regenerateDownloadUrls() {
    if (!this.business) {
      this.photos = [];
      return;
    }

    const tmp: GalleryPhoto[] = [];
    if (!this.business.photos) {
      this.business.photos = [];
    }
    for (const photo of this.business.photos) {
      const uploadPath = this.calculateUploadPath(
        this.business.businessID,
        photo
      );
      try {
        const url = await this.afStorage
          .ref(uploadPath)
          .getDownloadURL()
          .toPromise();
        tmp.push({
          path: photo,
          url,
        });
      } catch (e) {
        this.log(`photo not found: ${photo}`);
      }
    }
    this.photos = tmp;
  }

  async pickPhoto(photo: GalleryPhoto) {
    this.modalCtrl.dismiss(photo.path, 'choose');
  }

  private calculateUploadPath(businessId: string, filename: string) {
    return `bizphotos/${businessId}/${filename}`;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
