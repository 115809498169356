import { Component, OnInit, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import {
  BizSamedaySlot,
  DateUtil,
  ToglInfo,
  ToglState,
} from '@mojoapps1/mojoapps1common';
import { format } from 'date-fns';
import { BackendProService } from '../../angular-services/backend.pro.service';
import { UIStringPro } from 'src/app/lang/UIStringPro';
import {
  triggerFadeInOut,
  triggerScaleInOutFromTop,
} from 'src/app/animations/animations';
import { DateUtil2 } from 'src/app/util/DateUtil2';

@Component({
  selector: 'app-togl-listitem-pro',
  templateUrl: './togl-listitem-pro.component.html',
  styleUrls: ['./togl-listitem-pro.component.scss'],
})
export class ToglListitemProComponent implements OnInit {
  ratingPromptLabel: string;
  dateLabel: string;
  distanceLabel: string;

  header: string;
  slotTime: string;
  slotDate: string;

  stateLabel: string;
  isActive: boolean;

  constructor(private backendService: BackendProService) {}

  /**
   * the togl item we're rendering
   */
  @Input() set toglItem(val: ToglInfo) {
    this._toglItem = val;
    this.dateLabel = this.backendService.getToglinfoDateLabel(
      this.toglItem,
      true
    );

    if (
      (this._toglItem.state === ToglState.COMPLETED ||
        this._toglItem.state === ToglState.NOSHOW_ATFAULT ||
        this._toglItem.state === ToglState.USER_CANCELED_ATFAULT) &&
      this._toglItem.userReceivedRating == null
    ) {
      this.ratingPromptLabel = UIStringPro.format('LABEL_NEEDS_RATING');
    } else {
      this.ratingPromptLabel = null;
    }
  }
  get toglItem(): ToglInfo {
    return this._toglItem;
  }
  private _toglItem: ToglInfo;

  /**
   * position of the user who sent this togl, so the containing page can update it as needed. is only shown for active TOGLs.
   */
  @Input() set userPosition(val: google.maps.LatLngLiteral) {
    this._userPosition = val;
    this.updateDistance();
  }
  get userPosition(): google.maps.LatLngLiteral {
    return this._userPosition;
  }
  private _userPosition: google.maps.LatLngLiteral;

  /**
   * avatar url of user that sent togl
   */
  @Input() avatarUrl: string;

  /**
   * component init
   */
  ngOnInit() {
    // console.log('listitem onInit')
    // console.dir(this.toglItem);

    this.isActive = this.backendService.isActiveToglState(this._toglItem.state);

    const stateMap = {
      [ToglState.USER_PENDING]: 'TSTATE_USER_PENDING',
      [ToglState.CONFIRMED]: 'TSTATE_CONFIRMED',
      [ToglState.COMPLETED]: 'TSTATE_COMPLETED',
      [ToglState.TAKEN]: 'TSTATE_TAKEN',
      [ToglState.DENIED]: 'TSTATE_DENIED',
      [ToglState.EXPIRED]: 'TSTATE_EXPIRED',
      [ToglState.CANCELED_ATFAULT]: 'TSTATE_CANCELED_ATFAULT',
      [ToglState.USER_CANCELED]: 'TSTATE_USER_CANCELED',
      [ToglState.USER_CANCELED_ATFAULT]: 'TSTATE_USER_CANCELED_ATFAULT',
      [ToglState.NOSHOW_ATFAULT]: 'TSTATE_NOSHOW_ATFAULT',
    };
    const stateStringKey = stateMap[this.toglItem.state];
    if (stateStringKey) {
      this.stateLabel = UIStringPro.format(stateStringKey);
    }

    this.updateDistance();

    this.dateLabel = this.backendService.getToglinfoDateLabel(
      this.toglItem,
      true
    );

    this.header = this._toglItem.service.title;
    if (this._toglItem.samedaySlot) {
      this.slotTime = this.generateTimeslotLabel(this._toglItem.samedaySlot);
      this.slotDate = DateUtil2.formatDate(
        this._toglItem.samedaySlot.start.toDate()
      );
    }
  }

  updateDistance() {
    let dist: number = -1;
    if (this._userPosition) {
      dist = this.backendService.getDistanceToPoint(this._userPosition);
    }

    this.distanceLabel =
      dist === -1
        ? 'Location not available'
        : dist.toFixed(1) + UIStringPro.format('LABEL_RANGE_MILES');
  }

  /**
   * template function to generate a timeslot label
   * @param slot
   * @returns
   */
  generateTimeslotLabel(slot: BizSamedaySlot): string {
    return (
      DateUtil2.formatTime(slot.start) + ' - ' + DateUtil2.formatTime(slot.end)
    );
  }
}
