import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

import urlRegex from 'url-regex';

const regUrl = /^(http(s?):\/\/)?(www\.)?[a-zA-Z0-9\.\-\_]+(\.[a-zA-Z]{2,3})+(\/[a-zA-Z0-9\_\-\s\.\/\?\%\#\&\=]*)?$/;

/**
 *
 * @param control
 * @returns
 */
export const toglDataRowValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const group = control as FormGroup;
  const type = group.get('type');
  const label = control.get('label');
  const value = control.get('value');

  if (!type || !label || !value) return null;

  if (!type.value || !label.value || !value.value) {
    return { required: true };
  }

  if (type.value === 'url') {
    // if (!urlRegex({ exact: true }).test(value.value)) {
    //   return { invalidUrl: true };
    // }

    const regex = new RegExp(regUrl);
    const toTest: string = value.value;

    if (!toTest.match(regex)) {
      return { invalidUrl: true };
    }
  }

  return null;
};
