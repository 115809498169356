import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
// import { Events } from '@ionic/angular'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AverageRating } from '@mojoapps1/mojoapps1common';
import { RatingProService } from 'src/app/angular-services/rating.pro.service';

@Component({
  selector: 'app-avg-user-rating',
  templateUrl: './avg-user-rating.component.html',
  styleUrls: ['./avg-user-rating.component.scss'],
})
export class AvgUserRating implements OnInit {
  avgRatingLabel: string;
  totalRatingLabel: string;

  @Input() set avgRating(val: AverageRating) {
    this._avgRating = val;

    if (val && val.count != 0) {
      this.avgRatingLabel = val ? val.avg.toFixed(1) : '0';
      const label = val.count == 1 ? 'rating' : 'ratings';
      this.totalRatingLabel = `(${val.count} ${label})`;
    } else {
      this.avgRatingLabel = '0';
      this.totalRatingLabel = `(no ratings yet!)`;
    }
  }
  get avgRating() {
    return this._avgRating;
  }
  private _avgRating: AverageRating;
  @Input() hideTotal: boolean = false;
  @Input() inline: boolean = false;

  ngOnInit(): void {}

  constructor(private ratings: RatingProService) {}
}
