import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Service } from '@mojoapps1/mojoapps1common';

export interface IconInfo {
  svgUrl?: string;
  fallback?: string;
}

/**
 * manages category and service icons
 */
@Injectable({
  providedIn: 'root',
})
export class IconsProService {
  /**
   * the path in the default bucket where our icons are stored
   */
  public static readonly BUCKET_PATH: string = 'icons';

  /**
   * whether to use firebase storage for icon files or local embedded icons
   */
  private _useStorage: boolean = true;

  private _iconUrlCache: { [key: string]: string } = {};

  constructor(private storage: AngularFireStorage) {}

  /**
   * get an icon url from firebase storage. resolves to null if it doesn't exist in the default bucket
   *
   * @param icon
   * @returns
   */
  async getIconStorageDownloadURL(icon: string) {
    // await new Promise((resolve) => setTimeout(resolve, 10000));

    if (this._iconUrlCache[icon]) {
      return this._iconUrlCache[icon];
    }

    try {
      const url: string = await this.storage
        .ref(`${IconsProService.BUCKET_PATH}/${icon}`)
        .getDownloadURL()
        .toPromise();
      this._iconUrlCache[icon] = url;
      return url;
    } catch (e) {
      // doesn't exist in firebase storage, return null
      return null;
    }
  }

  /**
   * use SVG if available, fallback to ionic icon name if not
   * @param s
   */
  async getIconInfo(icon: string) {
    let ret: IconInfo = {};

    // question mark icon
    let failRet: IconInfo = {
      fallback: 'help-outline',
    };

    // no icon at all? fallback to questionmark
    if (!icon) {
      return failRet;
    }

    // try to generate an svg url if it's an svg icon
    if (icon.toLowerCase().endsWith('.svg')) {
      if (this._useStorage) {
        // use firebase storage
        const url = await this.getIconStorageDownloadURL(icon);
        if (url) {
          ret.svgUrl = url;
          return ret;
        }
        return failRet;
      } else {
        // get the icon locally
        ret.svgUrl = 'assets/icons/' + icon;
        return ret;
      }
    }

    // treat an icon not ending with .svg as an ionic icon name
    ret.fallback = icon;
    return ret;
  }
}
