import { concat, defer, Observable, of } from 'rxjs';

/**
 * returns an obserable that inserts an optional initial value before a stream of updates.
 * @param initialValue
 * @param observable
 * @returns
 */
export const currentAndFutureValuesObservable = function <T>(
  initialValue: T | null | undefined,
  observable: Observable<T>
) {
  // has initial value?
  if (initialValue) {
    // yes, add the current value in before the normal stream of changes
    return concat(
      defer(() => of(initialValue)),
      observable
    );
  } else {
    // no, just return the normal stream of changes
    return observable;
  }
};
