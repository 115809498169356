import { Injectable } from '@angular/core';
import { ToastController, AlertController, Platform } from '@ionic/angular';

import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentSnapshot,
  QueryFn,
} from '@angular/fire/firestore';
import {
  concat,
  defer,
  EMPTY,
  empty,
  forkJoin,
  from,
  iif,
  interval,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  BackendServiceBase,
  ToglState,
  Business,
  ToglInfo,
  Togl,
  Service,
  UserData,
  BizServices,
  BizService,
  BizSamedaySlot,
  DateUtil,
  CollectionNames,
  UserRole,
  UserPushToken,
  PushTypes,
  ToglStateListTypes,
  ToglStateList,
  UserWatchedService,
  StringSet,
  createDeletedUserDataForId,
} from '@mojoapps1/mojoapps1common';
import { environment } from '../../environments/environment';
import {
  concatMap,
  filter,
  first,
  ignoreElements,
  map,
  mergeMap,
  skip,
  tap,
} from 'rxjs/operators';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { UIStringPro } from '../lang/UIStringPro';
import { startOfToday } from 'date-fns';
import { Action, AngularFireDatabase } from '@angular/fire/database';
import { PushNotifProService } from './pushnotif.pro.service';
import { DateUtil2 } from '../util/DateUtil2';
import { SubscriptionProService } from './subscription.pro.service';
import { IAPProService } from './iap.pro.service';
import { AlertProService } from './alert.pro.service';
import { MaintenancePro } from '../util/MaintenancePro';

@Injectable({
  providedIn: 'root',
})
export class BackendProService extends BackendServiceBase {
  private business: Business;
  private businessSubject: Subject<Business>;
  private businessCompositeSubscription: Subscription;

  private userData: UserData;
  private userCompositeSubscription: Subscription;

  private toglInfoSubjects: {
    [key: string]: Subject<ToglInfo[]>;
  };

  private toglInfoLists: {
    [key: string]: ToglInfo[];
  };

  private _isUserReady: boolean;
  private _onUserReady: Subject<UserData>;
  private _userDataValueChanges: Subject<UserData>;
  private _isBizReady: boolean;
  private _onBizReady: Subject<Business>;
  private _isUserReadyWithoutBiz: boolean;
  private _onUserReadyWithoutBiz: Subject<void>;

  private _timeslots: BizSamedaySlot[];
  private _timeslotMetadata: any = {};

  private _redirectOnLogin: string;

  /**
   * internal flag set during signup process
   */
  private _signupInProgress: boolean = false;

  /**
   * current push notification token if any
   */
  private _pushToken: string;

  public static readonly DEFAULT_SERVICE_SLOTS: number = 10;

  private static readonly KEY_CURRENT_BUSINESS: string = 'currentBusiness';

  /**
   * which notifications are handled manually when in web mode
   */
  private static readonly WEB_NOTIF_INFO: {
    state: ToglState;
    onAppStartup?: boolean;
  }[] = [
    {
      state: ToglState.USER_PENDING,
      onAppStartup: true,
    },
    {
      state: ToglState.USER_CANCELED,
    },
    {
      state: ToglState.EXPIRED,
    },
    {
      state: ToglState.USER_CANCELED_ATFAULT,
    },
  ];

  constructor(
    private firestore: AngularFirestore,
    public toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private storage: Storage,
    private router: Router,
    private auth: AngularFireAuth,
    private http: HttpClient,
    private afStorage: AngularFireStorage,
    private platform: Platform,
    private rtdb: AngularFireDatabase,
    private pushNotifService: PushNotifProService,
    private alerts: AlertProService,
    private subscription: SubscriptionProService,
    private iap: IAPProService
  ) {
    // some services are included in constructor to force initialization at app startup

    super(firestore, storage, environment, true);

    this._timeslots = [];
    this.businessSubject = new Subject<Business>();
    this.toglInfoSubjects = {};
    this.toglInfoLists = {};
    this._onUserReady = new Subject<UserData>();
    this._isUserReady = false;
    this._onBizReady = new Subject<Business>();
    this._isBizReady = false;

    this._onUserReadyWithoutBiz = new Subject<void>();
    this._isUserReadyWithoutBiz = false;
    this._userDataValueChanges = new Subject<UserData>();

    this.initializeUIStrings();

    // load google maps with the right API key for this environment
    this.http
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${environment.gmapsApiKey}`,
        'callback'
      )
      .subscribe((obj) => {
        console.log('backendpro: google maps loaded');
      });

    // respond to auth events
    this.auth.authState.subscribe(async (u) => this.onAuthStateEvent(u));
  }

  /**
   * handles auth state events from firebase auth. fires once on app load and once on each user log in/out
   * @param u null if user logged out, non-null if user logged in
   */
  async onAuthStateEvent(u: firebase.User) {
    // if signup in progress, wait until signup is finished
    if (this._signupInProgress) {
      console.log('backendpro: auth event, signup in progress, waiting');
      let count = 0;
      while (this._signupInProgress) {
        console.log('backendpro: sleep ' + count);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        count++;

        if (count > 120) {
          throw new Error('timeout waiting for signup to finish');
        }
      }
    }

    console.log('backendpro: authState event: ' + JSON.stringify(u));

    if (u) {
      console.log('backendpro: user is logged in: ' + u.uid);
      try {
        // check if they're verified or not
        // is user email verified? this fires on initial user creation AND if you log in with an unverified email
        if (!u.emailVerified) {
          // send verification email, show a message, and log them out
          try {
            await u.sendEmailVerification();
            await this.alerts.alertOk(
              UIStringPro.format('NOTIF_EMAIL_UNVERIFIED', { email: u.email })
            );
          } catch (e) {
            await this.alerts.alertOk('error: ' + e.message);
            throw e;
          } finally {
            // log the user out
            return this.logout();
          }
        }

        // await this.createUserDataIfNeeded(u);

        // check the user's role - can't use app if you're not a business account
        const snap = await this.docRef<UserData>(CollectionNames.USERS, u.uid)
          .get()
          .toPromise();
        if (snap.exists) {
          const userData = snap.data() as UserData;
          if (userData.role === UserRole.CONSUMER) {
            await this.alerts.alertOk(
              UIStringPro.format('NOTIF_CONSUMER_NOT_ALLOWED')
            );
            return this.logout();
          }
        }

        // user is fine, proceed

        await this.setCurrentUser(u.uid);
        await this.registerForPushNotifications();
        await this.processUserLogin();

        console.log('backendpro: finished auth state event');
      } catch (err) {
        // error or consumer user logged in
        console.error(err);
      }
    } else {
      // not logged in, or, just logged out
      if (this.userData) {
        // logout event
        console.log('backendpro: user has logged out');

        await this.removeCurrentUserSubscriptions();
        await this.removeCurrentBusinessSubscriptions();
        await this.alerts.openAlertToast(
          UIStringPro.format('NOTIF_LOGGED_OUT')
        );
        await this.router.navigateByUrl('/login');
      } else {
        console.log('backendpro: initial load, user is logged out');
      }
    }
  }

  consumeRedirectOnLogin(): string {
    if (this._redirectOnLogin) {
      let ret = this._redirectOnLogin;
      this._redirectOnLogin = null;
      return ret;
    }
    return null;
  }

  setRedirectOnLogin(url: string) {
    this._redirectOnLogin = url;
  }

  /**
   * handle permissions, load user's business, etc
   */
  private async processUserLogin() {
    if (!this.userData) throw new Error('no userdata');

    // user is loaded
    console.log('backendpro: user is ready');
    console.log('backendpro: user business id: ' + this.userData.businessId);
    this._isUserReady = true;
    this._onUserReady.next({ ...this.userData });
    this._onUserReady.complete();

    // does the user have a business?
    if (this.userData.businessId) {
      // load their business
      await this.setCurrentBusiness(this.userData.businessId);
    } else {
      // fire the no business event
      this._onUserReadyWithoutBiz.next();
    }
  }

  /**
   * initializes the system when a user logs in
   * @param id the user's id
   */
  async setCurrentUser(id: string) {
    console.log('backendpro: setCurrentUser: ' + id);
    await this.removeCurrentUserSubscriptions();
    console.log('backendpro: setting up new user subscriptions');

    // subscribe internally to record changes associated with the user
    let userDataRef = this.docRef<UserData>(CollectionNames.USERS, id);
    this.userCompositeSubscription = new Subscription();

    return new Promise<void>((resolve) => {
      this.userCompositeSubscription.add(
        userDataRef.valueChanges().subscribe(
          async (userData: UserData) => {
            let initialLoad: boolean = this.userData == null;

            console.log(
              'backendpro: userData valueChange, initialLoad=' + initialLoad
            );
            console.log(userData);

            // save updates to user data
            this.userData = userData;

            this._userDataValueChanges.next({ ...this.userData });

            // if this is just a value-changes load, we're done
            if (!initialLoad) {
              return;
            }

            // initialize services
            this.subscription.onUserEvent({ ...this.userData });

            // iniitalize preferences - deprecated
            // await this.initializePreferences(id);

            // resolve promise
            resolve();
          },
          (err) => console.warn(err)
        )
      );
    });
  }

  /**
   * stop listening to push notifications and delete token
   *
   * call before logout
   */
  private async disablePushNotifications() {
    if (!this.pushNotifService.isSupported) {
      return;
    }

    await this.pushNotifService.onLogout();
  }

  /**
   * dispose previous subscriptions if any
   */
  private async removeCurrentUserSubscriptions() {
    if (this.userCompositeSubscription) {
      console.log('backendpro: removing current user subscriptions');

      // no longer ready
      this._isUserReady = false;
      this.userData = null;
      this._onUserReady = new Subject<UserData>();

      // all of the user's subscriptions have been added to this one, making unsubscribing easy
      this.userCompositeSubscription.unsubscribe();
      this.userCompositeSubscription = null;

      this._onUserReadyWithoutBiz.complete();
      this._onUserReadyWithoutBiz = new Subject<void>();
      this._userDataValueChanges.complete();
      this._userDataValueChanges = new Subject<UserData>();

      // disable services
      this.subscription.onUserEvent(null);
    }
  }

  /**
   * register for push notifications when user logs in to app
   * @returns
   */
  async registerForPushNotifications() {
    if (!this.userData) throw new Error('user not logged in');

    if (!this.pushNotifService.isSupported) {
      console.log('backend: push notifications not supported on this platform');
      return;
    }

    // register for push notifications
    await this.pushNotifService.onLogin(this.userData.id);

    this.pushNotifService.onPushNotif().subscribe(async (data) => {
      // show a notification
      let url: string;
      switch (data.type) {
        case PushTypes.BIZ_TOGL:
          if (data.toglId) {
            let info: ToglInfo = await this.getToglInfoOnce(data.toglId);
            await this.showProToglNotification(info);
          }
          break;
        case PushTypes.BIZ_TOGL_EXPIRING:
          if (data.toglId) {
            let info: ToglInfo = await this.getToglInfoOnce(data.toglId);

            let duration = this.getToglExpirationString(info);
            url = `/app/togls`;
            await this.openNotificationAlert(
              UIStringPro.format('HEADER_TOGL_EXPIRING'),
              UIStringPro.format('NOTIF_TOGL_EXPIRING', {
                name: data.userDisplayName,
                service: data.serviceTitle,
                duration,
              }),
              url
            );
          }
          break;
        case PushTypes.BIZ_TOGL_EXPIRED:
          url = `/app/togls`;
          await this.openNotificationAlert(
            UIStringPro.format('HEADER_TOGL_EXPIRED'),
            UIStringPro.format('NOTIF_TOGL_EXPIRED', {
              name: data.userDisplayName,
              service: data.serviceTitle,
            }),
            url
          );
          break;

        //   msg = !toglInfo.samedaySlot
        //   ? UIStringPro.format('NOTIF_TOGL_PENDING', {
        //       name: toglInfo.user.displayName,
        //       service: toglInfo.service.title,
        //     })
        //   : UIStringPro.format('NOTIF_TOGL_SAMEDAY_PENDING', {
        //       name: toglInfo.user.displayName,
        //       service: toglInfo.service.title,
        //       time: DateUtil2.formatTime(toglInfo.samedaySlot.start),
        //     });
        // url = '/tabs/pending';
        // return this.openNotificationAlert(
        //   UIStringPro.format('HEADER_TOGL_PENDING'),
        //   msg,
        //   url
        // );
      }
    });
  }

  /**
   * save an FCM push token to the user's record
   * @param token
   * @returns
   */
  // async savePushToken(token: string) {
  //   if (!this.userData) throw new Error('user not logged in');

  //   // save token to database
  //   let doc = this.docRef<UserPushToken>(
  //     CollectionNames.USERS_PUSHTOKENS_PRO,
  //     this.userData.id
  //   );
  //   const snap = await doc.get().toPromise();

  //   if (!snap.exists) {
  //     // create document
  //     await doc.ref.set({ tokens: [token] });
  //   } else {
  //     let userToken: UserPushToken = snap.data() as UserPushToken;
  //     if (!userToken.tokens.includes(token)) {
  //       // add token to list if not already there
  //       userToken.tokens.push(token);
  //       await doc.ref.update({ tokens: userToken.tokens });
  //     }
  //   }
  // }

  /**
   * registers a new user with firebase and logs them in. used in dev and sales environments. throws on error
   * @param email
   * @param displayName
   * @param password
   */
  async createUser(email: string, displayName: string, password: string) {
    if (!displayName) throw new Error('name is required');

    // this will fire an auth event prematurely, need to set a flag to prevent race conditions
    this._signupInProgress = true;
    try {
      const userCredential = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      );
      console.log(`backendpro: signed up ${displayName} / ${email}`);
      console.log('backendpro: updating display name to ' + displayName);
      await userCredential.user.updateProfile({
        displayName,
      });
      console.log('backendpro: updated display name');

      // create user data for user
      await this.createUserDataIfNeeded(userCredential.user);
    } catch (e) {
      console.log('backendpro: error creating user: ' + JSON.stringify(e));
      throw e;
    } finally {
      // done!
      this._signupInProgress = false;
    }
  }

  /**
   * create default user data if user has none
   * @param u the user
   */
  async createUserDataIfNeeded(u: firebase.User): Promise<void> {
    const doc = await this.docRef<UserData>(CollectionNames.USERS, u.uid)
      .get()
      .toPromise();

    if (doc.exists) {
      // new record matching uid, all good
      console.log('backendpro: user data loaded');
    } else {
      const role =
        environment.database == 'sales' ? UserRole.SALES : UserRole.BUSINESS;

      // no user data exists, create some defaults
      let defaultData: UserData = {
        email: u.email,
        id: u.uid,
        position: {
          lat: 43.700245,
          long: -116.5064,
        },
        displayName: u.displayName,
        role,
        businessId: null,
      };
      await this.docRef<UserData>(CollectionNames.USERS, u.uid).set(
        defaultData
      );
      console.log(
        'backendpro: created new default user record successfully: ' +
          JSON.stringify(defaultData)
      );
    }
  }

  async claimBusinessForCurrentUser(businessId: string) {
    if (!this.userData) throw new Error('user not logged in');

    // claim the business
    let bizRef = this.docRef<Business>(CollectionNames.BUSINESSES, businessId);
    const snap = await bizRef.get().toPromise();

    if (snap.exists) {
      let biz: Business = snap.data() as Business;
      if (biz.ownerId) {
        await this.alerts.alertOk(UIStringPro.format('NOTIF_BIZ_CLAIMED'));
        throw new Error('business already claimed');
      } else {
        // claim the business!
        await this.docRef<UserData>(
          CollectionNames.USERS,
          this.userData.id
        ).update({ businessId: biz.businessID });

        return bizRef.update({
          ownerId: this.userData.id,
        });
      }
    } else {
      throw new Error('business does not exist');
    }
  }

  async unclaimBusinessForCurrentUser(): Promise<void> {
    if (!this.userData) return Promise.reject();

    let businessId = this.userData.businessId;
    if (!businessId) {
      return Promise.reject();
    }

    // un-claim the business
    let bizRef = this.docRef<Business>(CollectionNames.BUSINESSES, businessId);
    const snap = await bizRef.get().toPromise();

    if (snap.exists) {
      let biz: Business = snap.data() as Business;
      if (biz.ownerId !== this.userData.id) {
        await this.alerts.alertOk(
          UIStringPro.format('NOTIF_BIZ_CLAIMED_WRONG_USER')
        );
        throw new Error('business belongs to other user');
      } else {
        // unclaim the business!
        await this.docRef<UserData>(
          CollectionNames.USERS,
          this.userData.id
        ).update({ businessId: null });

        return bizRef.update({
          ownerId: null,
        });
      }
    } else {
      throw new Error('business does not exist');
    }
  }

  initializeUIStrings() {
    this.setDateLabel(ToglState.USER_PENDING, '');
    this.setDateLabel(ToglState.CONFIRMED, '');
    this.setDateLabel(ToglState.COMPLETED, '');
    this.setDateLabel(ToglState.TAKEN, '');
    this.setDateLabel(ToglState.DENIED, '');
    this.setDateLabel(ToglState.EXPIRED, '');
    this.setDateLabel(ToglState.CANCELED_ATFAULT, '');
    this.setDateLabel(ToglState.USER_CANCELED, '');
    this.setDateLabel(ToglState.USER_CANCELED_ATFAULT, '');
    this.setDateLabel(ToglState.NOSHOW_ATFAULT, '');
  }

  public get isUserReady(): boolean {
    return this._isUserReady;
  }

  public get isBizReady(): boolean {
    return this._isBizReady;
  }

  /**
   * when the onBizReady event fires, the current business has been loaded,
   * and getToglsForCurrentBusiness can be called
   * @param f
   */
  public onBizReady(): Observable<Business> {
    // has biz already been loaded once??
    if (this.business) {
      // yes, add the current value in before the normal stream of changes
      return concat(
        defer(() => of(this.business)),
        this._onBizReady.asObservable()
      );
    } else {
      // no, just return the normal stream of changes
      return this._onBizReady.asObservable();
    }
  }

  /**
   * when the onUserReadyWithoutBiz event fires, the system has determined that
   * the current user does not have a business
   * @param f
   */
  public onUserReadyWithoutBiz(f: Function): Subscription {
    if (this._isUserReadyWithoutBiz) {
      console.log(
        'backendpro: onUserReadyWithoutBiz, calling listener immediately'
      );
      f();
    }

    return this._onUserReadyWithoutBiz.subscribe(() => {
      console.log(
        'backendpro: onUserReadyWithoutBiz, calling listener via subscription'
      );
      f();
    });
  }

  /**
   * when the onUserReady event fires, a user is logged in, but we haven't loaded their business yet
   *
   * fires once and then completes
   */
  public onUserReady() {
    // has user already been loaded once??
    if (this.userData) {
      // yes, add the current value in before the normal stream of changes
      return concat(
        defer(() => of(this.userData)),
        this._onUserReady.asObservable()
      );
    } else {
      // no, just return the normal stream of changes
      return this._onUserReady.asObservable();
    }
  }

  public userDataValueChanges(): Observable<UserData> {
    return this._userDataValueChanges.asObservable();
  }

  async openNotificationAlert(
    header: string,
    message: string,
    url: string,
    viewText?: string,
    cancelText?: string
  ) {
    const alert = await this.alertCtrl.create({
      cssClass: 'noneactive-alert-style',
      mode: 'md',
      header: header,
      message: message,

      buttons: [
        {
          text: viewText || UIStringPro.format('BTN_VIEW'),
          role: 'view',
          cssClass: 'secondary',
          handler: () => {
            this.router.navigateByUrl(url);
          },
        },
        {
          text: cancelText || UIStringPro.format('BTN_CLOSE'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  /**
   * is this togl state one we care about notifications for?
   * @param state
   * @returns
   */
  private shouldNotifyForToglState(state: string) {
    let found =
      BackendProService.WEB_NOTIF_INFO.find((v) => v.state === state) != null;
    return found;
  }

  /**
   * only cares about the following togl states: `USER_PENDING`, `USER_CANCELED`, `USER_CANCELED_ATFAULT`
   * @param state
   * @param list
   * @returns
   */
  private processToglWebNotifications(
    stateList: ToglStateList,
    list: ToglInfo[]
  ) {
    const isWeb = !this.pushNotifService.isSupported;
    const isSingle = stateList.states.length === 1;
    if (!isWeb || !isSingle) {
      return;
    }

    const state = stateList.states[0];
    const notifInfo = BackendProService.WEB_NOTIF_INFO.find(
      (v) => v.state === state
    );
    const prevList = this.toglInfoLists[state] || [];

    // things that only fire during app lifetime have
    // to have a previously-loaded list
    if (!notifInfo.onAppStartup && prevList.length == 0) {
      return;
    }

    // figure out how many new togls there are
    let numNewTogls = 0;
    for (const info of list) {
      if (prevList.find((i) => i.id === info.id) == null) {
        numNewTogls++;
      }
    }

    console.log(
      `backendpro: process togl notifications for ${state}, new togls: ${numNewTogls}`
    );

    if (numNewTogls > 0) {
      // there's one or more new togls that just got loaded
      let first: ToglInfo = list[0];
      return this.showProToglNotification(first);
    }
  }

  /**
   * only responds to the following states: `USER_PENDING`, `USER_CANCELED`, `USER_CANCELED_ATFAULT`
   * @param toglInfo
   * @returns
   */
  async showProToglNotification(toglInfo: ToglInfo) {
    let url: string, msg: string;

    switch (toglInfo.state) {
      case ToglState.USER_PENDING:
        let duration = this.getToglExpirationString(toglInfo);
        msg = !toglInfo.samedaySlot
          ? UIStringPro.format('NOTIF_TOGL_PENDING', {
              name: toglInfo.user.displayName,
              service: toglInfo.service.title,
              duration,
            })
          : UIStringPro.format('NOTIF_TOGL_SAMEDAY_PENDING', {
              name: toglInfo.user.displayName,
              service: toglInfo.service.title,
              time: DateUtil2.formatTime(toglInfo.samedaySlot.start),
              duration,
            });
        url = '/tabs/pending';
        return this.openNotificationAlert(
          UIStringPro.format('HEADER_TOGL_PENDING'),
          msg,
          url
        );
      case ToglState.USER_CANCELED:
      case ToglState.USER_CANCELED_ATFAULT:
        msg = UIStringPro.format('NOTIF_TOGL_USER_CANCELED', {
          user: toglInfo.user.displayName,
          email: toglInfo.user.email,
          service: toglInfo.service.title,
        });
        url = `/tabs/togls/${toglInfo.id}`; // `/tabs/pending`;
        return this.openNotificationAlert(
          UIStringPro.format('HEADER_TOGL_CANCELED'),
          msg,
          url
        );
      case ToglState.EXPIRED:
        msg = UIStringPro.format('NOTIF_TOGL_EXPIRED', {
          name: toglInfo.user.displayName,
          service: toglInfo.service.title,
        });
        url = `/tabs/togls/${toglInfo.id}`; // `/tabs/pending`;
        return this.openNotificationAlert(
          UIStringPro.format('HEADER_TOGL_EXPIRED'),
          msg,
          url
        );
    }
  }

  /**
   * translate a togl pending expiration into a human readable string
   * @param togl
   * @returns
   */
  getToglExpirationString(togl: Togl) {
    const errorString = 'unknown time';
    if (!togl._pendingExpirationSeconds) return errorString;

    const createdMillis = togl.createdTimestamp!.toMillis();
    const pendingExpirationMillis =
      createdMillis + (togl._pendingExpirationSeconds || 0) * 1000;
    let diff = pendingExpirationMillis - Date.now();
    let duration = errorString;
    if (diff > 0) {
      duration = this.millisecondsToHumanReadable(diff);
    }
    return duration;
  }

  /**
   * subscribe to togl object updates
   * @param stateList
   * @returns
   */
  private createToglSubscription(stateList: ToglStateList): Subscription {
    if (!this.business)
      throw new Error('createToglSubscription: business not loaded yet');

    console.log('createToglSubscription:', stateList.name);
    if (this.toglInfoSubjects[stateList.name])
      this.toglInfoSubjects[stateList.name].complete();
    this.toglInfoSubjects[stateList.name] = new Subject<ToglInfo[]>();

    let qFn = this.createToglQueryFunction(
      'businessId',
      this.business.businessID,
      stateList,
      stateList.name === ToglStateListTypes.ACTIVE ? 50 : 5
    );

    return this.collectionRef<Togl>(CollectionNames.TOGLS, qFn)
      .valueChanges()
      .subscribe((togls) => {
        this.hydrateToglInfoList(togls, async (toglList) => {
          // check for notifications (web, single states only)
          await this.processToglWebNotifications(stateList, toglList);

          let subject = this.toglInfoSubjects[stateList.name];
          this.toglInfoLists[stateList.name] = toglList;

          console.log(
            `backendpro: ${toglList.length} togls for state list type ${stateList.name}`
          );

          // broadcast
          subject.next(toglList);
        });
      });
  }

  /**
   * confirm and then log out
   *
   * @returns a promise that resolves to true if the user confirmed logging out
   */
  async logoutWithConfirmation() {
    const confirmation = await this.alerts.genericConfirm2(
      UIStringPro.format('NOTIF_CONFIRM_LOGOUT')
    );
    if (confirmation) {
      await this.logout();
    }
    return confirmation;
  }

  /**
   * log out of the app immediately
   * @returns
   */
  async logout() {
    console.log('backendpro: logging out');

    await this.disablePushNotifications();
    await this.auth.signOut();
  }

  /**
   * clean up biz state.
   */
  private removeCurrentBusinessSubscriptions(): Promise<any> {
    // dispose previous subscriptions if any
    if (this.businessCompositeSubscription) {
      // no longer ready
      this._isBizReady = false;
      this.business = null;
      this._onBizReady = new Subject<Business>();

      this.businessCompositeSubscription.unsubscribe();
      this.businessCompositeSubscription = null;

      this.toglInfoLists = {};
      this._timeslots = [];
      this._timeslotMetadata = {};

      for (const state of Object.keys(this.toglInfoSubjects)) {
        if (this.toglInfoSubjects[state]) {
          this.toglInfoSubjects[state].complete();
          this.toglInfoSubjects[state] = null;
        }
        this.toglInfoSubjects = {};
      }
    }

    return Promise.all([
      this.storage.remove(BackendProService.KEY_CURRENT_BUSINESS),
    ]);
  }

  async setCurrentBusiness(id: string): Promise<void> {
    console.log('backendpro: setting current business: ' + id);
    await this.removeCurrentBusinessSubscriptions();

    let bizref = this.docRef<Business>(CollectionNames.BUSINESSES, id);

    // subscribe internally to record changes associated with the new business
    this.businessCompositeSubscription = new Subscription();

    return new Promise((resolve) => {
      this.businessCompositeSubscription.add(
        bizref.valueChanges().subscribe(async (biz) => {
          let initialLoad = this.business == null;

          // save updates to biz data (also broadcast them?)
          this.business = biz;

          // if this is just a value-changes load, we're done!
          if (!initialLoad) {
            return;
          }

          // do initial-login stuff
          // subscribe internally to togl collections
          this.createToglSubscriptions();

          // subscribe internally to valuechanges from the business's sameday timeslots
          this.subscribeToBizTimeslots();

          // set up timed events
          this.setupTimedEvents();

          // artificial delay
          // console.log('backendpro: artificial delay');
          // await new Promise((resolve) => setTimeout(resolve, 10000));
          // console.log('backendpro: end artificial delay');

          // business is ready
          console.log('backendpro: business is ready');
          this._isBizReady = true;
          this._onBizReady.next(this.business);
          this._onBizReady.complete();

          // run maintenance?
          // const m = new MaintenancePro();
          // await m.maintenance_20221003_initToglMetadataRTDB(this.rtdb);

          // resolve promise
          resolve();
        })
      );
    });
  }

  /**
   * subscribe internally to certain togl collections on first load.
   *
   * subscribes to `ACTIVE` for the pending togls page.
   *
   * subscribes to a few more on the web according to `WEB_NOTIF_INFO` for the processing of notifications
   */
  private createToglSubscriptions() {
    let list: ToglStateList[] = [];
    list.push(ToglStateListTypes.active());

    // subscribe to more states on the web
    if (!this.pushNotifService.isSupported) {
      for (const info of BackendProService.WEB_NOTIF_INFO) {
        list.push(ToglStateListTypes.single(info.state));
      }
    }

    list.forEach((stateList) => {
      this.businessCompositeSubscription.add(
        this.createToglSubscription(stateList)
      );
    });
  }

  private setupTimedEvents() {
    this.businessCompositeSubscription.add(
      interval(1000 * 60).subscribe(async (n) =>
        this.processTimeslotExpirationNotifications()
      )
    );
  }

  private subscribeToBizTimeslots() {
    if (!this.business) throw new Error('no business loaded');

    // subscribe internally to valuechanges from the business's sameday timeslots
    // but only from the start of today
    this._timeslotMetadata = {};
    this.businessCompositeSubscription.add(
      this.collectionRef<BizSamedaySlot>(
        CollectionNames.BIZ_SAMEDAYSLOTS,
        (ref) =>
          ref
            .where('businessId', '==', this.business.businessID)
            .where('start', '>', startOfToday())
            .orderBy('start')
      )
        .valueChanges()
        .subscribe((slots) => {
          this._timeslots = slots ? [...slots] : [];
          // console.log('backendbase: slots valuechanges');
          // console.log(this._timeslots);
        })
    );
  }

  /**
   * determine if any timeslots are expiring and notify user
   */
  async processTimeslotExpirationNotifications() {
    if (this._timeslots && this._timeslots.length > 0) {
      let now = new Date();
      for (const slot of this._timeslots) {
        const id = slot.id;
        const start: Date = slot.start.toDate();
        let isValid = DateUtil2.isValidStarttime(start, now);
        let startInFuture: boolean = now.valueOf() < start.valueOf();

        // if this is the first time we've processed this slot, set notified flag to false
        if (!this._timeslotMetadata[id]) {
          this._timeslotMetadata[id] = {
            notified: false,
          };
        }
        // is this slot expiring soon? and have we notified them already for this slot?
        if (!isValid && startInFuture && !this._timeslotMetadata[id].notified) {
          // load the service to get the name and then show a box. include disabled
          const service = await this.getServiceFromCache(slot.serviceId, true);
          await this.openNotificationAlert(
            UIStringPro.format('HEADER_SAMEDAY_SLOT_EXPIRING'),
            UIStringPro.format('NOTIF_SAMEDAY_SLOT_EXPIRING', {
              service: service.title,
              time: DateUtil2.formatTime(slot.start),
            }),
            '/tabs/active-togls'
          );
          // save the fact that we've notified the user so it doesn't happen next time
          this._timeslotMetadata[id].notified = true;
        }
      }
    }
  }

  get currentBusiness(): Business {
    return this.business;
  }

  get currentUser(): UserData {
    return this.userData;
  }

  getUserDisplayname(u: UserData, useNameAndEmail: boolean = false): string {
    if (u.displayName) {
      return u.displayName + (useNameAndEmail ? ` (${u.email})` : '');
    }
    return u.email;
  }

  // updateBusiness(b: Business): Promise<void> {
  //   return this.docRef<Business>(`businesses`, b.businessID).set(b);
  // }

  /**
   * search a business by name
   * @param query
   * @param startAfterName
   * @param max
   * @returns
   */
  async searchBusinessName(
    query: string,
    startAfterName?: string,
    max: number = 15
  ): Promise<Business[]> {
    // break up into tokens on spaces
    let tokens = query.toLowerCase().trim().split(' ', 10);

    let qFn: QueryFn = startAfterName
      ? (ref) =>
          ref
            .where('_keywords', 'array-contains-any', tokens)
            .orderBy('name')
            .startAfter(startAfterName)
            .limit(max)
      : (ref) =>
          ref
            .where('_keywords', 'array-contains-any', tokens)
            .orderBy('name')
            .limit(max);

    const snap = await this.collectionRef<Business>(
      CollectionNames.BUSINESSES,
      qFn
    )
      .get()
      .toPromise();

    return snap.docs.map((value) => value.data() as Business);
  }

  /**
   * returns a list of services whose titles match the search query
   * or whose categories' titles match the search query
   * @param searchValue the value to search for
   */
  async searchServices(searchValue: string) {
    // ignore disabled
    const categories = await this.getAllCategoriesCached();
    const services = await this.getAllServicesCached();
    console.log('backend: searching:', services);
    searchValue = searchValue.toLowerCase().trim();

    // find categories that match the search string
    let matchingCatIds: string[] = categories
      .filter((c) => {
        return c.title.toLowerCase().trim().indexOf(searchValue) > -1;
      })
      .map((c) => c.id);

    // find services that match the search string
    let matchingServiceIds: string[] = services
      .filter((s) => {
        if (!s.title) {
          console.log(s);
        }
        return s.title.toLowerCase().trim().indexOf(searchValue) > -1;
      })
      .map((s) => s.id);

    // we want to return all matching services and all services belonging to matching categories
    let results = services.filter((service) => {
      // is this service in the matched service list?
      if (matchingServiceIds.includes(service.id)) {
        return true;
      }

      // does this service belong to a matching category?
      let serviceCatIds: string[] =
        typeof service.catId === 'string' ? [service.catId] : service.catId;
      let match: boolean = false;
      serviceCatIds.forEach((catId) => {
        if (matchingCatIds.includes(catId)) {
          match = true;
        }
      });
      return match;
    });
    return [...results];
  }

  /**
   * throws an error with a message on failure
   * @param toglId
   * @param rating
   */
  async setToglUserRating(toglId: string, rating: number) {
    const ref = this.docRef<Togl>(CollectionNames.TOGLS, toglId);
    const snap = await ref.get().toPromise();
    if (!snap.exists) {
      throw new Error("Togl doesn't exist.");
    }
    let data: Togl = snap.data() as Togl;
    if (data.userReceivedRating != null) {
      throw new Error('User has already been rated.');
    }
    if (rating < 1 || rating > 5) {
      throw new Error(`Invalid number of stars ${rating}.`);
    }

    const updateData: any = {
      userReceivedRating: rating,
    };
    await ref.update(updateData);
  }

  /**
   * create a new business
   *
   * @param biz
   *
   * @throws errors if it didn't work
   */
  async createBusiness(biz: Business) {
    // TODO: make this a transaction!
    let id = this.firestore.createId();
    biz.businessID = id;
    biz.id = id;

    await this.docRef<Business>(CollectionNames.BUSINESSES, id).set(biz);
    console.log('backend: business created: ' + id);

    // claim biz for user
    await this.claimBusinessForCurrentUser(id);

    // create blank biz services
    await this.docRef<BizServices>(CollectionNames.BIZ_SERVICES, id).set({});

    // set current business
    await this.setCurrentBusiness(id);
  }

  getNumToglsForCurrentBusiness(stateListType: string) {
    if (this.toglInfoLists[stateListType])
      return this.toglInfoLists[stateListType].length;
    return 0;
  }

  /**
   * this only really works for state list types we're actively subscribed for
   * @param stateListType
   * @returns
   */
  getToglsForCurrentBusiness(stateListType: string) {
    if (!this._isBizReady) throw new Error('backend not ready!');
    if (!this.toglInfoSubjects[stateListType]) {
      throw new Error('backendpro: unknown state ' + stateListType);
    }

    // have the togls already been loaded once?
    if (this.toglInfoLists[stateListType]) {
      console.log(
        'backendpro: get togls for state: ' +
          stateListType +
          ', togls already loaded once'
      );
      // yes, add the current value in before the normal stream of changes
      return concat(
        defer(() => of(this.toglInfoLists[stateListType])),
        this.toglInfoSubjects[stateListType].asObservable()
      );
    } else {
      console.log(
        'backendpro: get togls for state: ' +
          stateListType +
          ', initial load still in progress'
      );
      // no, just return the normal stream of changes for subscription
      return this.toglInfoSubjects[stateListType].asObservable();
    }
  }

  /**
   * create a document with default data only if it doesn't exist
   * @param docRef reference to document to check
   * @param defaultData default data to set if it doesn't exist
   */
  async createDocumentIfNeeded<T>(
    docRef: AngularFirestoreDocument<T>,
    defaultData: T
  ) {
    const doc = await docRef.get().toPromise();

    if (doc.exists) {
      // exists
      console.log('backendpro: document exists');
    } else {
      // no biz services, create some defaults
      await docRef.set(defaultData);
      console.log('backendpro: created new document');
    }
  }
  /**
   * create a document with default data only if it doesn't exist
   * @param docRef reference to document to check
   * @param defaultData default data to set if it doesn't exist
   */
  createDocumentIfNeededObservable<T>(
    docRef: AngularFirestoreDocument<T>,
    defaultData: T
  ) {
    return docRef.get().pipe(
      mergeMap((v) =>
        iif(
          () => !v.exists,
          defer(() => docRef.set(defaultData)),
          of<void>(undefined)
        )
      )
    );
  }

  /**
   * create default bizservices data if needed
   */
  async createBizServicesIfNeeded() {
    const id: string = this.business.businessID;
    const docRef = this.docRef<BizServices>(CollectionNames.BIZ_SERVICES, id);
    return this.createDocumentIfNeeded<BizServices>(docRef, {});
  }

  /**
   * load the business services once from the db
   * @returns
   */
  async getBizServicesOnce() {
    if (!this._isBizReady) throw new Error('backend not ready!');

    const id: string = this.business.businessID;
    const bsDocref = this.docRef<BizServices>(CollectionNames.BIZ_SERVICES, id);
    const snap = await bsDocref.get().toPromise();
    if (snap.exists) {
      return snap.data() as BizServices;
    }
    return null;
  }

  /**
   * get snapshot changes of business services, creating them first if they don't exist
   * filters out metadata/cached changes
   */
  getBizServicesSnapshotChanges() {
    if (!this._isBizReady) throw new Error('backend not ready!');

    const id: string = this.business.businessID;
    const bsDocref = this.docRef<BizServices>(CollectionNames.BIZ_SERVICES, id);

    // create if needed, ignoring any emitted values, and then begin streaming value changes
    const createIfEmpty = this.createDocumentIfNeededObservable(
      bsDocref,
      {}
    ).pipe(ignoreElements());
    return concat(createIfEmpty, this.getFilteredSnapshotChanges(bsDocref));
  }

  /**
   * get snapshot changes from a firestore document but filter cache/metadata updates
   * @param ref
   */
  getFilteredSnapshotChanges<T>(ref: AngularFirestoreDocument<T>) {
    // filter out cached snapshot changes
    return ref
      .snapshotChanges()
      .pipe(
        filter(
          (snap) =>
            !snap.payload.metadata.hasPendingWrites &&
            !snap.payload.metadata.fromCache
        )
      );
  }

  /**
   * get a firestore db reference for timeslots fitting certain criteria
   * @param serviceId service to get timeslots for
   * @param startAfter get timeslots after this time (exclusive), defaults to now
   * @returns database ref
   */
  getTimeslotsRef(serviceId: string, startAfter?: Date) {
    if (!this._isBizReady) throw new Error('backend not ready!');

    if (!startAfter) startAfter = new Date();

    return this.collectionRef<BizSamedaySlot>(
      CollectionNames.BIZ_SAMEDAYSLOTS,
      (ref) =>
        ref
          .where('businessId', '==', this.business.businessID)
          .where('serviceId', '==', serviceId)
          .where('start', '>', startAfter)
          .orderBy('start')
    );
  }

  /**
   * get value changes for timeslots fitting certain criteria
   * @param serviceId
   * @param startAfter
   * @returns
   */
  getTimeslotsValueChanges(serviceId: string, startAfter?: Date) {
    return this.getTimeslotsRef(serviceId, startAfter).valueChanges();
  }

  /**
   * get timeslots once fitting certain criteria
   * @param serviceId
   * @param startAfter
   * @returns
   */
  getTimeslotsOnce(serviceId: string, startAfter?: Date) {
    return this.getTimeslotsRef(serviceId, startAfter).get().toPromise();
  }

  /**
   * get active togls for current business for a particular service, once
   * @param serviceId
   * @returns
   */
  async getActiveToglsForService(serviceId: string) {
    return this.collectionRef<Togl>(CollectionNames.TOGLS, (ref) =>
      ref
        .where('businessId', '==', this.business.businessID)
        .where('serviceId', '==', serviceId)
        .where('state', 'in', ToglStateListTypes.active().states)
    )
      .get()
      .toPromise();
  }

  /**
   * load and hydrate a togl. throws an error if togl doesn't exist!
   * @param id
   * @returns
   */
  async getToglInfoOnce(id: string) {
    const snap = await this.docRef<Togl>(CollectionNames.TOGLS, id)
      .get()
      .toPromise();

    if (!snap.exists) {
      throw new Error(`Togl ${id} doesn't exist`);
    } else {
      const info = await this.hydrateToglInfo(snap.data() as Togl).toPromise();
      return info;
    }
  }

  /**
   * subscribe to valuechanges for a togl, including hydrated data
   * @param id
   * @returns
   */
  getToglInfoValueChanges(id: string): Observable<ToglInfo> {
    return this.docRef<Togl>(CollectionNames.TOGLS, id)
      .valueChanges()
      .pipe(mergeMap((togl) => this.hydrateToglInfo(togl as Togl)));
  }

  /**
   * load the extra things for one togl
   * @param togl
   * @returns
   */
  hydrateToglInfo(togl: Togl) {
    // start with a framework, fill in the async stuff when it loads
    let toglInfo: ToglInfo = {
      ...togl,
      service: null,
      user: null,
      business: null,
      samedaySlot: null,
      businessTogledOnDate: this.timestampToDate(
        togl.businessTogledOnTimestamp
      ),
      createdDate: this.timestampToDate(togl.createdTimestamp),
      confirmedDate: this.timestampToDate(togl.confirmedTimestamp),
      completedDate: this.timestampToDate(togl.completedTimestamp),
      stateMessage: togl.stateMessage,
    };

    let hasSlot: boolean = togl.samedaySlotId != null;

    // include disabled services to not break past events
    return forkJoin({
      service: defer(() => this.getServiceFromCache(togl.serviceId, true)),
      business: this.docRef<Business>(
        CollectionNames.BUSINESSES,
        togl.businessId
      )
        .get()
        .pipe(map((snap) => snap.data() as Business)),
      user: this.docRef<UserData>(CollectionNames.USERS, togl.userId)
        .get()
        .pipe(map((snap) => snap.data() as UserData)),
      slot: hasSlot
        ? this.docRef<BizSamedaySlot>(
            CollectionNames.BIZ_SAMEDAYSLOTS,
            togl.samedaySlotId
          )
            .get()
            .pipe(map((snap) => snap.data() as BizSamedaySlot))
        : of(null),
    }).pipe(
      map((res) => {
        toglInfo.service = { ...res.service };
        toglInfo.business = res.business;
        toglInfo.user = res.user
          ? res.user
          : createDeletedUserDataForId(toglInfo.userId);
        if (res.slot) {
          toglInfo.samedaySlot = res.slot;
        }
        return toglInfo;
      })
    );
  }

  /**
   * compute distance between business and map point
   * @param biz
   * @returns
   */
  getDistanceToPoint(p: google.maps.LatLngLiteral) {
    return this.calculateDistance(
      this.business.lat,
      this.business.long,
      p.lat,
      p.lng
    );
  }

  /**
   * check a service's categories to see if it can do auto-response togls
   * @param serviceId
   */
  async isServiceEnabledForAutoResponse(serviceId: string) {
    const s: Service = await this.getServiceFromCache(serviceId);
    let catIds = typeof s.catId == 'string' ? [s.catId] : s.catId;
    for (const id of catIds) {
      const c = await this.getCategoryFromCache(id);
      if (c.autoResponseAllowed) {
        return true;
      }
    }
    return false;
  }

  //////////////////////////
  // TOGL STATE FUNCTIONS //
  //////////////////////////

  /**
   * business action: confirm a togl
   * @param t
   */
  async setToglConfirmed(t: ToglInfo) {
    // TO DO: make this a transaction, or move to functions

    // set togl state to confirmed, and
    // if the user for this togl is watching the service, unwatch it

    // handle the togl state change
    await this.docRef<Togl>(CollectionNames.TOGLS, t.id).update({
      state: ToglState.CONFIRMED,
      confirmedTimestamp: firebase.firestore.FieldValue.serverTimestamp() as any,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp() as any,
    });

    // handle setting the watch state
    // load the user's watched services
    const snap = await this.docRef<UserWatchedService>(
      CollectionNames.USERS_WATCHED_SERVICES,
      t.userId
    )
      .get()
      .toPromise();

    if (snap.exists) {
      // user has watched services, remove this one if found
      let data = snap.data() as UserWatchedService;
      let watchedServiceIds: string[] =
        data && data.watchedServiceIds ? data.watchedServiceIds : [];

      if (StringSet.has(watchedServiceIds, t.serviceId)) {
        StringSet.remove(watchedServiceIds, t.serviceId);
        await snap.ref.update({ watchedServiceIds });
      }
    }
  }

  /**
   * business action: complete a togl
   * @param t
   * @returns
   */
  async setToglComplete(t: ToglInfo) {
    return this.updateToglStateEOL(t, ToglState.COMPLETED);
  }

  /**
   * business action: decline a togl with a reason
   *
   * @param t
   * @param stateMessage
   * @returns
   */
  setToglDenied(t: ToglInfo, stateMessage: string) {
    console.log('backendbase.setToglDenied: stateMessage:' + stateMessage);
    return this.updateToglStateEOL(t, ToglState.DENIED, stateMessage);
  }

  /**
   * business action: cancel a togl post-confirmation with the business at fault
   * @param t
   * @param stateMessage cancelation reason
   * @returns
   */
  async setToglCanceledAtFault(t: ToglInfo, stateMessage: string) {
    return this.updateToglStateEOL(t, ToglState.CANCELED_ATFAULT, stateMessage);
  }

  /**
   * mark a togl no-show, user at fault
   * @param t
   * @returns
   */
  async setToglNoshowAtfault(t: ToglInfo) {
    return this.updateToglStateEOL(t, ToglState.NOSHOW_ATFAULT);
  }

  /**
   * helper function to set a togl to an end of life state, with or without a reason
   * @param t
   * @param state
   * @param stateMessage
   * @returns
   */
  private async updateToglStateEOL(
    t: ToglInfo,
    state: ToglState,
    stateMessage?: string
  ) {
    const updateData: any = {
      state,
      completedTimestamp: firebase.firestore.FieldValue.serverTimestamp() as any,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp() as any,
    };
    if (stateMessage) {
      updateData.stateMessage = stateMessage;
    }
    return this.docRef<Togl>(CollectionNames.TOGLS, t.id).update(updateData);
  }
}
