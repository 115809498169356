import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

/**
 * app-wide loading screen
 */
@Injectable({
  providedIn: 'root',
})
export class LoadingScreenProService {
  /**
   * the current loading screen, if any
   */
  get loading() {
    return this._loading;
  }
  private _loading: HTMLIonLoadingElement;

  constructor(private loadingController: LoadingController) {}

  /**
   * @returns a promise that is resolved when the current loading screen, if any, dismisses,
   * or a resolved promise if there is currently no loading screen
   */
  async onDidDismiss() {
    if (this._loading) {
      return this._loading.onDidDismiss();
    }
    return Promise.resolve();
  }

  /**
   * show a loading screen. you must hide it with a call to `hideLoading`
   * @returns a promise that resolves to the loading screen
   */
  async showLoading() {
    if (this._loading) {
      console.log(
        'loadingscreenpro: showLoading: returning existing loading screen'
      );
      return this._loading;
    }

    this._loading = await this.loadingController.create({
      cssClass: 'custom-loading',
      message: 'Please wait...',
      // mode: 'ios',
      spinner: 'lines',
    });
    await this._loading.present();
    return this._loading;
  }

  /**
   * hide the current loading screen if any
   * @returns
   */
  async hideLoading() {
    if (this._loading) {
      const ret: boolean = await this._loading.dismiss();
      this._loading = null;
      return;
    }
  }
}
