import { ToglonFirebaseEnvironment } from '@mojoapps1/mojoapps1common';

export const environment: ToglonFirebaseEnvironment = {
  production: false,
  database: 'dev',
  gmapsApiKey: 'AIzaSyDdz_CawtbwROk7oKowOcnyJxAtfKN5VrA',
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebase: {
    apiKey: 'AIzaSyBrV8TKujBPsbVKLC1JpNjWlj5-RaTd8fM',
    authDomain: 'mojo1demo.firebaseapp.com',
    projectId: 'mojo1demo',
    storageBucket: 'mojo1demo.appspot.com',
    messagingSenderId: '274203423021',
    appId: '1:274203423021:web:cf850a5bce29c4c5ece3a4',
    measurementId: 'G-BS07H8RSF3',
  },
};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
